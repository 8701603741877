import React, { useCallback, useEffect, useState }  from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router'
import { getThemeProps } from '@mui/system';
import textLogo from '../textLogo_v1.svg';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { theme } from './theme_light'
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ScrollTop from './ScrollTop'
import ScrollButtons from './ScrollButtons'
import { device } from './device';
import CardMedia from '@mui/material/CardMedia';

const Image = styled.img`
    width: 120px;
    height: unset;
    @media ${device.mobileM}{
        width: 100%;
        height: 32px;
    }
`;

const StyledDivider = styled(Divider)`
    margin: 0 0 16px 0;
`;

const Sc_CardMedia = styled(CardMedia)`
    height: auto;
    width: 100%;
    @media ${device.MobileL}{
        max-width: 120px;
        height: 100%;
    }
`;

// const pages = ['Contact', 'About'];
// const settings = [ 'Deutsch', '中文', 'English'];

const content = {}

function Footer(props) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [url_footer, setUrl_footer] = useState('/root/footer');

    const {store, language} = useParams();

    const [isFetching, setIsFetching] = useState(false);
    const fetchData_footer = useCallback(() => {

        fetch(url_footer)
            .then(response => {
            if (!response.ok) {
                throw new Error(`status ${response.status}`);
            }
            return response.json();
            })
            .then(json => {
            json?.map(el => {

                const contentItem = {
                    'en': String,
                    'ch': String,
                    'de': String,
                    'image': Array
                }
    
                contentItem.en = el.fields.en;
                contentItem.ch = el.fields.ch;
                contentItem.de = el.fields.de;
                contentItem.image = el.fields.image;
    
                content[el.fields.name] = contentItem;
            });
            setIsFetching(false);
            }).catch(e => {
            console.log('root content call failed')
            setIsFetching(false);
            })
        }, [url_footer]);

    useEffect(() => {

        let mounted = true;
        if(mounted) {
            setIsFetching(true);
            fetchData_footer();
        }
        return () => {
            mounted = false;
        }
    }, [fetchData_footer]);

    return(
        <Box sx={{padding: 4, paddingBottom: 32, minWidth: '100%'}}>
            
            <StyledDivider/>

            <Stack spacing={2} sx={{ flexGrow: 1 , mt: 1}} >
                <Box >
                    <Toolbar disableGutters sx={{ display:'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start' }}>
                        <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'space-between', minWidth: '100%' }} >
                            <IconButton
                                href='/map'
                            >
                                <Image src={textLogo} />
                            </IconButton>
                            <ScrollTop showBelow={1080} />
                            { props.scrollButtons && <ScrollButtons showBelow={0} />}
                        </Box>
                    </Toolbar>
                </Box>
            </Stack>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mb: 2, }} >
                <Box sx={{ display:'flex', flexDirection: 'column' , alignItems: 'flex-start', flexGrow: 1, mb: { xs: 1, md: 0 } }} >
                    {/* <TextContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flexGrow: 1, ml: 0, mr: 'auto' }} > */}
                        <Typography sx={{ ml: 0, mr: 'auto'}} variant='subtitle2' color='secondary' >
                            { content['copywright'] &&  content['copywright'].en}
                        </Typography>
                        <Typography sx={{ ml: 0, mr: 'auto'}} variant='subtitle2' color='secondary' >
                            { content['credit'] &&  content['credit'].en}
                        </Typography>
                    {/* </TextContainer> */}
                </Box>
                {   props.cashier &&
                    <Box>
                        <Typography sx={{ ml: 0, mr: 'auto'}} variant='body1' >
                            { content['report-issues'] &&  content['report-issues'][`${language}`]}
                        </Typography>
                        <Sc_CardMedia
                            component='img'
                            image={content['report-issues-qrcode'] && content['report-issues-qrcode'].image && content['report-issues-qrcode'].image[0].thumbnails.large.url}
                            alt="qrcode"
                        />
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default Footer;