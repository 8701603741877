
export const theme_except_palette = {
    typography: {
        fontFamily: [
            '"Ubuntu"',
            'sans-serif',
        ].join(','),
        h1: {
            fontWeight: 400,
            fontSize: 88,
            lineHeight: 1.09,
            letterSpacing: 1.5
        },
        h2: {
            fontWeight: 400,
            fontSize: 57,
            lineHeight: 1.05,
            letterSpacing: 0.5
        },
        h3: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 500,
            margin: 0,
        },
        h4_bold: {
            fontSize: 34,
            fontWeight: 700,
            letterSpacing: 0.25,
            lineHeight: '118%',
            margin: 0,
            marginBottom: '0 !important'
        },
        h5: {
            fontWeight: 400,
            fontSize: 24,
            lineHeight: 1.6,
            letterSpacing: 0.25
        },
        h6: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: 1.6,
            letterSpacing: 0.25
        },
        h6_bold: {
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 12,
        },
        subtitle1_bold: {
            fontWeight: 700,
        },
        body1: {
            fontWeight: 400,
        },
        body1_bold: {
            fontWeight: 700,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
        },
        body2_bold: {
            fontSize: 14,
            fontWeight: 700,
        },
        button: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '260%',
        },
        button2: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '115%',
        },
        button3: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '115%',
        },
        caption: {
            fontSize: 12,
            fontWeight: 700,
            opacity: '0.6',

        },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 425,
            md: 768,
            lg: 1024,
            xl: 1440,
        },
    },
}