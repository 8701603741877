import * as React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
    max-width: 1440px;
`;

const Div = styled.div``;


// let reminders = [];

function MoreInfo(props) {
    // reminders = [];

    const [dense, setDense] = React.useState(false);
    const [reminders, setReminders] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [remindersCountLabelList, setRemindersCountLabelList] = React.useState();

    function readReminders() {

        setIsLoading(true);

        let remindersMemory = [];
        let remindersCountLabelListMemory = [];

        let countMemory = 0;
        for (let i = 0; i < Object.keys(props.storeContent).length; i++) {
            if (props.storeContent[`restaurant-reminder-${i + 1}`]) {
                countMemory = countMemory + 1;
                remindersCountLabelListMemory.push(countMemory);
                remindersMemory.push(
                    props.storeContent[`restaurant-reminder-${i + 1}`]
                );
            }
        }

        setRemindersCountLabelList(remindersCountLabelListMemory);
        setReminders(remindersMemory);

        setIsLoading(false);
    }

    React.useEffect(() => {
        readReminders();
    }, []);

    return (
        <Container>
            <Div>
                <List dense={dense}>

                    {   isLoading ? (
                            <Skeleton variant="text" />
                        ) : (
                            remindersCountLabelList && reminders?.map((el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={
                                                `${remindersCountLabelList[index]}`
                                            }
                                            secondary={
                                                el[`${props.language}`]
                                            }
                                        />
                                    </ListItem>
                                )
                            })
                        )
                    }

                </List>
            </Div>
        </Container>
    );
}

export default MoreInfo;
