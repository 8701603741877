import React, { useRef } from 'react';
import { PrintContextConsumer } from 'react-to-print';
import ReactToPrint from 'react-to-print';
import { Box , Container, Typography, Button } from '@mui/material';

const pageStyle = `
    html
    {
        background-color: #FFFFFF; 
        margin: 0mm;  /* this affects the margin on the html before sending to printer */
    }
    body
    {
        padding: 12px auto; /* margin you want for the content */
    }
    
    @page {
        size: 80mm auto;
        margin: 0mm 4mm;
        padding: 0mm 4mm;
    }

    @media all {
        .page-break {
        display: none;
        padding: 0mm auto;
        }
    }

    @media print {
        .page-break {
            page-break-before: always;
            padding: 0px auto;
        }
        html, body {
            height: initial !important;
            font-size: 11pt;
            font-family: Courier New;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
        .hide{
            display: none;
        }
        .box {

        }
        .header {
            display: flex;
            justify-content: space-between;
        }
        .header-invoice {
            display: flex;
        }
        .title {
            font-size: 12px;
            text-align: center;
            font-family: Courier New;
        }
        .id-text {
            font-size: 9px;
            color: grey;
            font-family: Courier New;
        }
        .section {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: 16px auto;
            width: 100%;
        }
        .typography {
            font-family: Courier New;
        }
        .two-column-section {
            display: flex;
            width: 100%;
        }
        .two-column-section-column {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
        .hr {
            border-top: 1px solid #EEEEEE;
        }
        .section-right-align {
            text-align: right;
        }
        .table-header {
            border-top: 1px solid #EEEEEE;
            color: grey;
            display: flex;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 12px;
            padding: 8px auto;
        }
        .item-table-row-name {
            width: 70%;
        }
        .item-table-row-qty {
            width: 10%;
        }
        .item-table-row-price {
            width: 10%;
        }
        .item-table-row-tax {
            width: 10%;
        }
        .row1 {
            width: 27%;
        }
        .row2 {
            width: 15%;
        }
        .row3 {
            width: 15%;
        }
        .row4 {
            width: 20%;
        }
        .row5 {
            width: 27%;
        }
        .table-item {
            display: flex;
            border-top: 1px solid #EEEEEE;
            padding: 8px auto;
        }
    }
`;

let rootContent = null;
const storeContent = {};

function readContent(inputFetch, output) {

    inputFetch.forEach((el) => {

        let recordContent = {
            en: String,
            ch: String,
            de: String,
            image: Array
        }

        recordContent.en = el.fields.en;
        recordContent.ch = el.fields.ch;
        recordContent.de = el.fields.de;
        recordContent.image = el.fields.image;

        output[`${el.fields.name}`] = recordContent;

        return;
    });
    return;
}

function createData(
    name: string,
    quantity: number,
    price: number,
    tax: string,
) {
    return { name, quantity, price, tax };
}

let data = [];

function Table(props) {
    return (
        <div>

            <table>
                <thead>
                    <tr>
                        <th className="typography">Product Name</th>
                        <th className="typography">Qty</th>
                        <th className="typography">Price</th>
                        <th className="typography">Tax</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.dataInput?.map((el, index) => {
                            {/* <div className="table-item" key={index}>
                                <div className="item-table-row-name">
                                    <p className="typography">
                                        {el.name}
                                    </p>
                                </div>
                                <div className="item-table-row-qty">
                                    <p className="typography">
                                        {el.quantity}
                                    </p>
                                </div>
                                <div className="item-table-row-price">
                                    <p className="typography">
                                        {el.price}
                                    </p>
                                </div>
                                <div className="item-table-row-tax">
                                    <p className="typography">
                                        {el.tax}
                                    </p>
                                </div>
                            </div> */}
                            return (
                                <tr key={index}>
                                    <td className="typography">{el.name}</td>
                                    <td className="typography">{el.quantity}</td>
                                    <td className="typography">{el.price}</td>
                                    <td className="typography">{el.tax}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

function TaxTable(props) {

    let aSum = 0;
    let aNet = 0;
    let aInclude = 0;

    let bSum = 0;
    let bNet = 0;
    let bInclude = 0;

    props.dataInput.forEach((el) =>{
        aSum = aSum + Math.round(Number(el.fields['A-sum']) * el.fields['quantity'] * 100) / 100 ;
        aNet = aNet + Math.round(Number(el.fields['A-net']) * el.fields['quantity'] * 100) / 100;

        bSum = bSum + Math.round(Number(el.fields['B-sum']) * el.fields['quantity'] * 100) / 100;
        bNet = bNet + Math.round(Number(el.fields['B-net']) * el.fields['quantity'] * 100) / 100;
    })

    return (
        <table>
            <thead>
                <tr>
                    <th className="typography">Tax Type</th>
                    <th className="typography">Value</th>
                    <th className="typography">Gross</th>
                    <th className="typography">Net</th>
                    <th className="typography">Include</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th className="typography">A</th>
                    <td className="typography">19%</td>
                    <td className="typography">{Math.round(aSum * 100) / 100}</td>
                    <td className="typography">{Math.round(aNet * 100) / 100}</td>
                    <td className="typography">{Math.round((aSum - aNet) * 100) / 100}</td>
                </tr>
                <tr>
                    <th className="typography">B</th>
                    <td className="typography">7%</td>
                    <td className="typography">{Math.round(bSum * 100) / 100}</td>
                    <td className="typography">{Math.round(bNet * 100) / 100}</td>
                    <td className="typography">{Math.round((bSum - bNet) * 100) / 100}</td>
                </tr>
            </tbody>
        </table>
    );
}

function readData(orderInput, dataOutput){
    orderInput.forEach((el) => {
        dataOutput.push(
            createData(
                `${el.fields.name}${el.fields.size ? `, ${el.fields.size}` : ''}${el.fields.sugar ? `, ${el.fields.sugar}` : ''}${el.fields.temperature ? `, ${el.fields.temperature}` : ''}`,
                el.fields['quantity'],
                el.fields['original-price'],
                el.fields.tax
            )
        );

        if(el.fields['toppings-1']){
            dataOutput.push(
                createData(
                    el.fields['toppings-1'],
                    el.fields['quantity'],
                    el.fields['toppings-1-price'],
                    el.fields['toppings-1-tax']
                )
            );
        }
        if(el.fields['toppings-2']){
            dataOutput.push(
                createData(
                    el.fields['toppings-2'],
                    el.fields['quantity'],
                    el.fields['toppings-2-price'],
                    el.fields['toppings-2-tax']
                )
            );
            
        }
        if(el.fields['toppings-3']){
            dataOutput.push(
                createData(
                    el.fields['toppings-3'],
                    el.fields['quantity'],
                    el.fields['toppings-3-price'],
                    el.fields['toppings-3-tax']
                )
            );
        }
        if(el.fields.customization){
            dataOutput.push(
                createData(
                    el.fields['customization'],
                    el.fields['quantity'],
                    el.fields['customization-price'],
                    el.fields['customization-tax']
                )
            );
        }
        if(el.fields['discount-name']){
            dataOutput.push(
                createData(
                    el.fields['discount-name'],
                    el.fields['quantity'],
                    `- ${el.fields['discounted-price']}`,
                    ''
                )
            );
        }
    })
}

function TextOnlyInvoice (props) {

    const componentRef = useRef();

    rootContent = props.invoiceContent;
    readContent(props.storeContent, storeContent);

    data = [];
    readData(props.order, data);

    return(
        <>
            {   props.order.length !== 0 && props.invoiceContent && storeContent && rootContent &&
                <div >
                    <ReactToPrint
                        pageStyle={pageStyle}
                        trigger={() => 
                            <Button>
                                {`${props.invoiceContent['download-receit'][`${props.language}`]}`}
                            </Button>
                        }
                        content={() => componentRef.current}
                    />
                    <div 
                    style={{ display: "none" }} 
                    className="page-break" 
                    >
                        <div ref={componentRef}>
                            <div className="page-break" styles={{'font-family': 'Courier New'}}>
                                <div>
                                    <p className="title">
                                        Invoice
                                    </p>
                                    <p className="id-text">
                                        {`#${props.order[0].fields['order-name']}-${props.order[0].fields["index"]}`}
                                    </p>
                                </div>
                            </div>

                            <br/>

                            <div>
                                <p className="typography">
                                    <strong>{storeContent.store.en}</strong>
                                </p>
                                <p className="typography">
                                    <em>
                                        {storeContent.address.en}
                                    </em>
                                </p>
                                {   storeContent['tax-number'].en &&
                                    <p className="typography">
                                        <em>
                                            {`${rootContent['tax-number'].en}: ${storeContent['tax-number'].en}`}
                                        </em>
                                    </p>
                                }
                                {   storeContent.instagram.en &&
                                    <p className="typography">
                                        <em>
                                            {`${rootContent.instagram.en}: ${storeContent.instagram.en}`}
                                        </em>
                                    </p>
                                }
                                {   storeContent.facebook.en &&
                                    <p className="typography">
                                        <em>
                                            {`${rootContent.facebook.en}: ${storeContent.facebook.en}`}
                                        </em>
                                    </p>
                                }
                            </div>

                            <br/>

                            <div>
                                <p className="typography">
                                    <strong>{rootContent['invoice-confirmation'].en}</strong>
                                </p>
                                <div 
                                    // className="two-column-section"
                                >
                                    <div>
                                        <p className="typography">
                                            {rootContent['invoice-id'].en}: {props.order[0].fields.id}
                                        </p>
                                        <p className="typography">
                                            {rootContent.date.en}: {props.order[0].fields.created}
                                        </p>
                                        <p className="typography">
                                            {rootContent.payment.en}: {props.order[0].fields.payment}
                                        </p>
                                        <p className="typography">
                                            {rootContent.currency.en}: {storeContent.currency.en}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <hr/>

                            {   data !== [] &&
                                <Table dataInput={data} ></Table>
                            }

                            <hr/>

                            <div>
                                <p className="typography">
                                    <strong>
                                        {`${rootContent.sum.en}: ${props.order[0].fields['order-sum']}`}
                                    </strong>
                                </p>
                            </div>

                            <br/>
                            <hr/>

                            <div>
                                <TaxTable dataInput={props.order} ></TaxTable>
                            </div>

                            <hr/>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TextOnlyInvoice;