import React,{ useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';

const SC_Card = styled(Card)(({theme}) => ({
    maxWidth: '220px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
}));

const SC_CardContent = styled(CardContent)(({theme}) => ({
    paddingBottom: '0px !important',
}));

export default function CardButtonVertical (props){

    const theme = useTheme();

    const handleClick = React.useCallback((inputRecord, inputId) => {
        props.onClick(inputRecord, inputId);
    }); 

    return(

        <SC_Card 
            raised={ props.selected ? false : true }
            sx={{ 
                backgroundColor: props.selected ? theme.palette.primary.main : theme.palette.background.paper, 
                maxHeight: 220, 
                display: 'flex', 
                flexDirection: 'row', 
                borderRadius: '10px' ,
                maxWidth: {
                    sm: '220px',
                    xl: '720px',
                },
                minWidth: '96px'
            }}
        >
            <CardActionArea
                onClick={() => {handleClick(props.record, props.id)}}
            >
                <Stack>
                    {
                        props.image &&
                        <CardMedia
                            component="img"
                            sx={{ width: '100%', height: 80, objectFit: 'cover', borderRadius: '10px' }}
                            image={props.image}
                            alt='image'
                        />
                    }
                    <SC_CardContent 
                        sx={{ 
                            alignItems: 'flex-start', 
                            color: `${props.selected ? theme.palette.text.on_surface_inverse : theme.palette.text.on_surface }`, 
                            display: 'flex', 
                            flexDirection: 'column',
                            padding: '0 0',
                        }}
                    >
                        {   props.title &&
                            <Typography gutterBottom variant="h5" component="div">
                                {props.title}
                            </Typography>
                        }
                        {   props.text &&
                            <Typography 
                                variant="body2" 
                                // color="text.secondary"
                            >
                                {props.text}
                            </Typography>
                        }
                    </SC_CardContent>
                </Stack>
            </CardActionArea>
        </SC_Card>

    )

}