import {useState} from 'react';
import axios from 'axios';

export default function useData() {
    const [data, setData] = useState(null);
    
    const getData = async (link) => {
        return axios.get(link).then(res => 
            setData(res.data)
            );
    }
    return {
        getData,
        data
    }
}