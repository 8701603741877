import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { device } from '../device';


const FrameBox = styled(Box)(({theme , input_color}) => `
    background-color: ${input_color ? input_color.hex : theme.palette.black.main};
    border-radius: 100% 0% 100% 0% / 0% 73% 27% 100%;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    padding-bottom: 120px;

    @media ${device.laptop} {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    @media ${device.laptopL}{
        flex-direction: row;
        justify-content: center;
    }

`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    margin: auto 16px;
    @media ${device.tablet}{
        flex-direction: row;
        margin: auto 32px;
    }
    @media ${device.laptop} {
        margin: auto 40px;
    }
    @media ${device.laptopL} {
        max-width: 1456px;
    }
`);

const MainContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: calc(100vw - 40px);
    @media ${device.tablet}{
        max-width: 728px;
        position: relative;
        width: 100%;
        z-index: 2;
    }
    @media ${device.tablet} {
        max-width: 50vw;
    }
    @media ${device.laptopL}{
        max-width: 843px;
    }
`);

const ImageBox = styled(Box)(({theme}) => `
    width: 100%;
    @media ${device.tablet}{
        position: absolute;
        right: 32px;
        width: 339px;
        z-index: 1;
    }
    @media ${device.laptop} {
        width: 597px;
        position: relative;
        right: unset;
    }
    @media ${device.laptopL} {
    }
`);

const ScCardMedia = styled(CardMedia)(({theme}) => `
    width: 100%;
`);

const TextsBox = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media ${device.tablet} {
        min-width: 728px;
    }
    @media ${device.laptop} {
        left: 40px;
        position: absolute;
    }

    @media ${device.laptop} {
        position: static;
    }
`);

const TitleBox = styled(Box)(({theme}) => `

`);

const Title1Box = styled(Box)(({theme}) => `

`);

const Title2Box = styled(Box)(({theme}) => `
    margin-left: 40px;
    @media ${device.laptopL} {
        margin-left: 160px;
    }
`);

const TextBox = styled(Box)(({theme}) => `
    margin-right: 80px;
    
    @media ${device.tablet} {
        margin-right: 160px;
    }
    
    @media ${device.laptopL} {

    }
`);

const ButtonBox = styled(Box)(({theme}) => `

`);

export default function CtaAndLargeImageWithTitleAndText (props){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    function handleClick(inputUrl){
        if(inputUrl.substring(0,7) === 'http://'){
            inputUrl = 'https://' + inputUrl.substring(7);
        } else if(inputUrl.substring(0,8) !== 'https://'){
            inputUrl = 'https://' + inputUrl;
        }

        window.open(inputUrl, '_blank', 'noopener,noreferrer');
    }

    return(

        <FrameBox 
            input_color={
                props['color-1'] && props['color-1']
            }
        >
            <ContentBox>
                <MainContentBox>
                    <TextsBox>
                        <TitleBox>
                            {   props['text-1'] &&
                                <Title1Box>
                                    <Typography variant='h1'>
                                        {
                                            props['text-1']
                                        }
                                    </Typography>
                                </Title1Box>
                            }
                            {   props['text-2'] &&
                                <Title2Box>
                                    <Typography variant='h1'>
                                        {
                                            props['text-2']
                                        }
                                    </Typography>
                                </Title2Box>
                            }
                        </TitleBox>
                        {   props['text-3'] &&
                            <TextBox>
                                <Typography variant='h6'>
                                    {
                                        props['text-3']
                                    }
                                </Typography>
                            </TextBox>
                        }
                        {   props['text-4'] &&
                            <ButtonBox> 
                                <Button
                                    variant='contained'
                                    className='btn btn-success'
                                    size='large'
                                    onClick={() => handleClick(props['url-1'])}
                                >
                                    {
                                        props['text-4']
                                    }
                                </Button>
                            </ButtonBox>
                        }
                    </TextsBox>
                </MainContentBox>
                {   props['images-1'] &&
                    <ImageBox>
                        <ScCardMedia
                            component="img"
                            image={props['images-1'].thumbnails.large.url}
                            alt="image"
                        />
                    </ImageBox>
                }
            </ContentBox>
        </FrameBox>

    )

}