import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { device } from '../device';

const Section = styled(Box)(({theme}) => `
    margin: 32px 16px;

    @media ${device.mobileL} {
        margin: 32px 16px;
    }
    @media ${device.tablet} {
        margin: 64px 16px;
    }
    @media ${device.laptop} {
        margin: 64px 16px;
    }
`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
    @media ${device.tablet} {
        flex-direction: row;
    }
    @media ${device.laptop} {
        justify-content: center;
    }
`);

const TextsBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${device.tablet} {
        align-items: center;
        flex: 1;
        text-align: left;
    }
    @media ${device.laptop} {
        max-width: 480px;
    }
`);

const TitleBox = styled(Box)(({theme}) => `
    @media ${device.mobileL} {
        max-width: 393px;
    }
    @media ${device.tablet} {
        max-width: 100%;
        min-width: 100%;
        text-align: left;
    }
    @media ${device.laptop} {
    }
`);

const DescriptionBox = styled(Box)(({theme}) => `
    @media ${device.mobileL} {
        max-width: 393px;
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        max-width: 100%;
        min-width: 100%;
    }
`);

const ImageSection = styled(Box)(({theme}) => `
    position: relative;
    min-width: 100%;
    
    @media ${device.mobileL} {
        display: flex;
        justify-content: center;
    }
    @media ${device.tablet} {
        flex: 1;
        min-width: unset;
    }
    @media ${device.laptop} {
        max-width: 480px;
    }
`);

const Image = styled('img')(({ theme }) => `
    width: 100%;

    @media ${device.mobileL} {
        max-width: 393px;
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        max-width: 100%;
    }
`);

const Example = styled(Box)(({theme}) => `

`);

export default function OrganizationIntroduction (props){

    let itemImages = props['images-1'];

    return(

        <Section>
            <ContentBox>
                <TextsBox>
                    {   props['text-1'] &&
                        <TitleBox>
                            <Typography variant='h3'>
                                {props['text-1']}
                            </Typography>
                        </TitleBox>
                    }
                    {   props['text-2'] &&
                        <DescriptionBox>
                            <Typography variant='body1'>
                                {props['text-2']}
                            </Typography>
                        </DescriptionBox>
                    }
                </TextsBox>
                <ImageSection>
                    {   itemImages &&
                        <Image 
                            src={`${itemImages[0].thumbnails.large.url}`}
                            srcSet={`${itemImages[0].thumbnails.large.url}`}
                            alt={props['text-1'] && props['text-1']}
                            loading="lazy"
                        />
                    }
                </ImageSection>
            </ContentBox>
        </Section>

    )

}