import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { useParams } from 'react-router';

const LanguagePageContainer = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 32px);
    justify-content: center;
    position: relative;
`;

const ButtonsBox = styled(Box)`
    display: flex;
    margin: 32px auto;
`;

const Hero = styled(Box)`
    bottom: 16px;
    ${'' /* display: flex; */}
    ${'' /* margin: 32px auto; */}
    position: absolute;
`;

export default function LanguagePage (){

    const { store } = useParams();

    return(

        <LanguagePageContainer>
            <ButtonsBox>
                <Button>
                    <Link to={`/main/${store}/en`} >English</Link>
                </Button>
                <Button>
                    <Link to={`/main/${store}/ch`}>中文</Link>
                </Button>
                <Button>
                    <Link to={`/main/${store}/de`}>Deutsch</Link>
                </Button>
            </ButtonsBox>
            <Hero>
                <Typography>
                    Powered by www.fongchou.de
                </Typography>
            </Hero>
        </LanguagePageContainer>

    )

}