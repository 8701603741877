import React from 'react';
import loading from '../loading.gif';
import styled from 'styled-components';

const Loading_Div = styled.div`
    ${'' /* align-items: center; */}
    ${'' /* background: #fff; */}
    ${'' /* display: flex; */}
    height: 100%;
    ${'' /* left: 0; */}
    margin: 0;
    ${'' /* top: 0; */}
    ${'' /* justify-content: center; */}
    position: static;
    width: 100%;
    z-index: 999;
`;

export default function Loading (){

    return(
            <img src={loading} width='180'/>

    )

}