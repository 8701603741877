import React, {useEffect, useState} from "react";
import useData from '../hooks/useData';
import CreateMap from './CreateMap';
import { Slide, Box, Button} from '@mui/material';
import styled, {keyframes} from 'styled-components';
import MapMarkerDetailWindow from './MapMarkerDetailWindow';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useParams } from 'react-router';

const Container = styled(Box)`
    ${'' /* display: flex; */}
    ${'' /* flex-direction: row; */}
    height: 100vh;
    width: 100％;
    ${'' /* justify-content: flex-end; */}
    ${'' /* position: relative; */}
`;

const MapBox = styled(Box)`
    ${'' /* display: flex; */}
    height: 100vh;
    width: 100%;
`;

const DrawerBox = styled(Box)`
    ${'' /* background-color:  hsl(0, 0%, 25%, 0); */}
    display: flex;
    ${'' /* fill: transparent; */}
    justify-content: flex-end;
    ${'' /* height: 100vh; */}
    ${'' /* width: 100vw; */}
`;

const DetailsWindowBox = styled(Box)`
    ${'' /* display: flex; */}
    ${'' /* height: 100%; */}
    ${'' /* overflow-y: scroll; */}
    position: relative;
    ${'' /* right: 0; */}
    ${'' /* z-index: 999; */}
`;

export default function Map(props){

    const {mapTypeId, language} = useParams();

    const [lightboxState, setLIghtboxState] = useState(false);

    const [ activeMap, setActiveMap ] = useState(() => {
        return props.allMaps.filter((el) => {
            return el.id === props.activeMapId;
        })
    });

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        });

        const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        };

        const executeLightboxStyling = (state) => {
            setLIghtboxState(state);
        }

        const detailsDrawer = (anchor) => (
            <Box
                sx={{ 
                    width: md ? 'calc(480px + 32px)' : sm ? 'calc(70vw + 32px)' : 'calc(80vw + 32px)', position: 'relative'
                }}
                role="presentation"
                // onClick={() => {
                //         toggleDrawer(anchor, false);
                //         setDetailsVisible(false);
                //     }
                // }
                // onKeyDown={() => {
                //         toggleDrawer(anchor, false);
                //         setDetailsVisible(false);
                //     }
                // }
            >
                <DetailsWindowBox>
                    {   detailsInDetailsWindow &&

                        <MapMarkerDetailWindow
                            marker={detailsInDetailsWindow.marker}
                            visible={detailsInDetailsWindow.visible}
                            name={detailsInDetailsWindow.name}
                            facebook={detailsInDetailsWindow.facebook}
                            instagram={detailsInDetailsWindow.instagram}
                            address={detailsInDetailsWindow.address}
                            website={detailsInDetailsWindow.website}
                            video={detailsInDetailsWindow.video}
                            owner={detailsInDetailsWindow.owner}
                            images={detailsInDetailsWindow.images}
                            menus={detailsInDetailsWindow.menus}
                            handleLightbox={(state) => executeLightboxStyling(state)}
                        />

                    }
                </DetailsWindowBox>
            </Box>
        );

    const {data, getData} = useData();

    const [detailsInDetailsWindow, setDetailsInDetailsWindow] = useState();

    const [detailsVisible, setDetailsVisible] = useState(false)

    function handleDetailWindow (input, details){
        if(input === true){
            setDetailsInDetailsWindow(details);
            setDetailsVisible(true);
            toggleDrawer('right', true);
        } else {
            // setDetailsInDetailsWindow(null);
            setDetailsVisible(false);
        }
    }

    useEffect(() => {
        async function onPageLoad () {
            await getData('/markers');
        }
        onPageLoad();
        // setActiveMap(() => {
        //     return props.allMaps.filter((el) => {
        //         return el.fields.en === mapType;
        //     })
        // })

    }, []);

    return (
        // Important! Always set the container height explicitly
        <Container>
            <MapBox>
                {data && activeMap && <CreateMap theme={theme} mapType={props.activeMapId} data={data} onHandleDetailWindow={(input, details) => handleDetailWindow(input, details)} />}
                <Drawer
                    anchor={'right'}
                    open={detailsVisible}
                    onClose={() => {
                        toggleDrawer('right', false);
                        setDetailsVisible(false);
                    }}
                >
                    <DrawerBox
                        sx={{
                            width: lightboxState === true ? '100vw' : 'auto' ,
                        }}
                    >
                        {detailsDrawer('right')}
                    </DrawerBox>
                </Drawer>
            </MapBox>
        </Container>
    );
}