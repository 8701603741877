import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useFetch from './useFetch';
import Loading from './Loading';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import styled from 'styled-components';
import PaypalAnimation from './PaypalAnimation'

const PaypalBox = styled(Box)`
    ${'' /* margin: 8px !important; */}
    min-width: 100%;
`;

let mount = false;

export default function PayPal ({onCheckMount, store, clientId, purchases, onSuccessfulPayment}){

    const [initialOptions, setInitialOptions] = useState(
        {
            "client-id": clientId,
            currency: "EUR",
            intent: "capture",
            // "data-client-token": "access_token$production$wdy3mnsq6sz8fv4x$053b629aa553b0f0f3fdd1e57787aadd"
        }
    )
    
    useEffect(()=>{
        mount = false;
        onCheckMount(true);
        mount = true;
    },[mount])

    return(
        <PaypalBox>
            {   mount && initialOptions
                ?
                <PayPalScriptProvider style="min-width: 100%" deferLoading={false} options={initialOptions}>
                    <PayPalButtons
                        createOrder={() => {
                            return fetch(`/create-order/${store}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(purchases)
                            })
                            .then(res => {
                                if(res.ok) return res.json()
                                return res.json().then(json => Promise.reject(json))
                            }).then(({id}) => {
                                return id;
                            }).catch(e => {
                                console.error(e.error)
                            })
                        }}
                        onApprove={async (data, actions) => {
                            return await actions.order.capture().then((details) => {
                                const name = details.payer.name.given_name;
                                // console.log(details)
                                onSuccessfulPayment(details.id)
                                // alert(`Transaction completed by ${name} id ${details.purchase_units[0].reference_id}`);
                            });
                        }}
                        onError={(err) => {
                            console.log(err);
                        }}
                        showSpinner={true}
                        fundingSource="paypal"
                        style={{ 
                            color: "white",
                            height: 32
                        }}
                    />
                </PayPalScriptProvider>
                :
                <PaypalAnimation width='140px' height='140px' />
            }
        </PaypalBox>

    )

}