import React from "react";
// import { Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { Box , Container, Typography, Button } from '@mui/material';
import styled from 'styled-components';

const Section = styled(Box)`
    display: flex;
    flex-direction: column;
    font-size: 7px;
    height: 100%;
    justify-content: center;
    margin: 8px auto;
    width: 100%;
`;


const TableItem = styled(Box)`
    display: flex;
    flex-direction: column;

    @media print {
        page-break-before: always;
    }
`;

let data = [];
let totalItemsCount = 0;

function Table(props) {
    return (
        <>
            {
                props.dataInput?.map((el, index) => {

                    let itemIndex = Number(index);
                    itemIndex++;

                    return (
                        <Section key={index} className="page-break">
                            <TableItem>
                                <Typography>
                                    {el.title} - {itemIndex}/{totalItemsCount}
                                </Typography>
                                <Typography>
                                    {el.item}
                                </Typography>
                                <Typography>
                                    {el.merics}
                                </Typography>
                                {   el.customization !== '' &&
                                    <Typography>
                                        {el.customization}
                                    </Typography>
                                }
                                {   el.topping1 !== '' &&
                                    <Typography>
                                        {el.topping1}
                                    </Typography>
                                }
                                {   el.topping1 !== '' &&
                                    <Typography>
                                        {el.topping2}
                                    </Typography>
                                }
                                {   el.topping1 !== '' &&
                                    <Typography>
                                        {el.topping3}
                                    </Typography>
                                }
                                {   el.note !== '' &&
                                    <Typography>
                                        {el.note}
                                    </Typography>
                                }
                                {   el.discount !== '' &&
                                    <Typography>
                                        {el.discount}
                                    </Typography>
                                }
                            </TableItem>
                        </Section>
                    )
                })
            }
        </>
    );
}

function readData(orderInput, dataOutput, title){

    totalItemsCount = 0;

    let dataMemory = {
        title: String,
        item: String,
        merics: String,
        customization: String,
        topping1: String,
        topping2: String,
        topping3: String,
        note: String,
        discount: String
    }

    orderInput.forEach((el) => {

        function readMerics(inputElement) {
            if(inputElement.fields.sugar && inputElement.fields.temperature){
                return `${inputElement.fields.sugar} / ${inputElement.fields.temperature}`;
            } else if (inputElement.fields.sugar) {
                return inputElement.fields.sugar;
            } else if (inputElement.fields.temperature){
                return inputElement.fields.temperature;
            } else {
                return "";
            }
        }
        for(let i = 0; i < el.fields['quantity']; i++){

            totalItemsCount++;
    
            dataMemory = {
                title: `${title}`,
                item: `${el.fields.name} - ${el.fields.size}`,
                merics: readMerics(el),
                customization: '',
                topping1: '',
                topping2: '',
                topping3: '',
                note: '',
                discount: ''
            }
    
            if(el.fields.customization){
                dataMemory.customization = el.fields.customization;
            }
    
            if(el.fields['toppings-1']){
                dataMemory.topping1 = el.fields['toppings-1'];
            }
    
            if(el.fields['toppings-2']){
                dataMemory.topping2 = el.fields['toppings-2'];
            }
    
            if(el.fields['toppings-3']){
                dataMemory.topping3 = el.fields['toppings-3'];
            }
            if(el.fields['note']){
                dataMemory.note = el.fields['note'];
            }
            if(el.fields['discount-name']){
                dataMemory.discount = el.fields['discount-name'];
            }
    
            dataOutput.push(dataMemory);
        }
    })
}

const LabelToPrint = (props) => {

    data = [];
    readData(props.order, data, props.title);

    return (
        <Box>
            {   data !== [] &&
                <Table dataInput={data} ></Table>
            }
        </Box>
    )
};

export default LabelToPrint;