import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useFetch from './useFetch';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import CartItem from './CartItem'
import '../css/GlobalCss.css'
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Create from './Create';
import PugDogAnimation from './PugDogAnimation';
import Badge from '@mui/material/Badge';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LottieAnimationWithLocalFile from './LottieAnimationWithLocalFile';
import NoteSection from "./NoteSection";


const ScCartBox = styled(Box)(({theme}) => ({
    position: 'relative'
}));

const ScDialog = styled(Dialog)(({theme}) => ({
    margin: '0 !important',
    padding: 0,
    width: '100%'
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: theme.palette.text.on_surface_inverse,
        right: -2,
        top: -2,
        // border: `2px solid ${theme.palette.primary.main}`,
        padding: '0 4px',
    },
}));

const ActionBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100%'
}));

const NoteSectionBox = styled(Box)(({theme}) => ({
    padding: '8px',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AppBarCart(props) {

    const theme = useTheme();

    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const md = useMediaQuery(theme.breakpoints.up('md'));

    // const [checkout, setCheckout] = useState(false);

    // const [total, setTotal] = useState();

    const [paypalClientId, setPaypalClientId] = useState();

    const url_rootContent_itemPage = '/root/item-page';
    const { data: rootContent_itemPage, isPending: rootContent_itemPage_isPending, error: rootContent_itemPage_error } = useFetch(url_rootContent_itemPage);
    const url_rootContent_cart = '/root/cart';
    const {data: rootContent_cart, isPending: rootContent_cart_isPending, error: rootContent_cart_error} = useFetch(url_rootContent_cart);

    const {store, language, product} = useParams();

    const [checkCart, setCheckCart] = useState(false);

    const [note, setNote] = useState(props.savedNote && props.savedNote);

    const descriptionElementRef = React.useRef(null);
    const [scroll, setScroll] = React.useState('paper');

    const history = useHistory();
    const handleCheckout = React.useCallback((inputOrderID) => {
        props.onCleanUpCart();
        props.passNote(null);
        props.cashier ? history.push(`/cashier/${store}/${language}/invoice/${inputOrderID}`) : history.push(`/${store}/${language}/invoice/${inputOrderID}`);
    },[history, language, props, store]);

    const handleClickOpen = (scrollType) => () => {
        // calculateTotal ();
        setCheckCart(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setCheckCart(false);
    };

    function onPassHandleChangeQuantity(action, inputItem){
        // calculateTotal();
        props.onHandleChangeQuantity(action, inputItem);
    }

    let cartContent = {};

    function readCartContent(data) {
        data.forEach((el) => {

            let recordContent = {
                en: String,
                ch: String,
                de: String
            }

            recordContent.en = el.fields.en;
            recordContent.ch = el.fields.ch;
            recordContent.de = el.fields.de;

            cartContent[`${el.fields.name}`] = recordContent;

            return;

        })
        return cartContent;
    }
    rootContent_cart && readCartContent(rootContent_cart);

    function selectNote(record, action){

        let noteRecordMemory;

        if(action === 'plus') {
            noteRecordMemory =  record;
        } else if (action === 'minus') {
            noteRecordMemory = null;
        }
        setNote(noteRecordMemory);

        props.passNote(noteRecordMemory);

    }

    React.useEffect(() => {

        fetch(`/paypalClientId/${store}`)
        .then(res => res.text())
        .then(res => setPaypalClientId(res));

        if (checkCart) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [checkCart, store, props.savedNote, note]);

    return (
        <ScCartBox>
            <IconButton size="medium" color={checkCart ? 'secondary' : 'primary'} aria-label="add" onClick={handleClickOpen('paper')}>
                <StyledBadge 
                    badgeContent={props.purchases ? props.purchases.length : 0} 
                    color="danger"
                >
                    {checkCart ? <FontAwesomeIcon icon={faXmark} size='lg' /> : <FontAwesomeIcon icon={faBagShopping} size='lg' />}
                </StyledBadge>
            </IconButton>
            {/* Dialog */}
            <ScDialog
                className="cart-dialog-box"
                sx={{ margin: 0 }}
                fullWidth={true}
                maxWidth='false'
                open={checkCart}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
            >
                {   rootContent_cart && cartContent && language &&
                    <DialogTitle id="scroll-dialog-title">
                        {cartContent['cart-title'][`${language}`]}
                    </DialogTitle>
                }
                <DialogContent dividers={scroll === 'paper'}>
                    {   props.purchases.length !== 0 && rootContent_itemPage ?
                        props.purchases?.map((el, index) => {
                            return  <CartItem key={index} rootContent={rootContent_itemPage} item={el} language={language} passHandleChangeQuantity={(action, inputItem) => onPassHandleChangeQuantity(action, inputItem)} />;
                        })
                        :
                        <Box>
                            <PugDogAnimation width='140px' height='140px' />
                            {   rootContent_cart && cartContent &&
                                <Typography>
                                    {cartContent['no-order-message'][`${language}`]}
                                </Typography>
                            }
                        </Box>
                    }
                </DialogContent>
                {   rootContent_cart && cartContent && store && language && props.notes && props.settings &&
                    <NoteSectionBox>
                        <NoteSection
                            cashier={props.cashier ? true : false} 
                            content={cartContent}
                            store={store} 
                            language={language} 
                            settings={props.settings} 
                            records={props.notes}
                            savedNote={props.savedNote && props.savedNote}
                            onClick={(record, action) => selectNote(record, action)} 
                        /> 
                    </NoteSectionBox>
                }
                <ActionBox 
                    style={{ 
                        flexDirection: md ? 'row' : xs ? 'column' : 'unset', 
                        alignItems: md ? 'unset' : xs ? 'flexStart' : 'center' 
                    }}
                >
                    {   rootContent_cart && cartContent && cartContent['sum'] &&
                        <Box 
                            sx={{ 
                                marginLeft: {xs: '16px', md:'24px'} 
                            }}
                        >
                            <Typography variant={ md ? "h4_bold" : "h5"} component="div">
                                {cartContent['sum'][`${language}`]} {props.total && Number(props.total).toFixed(2)} {props.settings && props.settings['currency-symbol'][`text-${language}`]}
                            </Typography>
                        </Box>
                    }
                    <Box 
                        sx={{ 
                            marginBottom: '8px' 
                        }}
                    >
                        {   rootContent_cart && cartContent && language && props.settings &&
                            <Create 
                                cashier={props.cashier ? true : false} 
                                store={store} 
                                settings={props.settings} 
                                paypalId={paypalClientId} 
                                language={language} 
                                cartContent={cartContent} 
                                purchases={props.purchases} 
                                note={note && note}
                                onCheckout={(inputOrder) => handleCheckout(inputOrder)}>
                            </Create>
                        }
                    </Box>
                </ActionBox>
            </ScDialog>
        </ScCartBox>
    )
}

export default AppBarCart;