import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { device } from '../device';

const Section = styled(Box)(({theme}) => `
    margin: 32px 16px;

    @media ${device.mobileL} {
        margin: 32px 16px;
    }
    @media ${device.tablet} {
        margin: 64px 16px;
    }
    @media ${device.laptop} {
        margin: 64px 16px;
    }
`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
`);

const TextsBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${device.tablet} {
        align-items: center;
        text-align: center;
    }
`);

const TitleBox = styled(Box)(({theme}) => `
    @media ${device.tablet} {
        max-width: 736px;
        text-align: center;
    }
    @media ${device.laptop} {
    }
`);

const DescriptionBox = styled(Box)(({theme}) => `
    @media ${device.tablet} {
        max-width: 360px;
    }
`);

const CardsSection = styled(Box)(({theme}) => `
    position: relative;
    min-width: 100%;
    
    @media ${device.mobileL} {

    }
    @media ${device.tablet} {
        display: flex;
        justify-content: center;
    }
    @media ${device.laptop} {
    }
`);

const CardsBox = styled(Box)(({theme}) => `
    display: flex;
    gap: 16px;
    padding-left: 32px;
    padding-right: 32px;
    overflow-x: scroll;
    @media ${device.laptop} {

    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`);

const CardItem = styled(Box)(({theme, image_url}) => `
    align-items: end;
    background-image: url(${image_url});
    background-color: #cccccc;
    height: 332px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    min-width: 332px;
`);

const CardTextBox = styled(Box)(({theme}) => `
    background-color: ${theme.palette.black.main + 'CC'};
    border-radius: 0px 0px 10px 10px;
    color: ${theme.palette.white.main};
    min-width: 100%;
    padding: 16px;
`);

const Image = styled('img')(({ theme }) => `
    width: 100%;
    max-width: 300px;
    margin-bottom: 16px;

    @media ${theme.breakpoints.up('sm')} {
        max-width: 500px;
        margin-bottom: 32px;
    }
`);

const Example = styled(Box)(({theme}) => `

`);

export default function ThreeSimpleCardsAndTexts (props){

    function renderCards(){


        
        let cardTexts = props['text-3'].split('/*/');

        let cardImages = props['images-1'];

        let cardObjs = [];

        for(let i = 0; i < cardTexts.length; i++){
            if(cardImages[i]) {
                let cardObj = {
                    text: String,
                    imageUrl: String
                }
                cardObj.text = cardTexts[i];
                cardObj.imageUrl = cardImages[i].thumbnails.large.url;
                cardObjs.push(cardObj);
            }
        }

        return (
            <CardsBox>
                {
                    cardObjs !== [] && cardObjs?.map((el, index) => {
                        return (
                            <CardItem key={index} image_url={el.imageUrl}>
                                <CardTextBox>
                                    <Typography variant='h6'>
                                        {
                                            el.text
                                        }
                                    </Typography>
                                </CardTextBox>
                            </CardItem>
                        )
                    })
                }
            </CardsBox>
        )
    }

    return(

        <Section>
            <ContentBox>
                <TextsBox>
                    {   props['text-1'] &&
                        <TitleBox>
                            <Typography variant='h3'>
                                {props['text-1']}
                            </Typography>
                        </TitleBox>
                    }
                    {   props['text-2'] &&
                        <DescriptionBox>
                            <Typography variant='body1'>
                                {props['text-2']}
                            </Typography>
                        </DescriptionBox>
                    }
                </TextsBox>
                <CardsSection>
                    {props['text-3'] && renderCards()}
                </CardsSection>
            </ContentBox>
        </Section>

    )

}