import React from 'react';
// import styled from 'styled-components';
import { styled } from '@mui/system';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import SimpleCardButtonGroup from "./SimpleCardButtonGroup";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SC_Card = styled(Card)`

    margin: 4px 0;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    text-color: white;
`;

const CustomButton = styled(Button)(({ theme }) => ({
    ...theme.typography.button3,
    // padding: theme.spacing(1, 2),
    // borderRadius: theme.shape.borderRadius,
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main,
    // },
}));

function NoteSection({cashier, content, records, store, language, onClick, settings, savedNote}) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [open, setOpen] = React.useState(false);

    const [activeNote, setActiveNote] = React.useState(savedNote && savedNote);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [filteredRecords, setFilteredRecords] = React.useState()

    const handleClick = React.useCallback((record, action) => {

        let selectedRecord = record;
        let selectedAction = action
        onClick(selectedRecord, selectedAction);
        if(selectedAction === 'plus'){
            setActiveNote(selectedRecord);
        }
        if(selectedAction === 'minus'){
            setActiveNote(null);
        }
        setOpen(false);
    }); 

    React.useEffect(() => {
        records && setFilteredRecords(() =>{
            return records.filter((el) => {
                if(cashier){
                    return el.fields['activate-cashier']
                }
                if(!cashier){
                    return el.fields['activate-client']
                }
            })
        })
        let mounted = true;
        if(mounted) {

        }
        return () => {
            mounted = false;
        }
    }, [activeNote]);

    return (
        <Stack
            spacing={1}
        >
            {   content && content['additional-note'] &&
                <Typography variant="body2_bold">
                    {content['additional-note'] && content['additional-note'][`${language}`] }
                </Typography>
            }
            {   filteredRecords && content && language &&
                <Box>
                    <Box sx={{ display: sm ? 'none' : 'flex' }}>
                        <CustomButton variant={activeNote ? 'text' : 'outlined'} onClick={handleClickOpen}>
                            {   content['note-button-text'] && activeNote && activeNote.fields ?
                                activeNote.fields[`${language}`]
                                :
                                content['note-button-text'][`${language}`]
                            }
                        </CustomButton>
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>
                                {   content['additional-note'] &&
                                    content['additional-note'][`${language}`] 
                                }
                            </DialogTitle>
                            <DialogContent>
                                {   content['note-button-text'] &&
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {content['note-button-text'][`${language}`]}
                                    </DialogContentText>
                                }
                                <SimpleCardButtonGroup
                                    defaultValue={activeNote && activeNote.fields.name}
                                    content={content}
                                    records={filteredRecords}
                                    language={language}
                                    onClick={(record, action) => handleClick(record, action)} 
                                />
                            </DialogContent>
                            <DialogActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button onClick={handleClose}>
                                    {   content['close'] && 
                                        content['close'][`${language}`] 
                                    }
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <Box
                        sx={{ display: sm ? 'flex' : 'none' }}
                    >
                        <SimpleCardButtonGroup
                            defaultValue={activeNote && activeNote.fields.name}
                            content={content}
                            records={filteredRecords}
                            language={language}
                            onClick={(record, action) => handleClick(record, action)} 
                        />
                    </Box>
                </Box>
            }
        </Stack>
    );
}

export default NoteSection;