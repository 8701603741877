import React, {useRef} from 'react';
import { Box , Container, Typography, Button, Link } from '@mui/material';
import axios from 'axios';
import qz from "qz-tray";


function QztrayCashierPrinter(props){


    function updatePaymentStatus() {

        const order = props.order;

        axios
        .post(`/confirmOrder/${props.store}`, order)
        .then((res) => {
            if(res.data.status === 'success'){
                
            }
        })
        .catch(err => {
            console.error(err);
        });

        const orderName = order[0].fields['order-name'];

        handlePrint(orderName);
    }

    function handlePrint(ticketNameInput) {

        qz.websocket.connect().then(() => {
            // return qz.printers.find('SNBC BTP-L520 BPLE');
            return qz.printers.find('Kasse');
            // return qz.printers.find('EPSON3A209D (Epson Stylus SX420W)');
            // return qz.printers.find('PDFCreator');
        }).then(async (found) => {

            // console.log("FOUND PRINTER", found)

            let config = qz.configs.create(found);

            const lineFeed = '\x0A';

            let cmd;

            cmd = [
                // ...cmd,
                '\x1B' + '\x40',          // init
                '\x1B' + '\x61' + '\x30', // left align
                '\x1B' + '\x4D' + '\x30', // normal text
                lineFeed,                   // line break
                lineFeed,                   // line break
                '\x1B' + '\x45' + '\x0D', // bold on
                `#${ticketNameInput}`,
                '\x1B' + '\x45' + '\x0A', // bold off
                lineFeed,                   // line break
                lineFeed,                   // line break
                lineFeed,                   // line break
                lineFeed,                   // line break
                lineFeed,                   // line break
                lineFeed,                   // line break
                lineFeed,                   // line break
                // '\x1D' + '\x56'  + '\x01', // partial cut (new syntax)
                // '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
            ];
            cmd = [...cmd,
                '\x1D' + '\x56'  + '\x01', // partial cut (new syntax)
            ]
            // console.log(final)
            return qz.print(config, cmd);
        }).catch((e) => {
            alert(e);
        }).finally(() => {
            return qz.websocket.disconnect();
        });


    }

    return(
        <Box>
            {   props.order.length !== 0 && props.invoiceContent && props.invoiceContent['print-label'] &&
                <Box>
                    <Button
                        onClick={() => updatePaymentStatus()}
                    >
                        {props.label && props.label}
                    </Button>
                </Box>
            }
        </Box>
    )

}

export default QztrayCashierPrinter;