import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import Box from '@mui/material/Box';

export default function PaymentAnimation(props) {

    const container =useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../lottie-payment_v1.json')
        })
    }, [])

    return (

        <Box
            sx={{
                width: `${props.width}`,
                height: `${props.height}`
            }}
            ref={container}
        >
        </Box>

    )
}