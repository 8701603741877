import React, {useState} from 'react';
import axios from 'axios';
// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
// import {makeStyles} from '@mui/material/styles';
// Custom Components
import CardInput from './CardInput';
import { useSlotProps } from '@mui/base';
import styled from 'styled-components';

// const useStyles = makeStyles({
//     root: {
//         maxWidth: 500,
//         margin: '35vh auto',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignContent: 'flex-start',
//     },
//     div: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignContent: 'flex-start',
//         justifyContent: 'space-between',
//     },
//     button: {
//         margin: '2em auto 1em',
//     },
// });

const StyledBox = styled(Box)`
    ${'' /* height: auto !important; */}
    ${'' /* max-height: 620px !important; */}
    ${'' /* margin: 8px !important; */}
    ${'' /* padding: 8px !important; */}
    min-width: 100%;
`;

const StyledButton = styled(Button)`
    min-width: 100%;
`;

function StripeCard({ language, store, purchases, cartContent, onPushPaymentToBacklog }){

    const options = {
        // passing the client secret obtained from the server
        clientSecret: '{{CLIENT_SECRET}}',
    };

    // const classes = useStyles();
    // State
    const [email, setEmail] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmitPay = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const res = await axios.post('/pay', {email: email});

        const clientSecret = res.data['client_secret'];

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                email: email,
                },
            },
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                console.log('Money is in the bank!');
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
            }
        }
    };

    async function handlePayment(event) {

        event.preventDefault();
        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        return fetch(`/create-order-stripe/${store}/${language}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(purchases)
            // body: JSON.stringify({
            //     items: [
            //         { id: 1, quantity: 3 },
            //         { id: 2, quantity: 1 }
            //     ]
            // })
        })
        .then(res => {
            if(res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        }).then( async ({ url, stripeKey }) => {

            await onPushPaymentToBacklog(stripeKey)

            window.location = url;
            
        }).catch(e => {
            console.error(e.error)
        })
    }

    return(
        <StyledBox>
            {/* <CardContent> */}
                {/* <TextField
                    label='Email'
                    id='outlined-email-input'
                    helperText={`Email you'll recive updates and receipts on`}
                    margin='normal'
                    variant='outlined'
                    type='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <CardInput /> */}
                {/* <div> */}
                    <StyledButton variant="outlined" color="primary" onClick={handlePayment}>
                        {cartContent['online-pay-with-card'][`${language}`]}
                    </StyledButton>
                {/* </div> */}
            {/* </CardContent> */}
            {/* <Button onClick={() => handlePayment()}>Credit Card</Button> */}
        </StyledBox>
    )
}

export default StripeCard;