import React, { useRef, useState } from 'react';
import { Button, Box } from '@mui/material';
import qz from "qz-tray";
// import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';


let rootContent = null;
const storeContent = {};
let data = [];
let tse;

function readContent(inputFetch, output) {

    inputFetch.forEach((el) => {

        let recordContent = {
            en: String,
            ch: String,
            de: String,
            image: Array
        }

        recordContent.en = el.fields.en;
        recordContent.ch = el.fields.ch;
        recordContent.de = el.fields.de;
        recordContent.image = el.fields.image;

        output[`${el.fields.name}`] = recordContent;

        return;
    });
    return;
}

function createData(
    name: string,
    quantity: number,
    price: number,
    tax: string,
) {
    return { name, quantity, price, tax };
}

function chunkString (str, len) {
    const size = Math.ceil(str.length/len)
    const r = Array(size)
    let offset = 0
    
    for (let i = 0; i < size; i++) {
        r[i] = str.substr(offset, len)
        offset += len
    }
    
    return r
}

function readData(orderInput, dataOutput){
    orderInput.forEach((el) => {
        let product = el.fields.name;
        if(product.length > 14){
            let productStringArray = chunkString(product, 14);
            
            //first line
            dataOutput.push(
                createData(
                    `${productStringArray.shift()}-`,
                    el.fields['quantity'],
                    el.fields['original-price'],
                    el.fields.tax
                )
            );
            //rest of the lines
            product = productStringArray.join('-\n');
            dataOutput.push(
                createData(
                    `${product}`,
                    "",
                    "",
                    ""
                )
            );
        } else {
            dataOutput.push(
                createData(
                    `${product}`,
                    el.fields['quantity'],
                    el.fields['original-price'],
                    el.fields.tax
                )
            );
        }
        // if(el.fields.size){
        //     product = `${product}\n${el.fields.size}`
        // }
        // if(el.fields.sugar){
        //     product = `${product}\n${el.fields.sugar}`
        // }
        // if(el.fields.temperature){
        //     product = `${product}\n${el.fields.temperature}`
        // }

        if(el.fields['toppings-1']){
            dataOutput.push(
                createData(
                    el.fields['toppings-1'],
                    el.fields['quantity'],
                    el.fields['toppings-1-price'],
                    el.fields['toppings-1-tax']
                )
            );
        }
        if(el.fields['toppings-2']){
            dataOutput.push(
                createData(
                    el.fields['toppings-2'],
                    el.fields['quantity'],
                    el.fields['toppings-2-price'],
                    el.fields['toppings-2-tax']
                )
            );
            
        }
        if(el.fields['toppings-3']){
            dataOutput.push(
                createData(
                    el.fields['toppings-3'],
                    el.fields['quantity'],
                    el.fields['toppings-3-price'],
                    el.fields['toppings-3-tax']
                )
            );
        }
        if(el.fields.customization){
            dataOutput.push(
                createData(
                    el.fields['customization'],
                    el.fields['quantity'],
                    el.fields['customization-price'],
                    el.fields['customization-tax']
                )
            );
        }
        if(el.fields['discount-name']){
            dataOutput.push(
                createData(
                    el.fields['discount-name'],
                    el.fields['quantity'],
                    `- ${el.fields['discounted-price']}`,
                    ''
                )
            );
        }
    })
}

function interpretDate(input){
    let date = new Date();
    let timeOffset = date.getTimezoneOffset();
    
    let interpretDateArray = input.split('-');
    let year = interpretDateArray[0];
    let month = interpretDateArray[1];
    let day = interpretDateArray[2].substring(0,2);
    let dayAsNumber;
    let interpretTimeArray = input.split('T');  //
    let interpretTimeString = interpretTimeArray[1];  //
    let interpretTimeStringAlong = interpretTimeString.split('.');  //
    let timeString = interpretTimeStringAlong[0];  //
    let timeStringArray = timeString.split(':');
    let hour = +timeStringArray[0];
    let minute = +timeStringArray[1];
    let second = timeStringArray[2];

    if(timeOffset !== 0){
        let timeAbs = Math.abs(timeOffset);
        let offsetHour = Math.round(timeAbs / 60);
        let offsetMinute = timeAbs % 60;
        if(timeOffset > 0){
            hour = hour - offsetHour;
            if(hour < 0){
                dayAsNumber = day * 1;
                dayAsNumber = dayAsNumber - 1;
                // day = dayAsNumber;
                day = dayAsNumber.toString();
                hour = hour + 24;
            }
            minute = minute - offsetMinute;
        } else if(timeOffset < 0){
            hour = hour + offsetHour;
            if(hour >= 24){
                dayAsNumber = day * 1;
                dayAsNumber = dayAsNumber + 1;
                // day = dayAsNumber;
                day = dayAsNumber.toString();
                hour = hour % 24;
            }
            minute = minute + offsetMinute;
        }
    }

    return `${day}.${month}.${year} ${hour}:${minute}:${second}`
}

function numberToTwoDecimalString(number){
    const convertedNumber = (Math.round(number * 100) / 100).toFixed(2);
    return convertedNumber;
}

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
}

function TicketPrinter (props) {

    // const componentRef = useRef();

    const [printProcess, setPrintProcess] = useState('idle');

    rootContent = props.invoiceContent;
    readContent(props.storeContent, storeContent);

    data = [];
    readData(props.order, data);

    function print() {

        tse = undefined;

        setPrintProcess('printing');

        //print invoice
        qz.websocket.connect().then(() => {
            return qz.printers.find('Kasse');
            // return qz.printers.find('EPSON3A209D (Epson Stylus SX420W)');
            // return qz.printers.find('PDFCreator');
        }).then(async (found) => {

            // console.log("FOUND PRINTER", found)
            let dateString;
            // let isoDateString = new Date(tse['tse_end']).toISOString();
            // dateString = interpretDate(isoDateString);
            if(tse){
                dateString = timeConverter(tse['tse_end']);
            } else {
                dateString = interpretDate(props.order[0].fields.created);
            }

            let config = qz.configs.create(found);
            const lineFeed = '\x0A';
            let cmd;
            cmd = [
                '\x1B' + '\x40',          // init
                '\x1B' + '\x61' + '\x31', // center align
                lineFeed,
                '\x1B' + '\x4D' + '\x30', // normal text
                lineFeed,                   // line break
                '\x1B' + '\x61' + '\x30', // left align
                '\x1B' + '\x45' + '\x0D', // bold on
                '\x1B' + '\x21' + '\x30', // em mode on
                'Number:\t',
                `#${props.order[0].fields['order-name']}` + lineFeed,
                '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + lineFeed, // em mode off
                '\x1B' + '\x45' + '\x0A', // bold off
                lineFeed,                   // line break
                '\x1B' + '\x4D' + '\x30', // normal text
                '-----------------------------------------' + lineFeed,
                `${rootContent['invoice-id'].en}: ${props.order[0].fields.id}` + lineFeed,
                `${rootContent.date.en}: ${dateString}` + lineFeed,
                `${rootContent.payment.en}: ${props.order[0].fields.payment}` + lineFeed,
                lineFeed,                   // line break                
                `${storeContent.instagram.en || storeContent.facebook.en ? 'Follow us on:' : ''}` + lineFeed,
                `${storeContent.instagram.en ? `${rootContent.instagram.en}: ${storeContent.instagram.en}\n` : '\n'}`,
                `${storeContent.facebook.en ? `${rootContent.facebook.en}: ${storeContent.facebook.en}\n` : '\n'}`,
                lineFeed,                   // line break                
                // 'Product Name\t',
                // \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t
                // '\x1B' + '\x61' + '\x32', // right align
                // 'Qty\t',
                // 'Price\t',
                // 'Tax\t',
                // lineFeed,
            ];

            //items
            // data !== [] &&
            // await data.forEach((el, index) => {

            //     let item;

            //     item = [
            //         `${el.name}\t`,
            //         '\x1B' + '\x61' + '\x32',
            //         `${el.quantity}\t`,
            //         `${el.price}\t`,
            //         `${el.tax}\t`,
            //         lineFeed
            //     ]
            //     cmd = [...cmd, ...item]

            // })

            //Sum
            cmd = [
                ...cmd,
                '-----------------------------------------' + lineFeed,
                // '\x1B' + '\x61' + '\x32', // right align
                // '\x1B' + '\x45' + '\x0D', // bold on
                // '\x1B' + '\x21' + '\x30', // em mode on
                // `${rootContent.sum.en}: ${numberToTwoDecimalString(props.order[0].fields['order-sum'])}` + lineFeed,
                // '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45', // em mode off
                // '\x1B' + '\x45', // bold off
                // '\x1B' + '\x61' + '\x30', // left align
                lineFeed
            ]

            let end = [
                '\x1B' + '\x4D' + '\x30', // normal text
                lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // 'Baklava (Qty 4)       9.00' + '\x1B' + '\x74' + '\x13' + '\xAA', //print special char symbol after numeric
                // '*****************************************' + lineFeed,       
                // '\x1B' + '\x45' + '\x0D', // bold on
                // 'Here\'s some bold text!',
                // '\x1B' + '\x45' + lineFeed, // bold off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x32', // right align
                // '\x1B' + '\x21' + '\x30', // em mode on
                // 'DRINK ME',
                // '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + lineFeed, // em mode off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // '----------------------------------------------' + lineFeed,
                // '\x1B' + '\x4D' + '\x31', // small text
                // 'EAT ME' + lineFeed,
                // '\x1B' + '\x4D' + '\x30', // normal text
                // '----------------------------------------------' + lineFeed,
                // 'normal text',
                // '\x1B' + '\x61' + '\x30', // left align
                lineFeed + lineFeed + lineFeed + lineFeed,
                // '\x1B' + '\x69',          // cut paper (old syntax)
                // '\x1D' + '\x56'  + '\x00', // full cut (new syntax)
                // '\x1D' + '\x56'  + '\x30' // full cut (new syntax)
                '\x1D' + '\x56'  + '\x01', // partial cut (new syntax)
                // '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
                '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                // **for legacy drawer cable CD-005A.  Research before using.
                // Star TSP100-series kick-out ONLY
                '\x1B' + '\x70' + '\x00' /* drawer 1 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
                // '\x1B' + '\x70' + '\x01' /* drawer 2 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
            ]

            const final = [...cmd, ...end]
            // cmd = final;
            // console.log(final)
            setPrintProcess('printed');
            return qz.print(config, final);
        }).catch((e) => {
            alert(e);
        }).finally(() => {
            return qz.websocket.disconnect();
        });
    }

    return(
        <Box>
            {   props.order.length !== 0 && props.invoiceContent && storeContent && rootContent && printProcess === 'printing' ?
                <Button variant='outlined' onClick={() => print()}>
                    {props.invoiceContent['printing-ticket'][`${props.language}`]}
                </Button>
                :
                <Button variant='outlined' onClick={() => print()}>
                    {props.invoiceContent['print-ticket'][`${props.language}`]}
                </Button>
            }
        </Box>
    )
}

export default TicketPrinter;