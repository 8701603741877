import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import useFetch from '../useFetch';
import Box from '@mui/material/Box';
import CtaAndLargeImageWithTitleAndText from '../pageSections/CtaAndLargeImageWithTitleAndText';
import ThreeSimpleCardsAndTexts from '../pageSections/ThreeSimpleCardsAndTexts';
import ThreeCardsWithEachCardAlongWithDescriptionAndBtn from '../pageSections/ThreeCardsWithEachCardAlongWithDescriptionAndBtn';
import OrganizationIntroduction from '../pageSections/OrganizationIntroduction';
import SimpleFeatures from '../pageSections/SimpleFeatures';
import SimpleFooter from '../pageSections/SimpleFooter';
import TestimonialWithAvatarButtons from '../pageSections/TestimonialWithAvatarButtons';
import SimpleTitleAndButton from '../pageSections/SimpleTitleAndButton';

export default function Section (props){

    const [storeColors, setStoreColors] = useState(props.storeColors)
    const [storeSections, setStoreSections] = useState(props.storeSections)

    function SwitchSection(){
        
        let rootSectionObj
        let storeSectionObj
        let sectionRecordObj = props.storePageBuilderRecord.fields

        switch(sectionRecordObj.sections[0]){

            case storeSections['cta-and-large-image-with-title-and-text'].id:
                storeSectionObj = storeSections['cta-and-large-image-with-title-and-text'];
                return (
                    <CtaAndLargeImageWithTitleAndText
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            sectionRecordObj['text-1'] ?
                            null :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        text-4={
                            sectionRecordObj['text-4-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-4-visibility'] === 'show' && sectionRecordObj['text-4'] ?
                            sectionRecordObj['text-4'] :
                            storeSectionObj['text-4']
                        }
                        images-1={
                            sectionRecordObj['images-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['images-1-visibility'] === 'show' && sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'][0] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1'][0]
                        }
                        color-1={
                            sectionRecordObj['color-1-name'] && sectionRecordObj['color-1-name'][0] ?
                            storeColors[`${sectionRecordObj['color-1-name'][0]}`] :
                            storeColors[`${storeSectionObj['color-1-name'][0]}`]
                        }
                        url-1={
                            sectionRecordObj['url-1'] ?
                            sectionRecordObj['url-1'] :
                            storeSectionObj['url-1']
                        }
                    />
                )
            
            case storeSections['three-simple-cards-and-texts'].id:
                storeSectionObj = storeSections['three-simple-cards-and-texts'];
                return (
                    <ThreeSimpleCardsAndTexts
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                    ></ThreeSimpleCardsAndTexts>
                )

            case storeSections['three-cards-with-each-card-along-with-description-and-btn'].id:
                storeSectionObj = storeSections['three-cards-with-each-card-along-with-description-and-btn'];
                return (
                    <ThreeCardsWithEachCardAlongWithDescriptionAndBtn
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        text-4={
                            sectionRecordObj['text-4-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-4-visibility'] === 'show' && sectionRecordObj['text-4'] ?
                            sectionRecordObj['text-4'] :
                            storeSectionObj['text-4']
                        }
                        text-5={
                            sectionRecordObj['text-5-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-5-visibility'] === 'show' && sectionRecordObj['text-5'] ?
                            sectionRecordObj['text-5'] :
                            storeSectionObj['text-5']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                        color-1={
                            sectionRecordObj['color-1-name'] && sectionRecordObj['color-1-name'][0] ?
                            storeColors[`${sectionRecordObj['color-1-name'][0]}`] :
                            storeColors[`${storeSectionObj['color-1-name'][0]}`]
                        }
                        url-1={
                            sectionRecordObj['url-1'] ?
                            sectionRecordObj['url-1'] :
                            storeSectionObj['url-1']
                        }
                    ></ThreeCardsWithEachCardAlongWithDescriptionAndBtn>
                )
            
            case storeSections['organization-introduction'].id:
                storeSectionObj = storeSections['organization-introduction'];
                return (
                    <OrganizationIntroduction
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                    ></OrganizationIntroduction>
                )
            case storeSections['simple-features'].id:
                storeSectionObj = storeSections['simple-features'];
                return (
                    <SimpleFeatures
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                    ></SimpleFeatures>
                )
            case storeSections['simple-footer'].id:
                storeSectionObj = storeSections['simple-footer'];
                return (
                    <SimpleFooter
                        pages={props.pages}
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        text-4={
                            sectionRecordObj['text-4-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-4-visibility'] === 'show' && sectionRecordObj['text-4'] ?
                            sectionRecordObj['text-4'] :
                            storeSectionObj['text-4']
                        }
                        text-5={
                            sectionRecordObj['text-5-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-5-visibility'] === 'show' && sectionRecordObj['text-5'] ?
                            sectionRecordObj['text-5'] :
                            storeSectionObj['text-5']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                        color-1={
                            sectionRecordObj['color-1-name'] && sectionRecordObj['color-1-name'][0] ?
                            storeColors[`${sectionRecordObj['color-1-name'][0]}`] :
                            storeColors[`${storeSectionObj['color-1-name'][0]}`]
                        }
                        url-1={
                            sectionRecordObj['url-1'] ?
                            sectionRecordObj['url-1'] :
                            storeSectionObj['url-1']
                        }
                        url-2={
                            sectionRecordObj['url-2'] ?
                            sectionRecordObj['url-2'] :
                            storeSectionObj['url-2']
                        }
                    ></SimpleFooter>
                )
            case storeSections['testimonial-with-avatar-buttons'].id:
                storeSectionObj = storeSections['testimonial-with-avatar-buttons'];
                return (
                    <TestimonialWithAvatarButtons
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        text-3={
                            sectionRecordObj['text-3-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-3-visibility'] === 'show' && sectionRecordObj['text-3'] ?
                            sectionRecordObj['text-3'] :
                            storeSectionObj['text-3']
                        }
                        text-4={
                            sectionRecordObj['text-4-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-4-visibility'] === 'show' && sectionRecordObj['text-4'] ?
                            sectionRecordObj['text-4'] :
                            storeSectionObj['text-4']
                        }
                        text-5={
                            sectionRecordObj['text-5-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-5-visibility'] === 'show' && sectionRecordObj['text-5'] ?
                            sectionRecordObj['text-5'] :
                            storeSectionObj['text-5']
                        }
                        images-1={
                            sectionRecordObj['images-1'] && sectionRecordObj['images-1'][0] ?
                            sectionRecordObj['images-1'] :
                            storeSectionObj['images-1'] && storeSectionObj['images-1']
                        }
                        color-1={
                            sectionRecordObj['color-1-name'] && sectionRecordObj['color-1-name'][0] ?
                            storeColors[`${sectionRecordObj['color-1-name'][0]}`] :
                            storeColors[`${storeSectionObj['color-1-name'][0]}`]
                        }
                    ></TestimonialWithAvatarButtons>
                )
            case storeSections['simple-title-and-button'].id:
                storeSectionObj = storeSections['simple-title-and-button'];
                return (
                    <SimpleTitleAndButton
                        text-1={
                            sectionRecordObj['text-1-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-1-visibility'] === 'show' && sectionRecordObj['text-1'] ?
                            sectionRecordObj['text-1'] :
                            storeSectionObj['text-1']
                        }
                        text-2={
                            sectionRecordObj['text-2-visibility'] === 'hide' ?
                            null :
                            sectionRecordObj['text-2-visibility'] === 'show' && sectionRecordObj['text-2'] ?
                            sectionRecordObj['text-2'] :
                            storeSectionObj['text-2']
                        }
                        color-1={
                            sectionRecordObj['color-1-name'] && sectionRecordObj['color-1-name'][0] ?
                            storeColors[`${sectionRecordObj['color-1-name'][0]}`] :
                            storeColors[`${storeSectionObj['color-1-name'][0]}`]
                        }
                        url-1={
                            sectionRecordObj['url-1'] ?
                            sectionRecordObj['url-1'] :
                            storeSectionObj['url-1']
                        }
                    ></SimpleTitleAndButton>
                )
        }
    }
        
    return(
        
        <Box>
            <SwitchSection />
        </Box>
    )

}