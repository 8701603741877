import React from 'react';
import styled from 'styled-components';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import SimpleCardButton from './SimpleCardButton';


const SC_Card = styled(Card)`

    margin: 4px 0;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    text-color: white;
`;

export default function SimpleCardButtonGroup (props){

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [itemSelected, setItemSelected] = React.useState(props.defaultValue && props.defaultValue);

    const handleClick = React.useCallback((record, action) => {

        let selectedRecord = record;
        let selectedAction = action

        if(itemSelected != selectedRecord.fields.name){
            setItemSelected(record.fields.name);
        } else {
            setItemSelected();
        }
        props.onClick(selectedRecord, selectedAction);
    }); 

    return(

        <Grid container spacing={1}>
            {
                props.content && props.records?.map((el, index) => {
                    return (
                        <Grid key={index} item xs={ lg ? 3 : sm ? 6 : 12} sx={{ display: 'flex' }}>
                            <SimpleCardButton
                                data={el}
                                warning={props.content['sold-out'] && props.content['sold-out'][`${props.language}`]}
                                selected={itemSelected === el.fields.name ? true : false}
                                active={el.fields['sold-out'] ? false : true}
                                title={el.fields[`${props.language}`]}
                                text={el.fields.price !== undefined || null ? `${el.fields.price} €` : null }
                                image={el.fields.image && el.fields.image[0].thumbnails.large.url}
                                onClick={(record, action) => handleClick(record, action)}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>

    )

}