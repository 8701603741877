import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const styles = {
    upButton: {
        zIndex: 2,
        position: 'fixed',
        bottom: '3vh',
        backgroundColor: '#DCDCDC',
        color: 'black !important',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: 'white !important',
            backgroundColor: '#2185D5'
        },
        right: 'calc(5% + 64px)'
    },
    downButton: {
        zIndex: 2,
        position: 'fixed',
        bottom: '3vh',
        backgroundColor: '#DCDCDC',
        color: 'black !important',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: 'white !important',
            backgroundColor: '#2185D5'
        },
        right: 'calc(5% + 128px)'
    }
}

const ScrollButtons = ({
    showBelow
}) => {
    // const classes = useStyles();

    const panel = document.querySelector("#container");

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if(window.scrollY > showBelow) {
            if(!show) setShow(true)
        } else {
            if(show) setShow(false)
        }
    }

    const handleClickUp = () => {
        window[`scrollTo`]({ top: panel.scrollTop, behavior: `smooth` })
        console.log(panel)
    }

    const handleClickDown = () => {
        window[`scrollTo`]({ top: panel.scrollDown, behavior: `smooth` })
    }

    useEffect(() => {
        if(showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })
    return (
        <Box>
            {   show &&
                <Box sx={{ display: "flex" }}>
                    <Fab size="medium" color="secondary" aria-label="add" sx={styles.downButton} onClick={handleClickDown}>
                        <ArrowDownwardIcon style={{color: 'unset'}} />
                    </Fab>
                    <Fab size="medium" color="secondary" aria-label="add" sx={styles.upButton} onClick={handleClickUp}>
                        <ArrowUpwardIcon style={{color: 'unset'}} />
                    </Fab>
                </Box>
            }
        </Box>
    )
}

export default ScrollButtons;