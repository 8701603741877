import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { device } from '../device';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Section = styled(Box)(({theme}) => `
    margin: 32px 0px;
`);

const Header = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0px 16px;
    @media ${device.tablet} {
        align-items: center;
        text-align: center;
    }
`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    margin: 32px 0px;
    width: 100%;
    @media ${device.tablet} {
        justify-content: center;
    }
`);

const TextsBox = styled(Box)(({theme}) => `

`);

const TitleBox = styled(Box)(({theme}) => `

`);

const DescriptionBox = styled(Box)(({theme}) => `
    @media ${device.tablet} {
        max-width: 420px;
    }
`);

const ItemsSection = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    width: 100%;
    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
    @media ${device.laptop} {
        justify-content: center;
        max-width: 800px;
    }
`);

const ItemBox = styled(Box)(({theme}) => `

`);

const Item = styled(Box)(({theme, image_url}) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0px 16px;
    @media ${device.tablet} {
        max-width: 320px;
        margin: 0 auto;
    }
    @media ${device.laptop} {
        margin: 0 64px;
    }
`);

const ItemTitleBox = styled(Box)(({theme}) => `

`);

const ItemTexts = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
`);

const ItemDescription = styled(Box)(({theme}) => `

`);

const ItemBtnBox = styled(Box)(({theme}) => `

`);

const Image = styled('img')(({ theme }) => `
    border-radius: 10px;
    width: 100%;
    @media ${device.mobileL} {
        width: 328px;
    }
`);

const ButtonsCol = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`);

const Chips = styled(Box)(({theme}) => `
    display: flex;
    gap: 12px;
    overflow-x: scroll;
    padding: 0px 16px;
    @media ${device.tablet} {
        flex-direction: column;
        overflow: hidden;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`);

const ChipNavBtns = styled(Box)(({theme}) => `
    display: flex;
    gap: 10px;
    justify-content: center;
`);

const ChipBox = styled(Box)(({theme}) => `

`);

const ScCard = styled(Card)(({theme, active, color}) => `
    border-radius: 76px 0px 0px 76px;
    background-color: ${active === 'true' ? color : 'unset'};
    border: 0.5px solid ${active === 'true' ? 'unset' : color + 'cc'};
    display: flex;
    gap: 6px;
    justify-content: flex-start;
    padding: 8px 20px 8px 8px;
    width: 328px;
`);

const ButtonAvatar = styled(Avatar)(({theme}) => `
    height: 80px;
    width: 80px;
`);

const ChipButtonTextBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    text-align: left;
`);

const ChipButtonTitle = styled(Typography)(({theme}) => `
    margin-bottom: 10px;
`);

const CardButton = styled(Button)(({theme}) => `

`);

const Example = styled(Box)(({theme}) => `

`);

export default function ThreeCardsWithEachCardAlongWithDescriptionAndBtn (props){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));

    const [activeItem, setActiveItem] = useState(0);

    function handleActiveItem(inputIndex) {
        setActiveItem(inputIndex);
    }

    function cutString(s, n){
        var cut= s.indexOf(' ', n);
        if(cut== -1) return s;
        return s.substring(0, cut)
    }

    function handleClick(inputUrl){
        if(inputUrl.substring(0,7) === 'http://'){
            inputUrl = 'https://' + inputUrl.substring(7);
        } else if(inputUrl.substring(0,8) !== 'https://'){
            inputUrl = 'https://' + inputUrl;
        }

        window.open(inputUrl, '_blank', 'noopener,noreferrer');
    }

    function switchCard(action, total) {
        let lastIndex = total - 1;
        let currActiveItem = activeItem;
        if(action === 'up'){
            if(activeItem === 0) {
                setActiveItem(lastIndex);
            } else {
                let indexOfItemBefore = currActiveItem - 1;
                setActiveItem(indexOfItemBefore);
            }
        } else if (action === 'down') {
            if(activeItem === lastIndex) {
                setActiveItem(0);
            } else {
                let indexOfItemAfter = currActiveItem + 1;
                setActiveItem(indexOfItemAfter);
            }
        }
    }

    useEffect(() => {

    }, [activeItem])

    function renderItem(){
        
        let itemTitles = props['text-3'].split('/*/');
        let itemDescriptions = props['text-4'].split('/*/');
        let itemUrls;
        if(props['url-1'] && props['url-1'].indexOf('/*/')){
            itemUrls = props['url-1'].split('/*/');
        } else if (props['url-1']) {
            itemUrls = [props['url-1']];
        } else {
            itemUrls = ['www.mappals.app/map/recrnq4r5tmTPXuhN/en']
        }

        let btnText = props['text-5'];

        let itemImages = props['images-1'];

        let itemObjs = [];

        for(let i = 0; i < itemTitles.length; i++){
            if(itemImages[i] && itemDescriptions[i]) {
                let itemObj = {
                    title: String,
                    description: String,
                    url: String,
                    imageUrl: String
                }
                itemObj.title = itemTitles[i];
                itemObj.description = itemDescriptions[i];
                if(itemUrls[i]) {
                    itemObj.url = itemUrls[i];
                } else if (itemUrls[0]){
                    itemObj.url = itemUrls[0];
                } else {
                    itemObj.url = ''
                }
                itemObj.imageUrl = itemImages[i].thumbnails.large.url;
                itemObjs.push(itemObj);
            }
        }

        return (
            <ItemsSection>
                <Item >
                    {   itemImages &&
                        <Image 
                            src={`${itemImages[activeItem].thumbnails.large.url}`}
                            srcSet={`${itemImages[activeItem].thumbnails.large.url}`}
                            alt={
                                itemTitles && itemTitles[activeItem] &&
                                itemTitles[activeItem]
                            }
                            loading="lazy"
                        />
                    }
                    <ItemTexts>
                        <ItemTitleBox>
                            <Typography variant='h6_bold'>
                                {
                                    itemTitles && itemTitles[activeItem] &&
                                    itemTitles[activeItem]
                                }
                            </Typography>
                        </ItemTitleBox>
                        <ItemDescription>
                            <Typography variant='body1'>
                                {
                                    itemDescriptions && itemDescriptions[activeItem] &&
                                    itemDescriptions[activeItem]
                                }
                            </Typography>
                        </ItemDescription>
                    </ItemTexts>
                    {   !md &&
                        <ItemBtnBox>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => handleClick(itemUrls[activeItem] ? itemUrls[activeItem] : itemUrls[0] ? itemUrls[0] : '')}
                            >
                                {
                                    btnText && 
                                    btnText
                                }
                            </Button>
                        </ItemBtnBox>
                    }
                </Item>
                <ButtonsCol>
                    <Chips>
                        {
                            itemObjs !== [] && itemObjs?.map((el, index) => {
                                let elIndex = index;
                                return (
                                    <ChipBox key={index}>
                                        {   md ?
                                            <ScCard
                                                active={elIndex === activeItem ? 'true' : 'false'}
                                                color={elIndex === activeItem ? theme.palette.primary.main : theme.palette.secondary.main}
                                                onClick={() => handleActiveItem(elIndex)}
                                            >
                                                <ButtonAvatar 
                                                    alt="Natacha" 
                                                    src={`${el.imageUrl}`}
                                                />
                                                <ChipButtonTextBox>
                                                    <ChipButtonTitle
                                                        color={elIndex === activeItem ? theme.palette.white.main : theme.palette.black.main}
                                                    >
                                                        {
                                                            el.title
                                                        }
                                                    </ChipButtonTitle>
                                                    {
                                                        elIndex !== activeItem &&
                                                        <Typography variant='caption'>
                                                            {
                                                                cutString(el.description, 60)
                                                            } ...
                                                        </Typography>
                                                    }
                                                    {
                                                        elIndex === activeItem &&
                                                        <Box>
                                                            <CardButton
                                                                variant="contained"
                                                                color="secondary"
                                                                size="small"
                                                                disabled={
                                                                    elIndex === activeItem ?
                                                                    false
                                                                    :
                                                                    true
                                                                }
                                                                onClick={() => handleClick(el.url)}
                                                            >
                                                                {
                                                                    btnText &&
                                                                    btnText
                                                                }
                                                            </CardButton>
                                                        </Box>
                                                    }
                                                </ChipButtonTextBox>
                                            </ScCard>
                                            :
                                            <Chip
                                                avatar={
                                                    <Avatar 
                                                        alt="Natacha" 
                                                        src={`${el.imageUrl}`}
                                                    />
                                                }
                                                label={`${el.title}`}
                                                variant={
                                                    elIndex === activeItem ?
                                                    "contained"
                                                    :
                                                    "outlined"
                                                }
                                                color={
                                                    elIndex === activeItem ?
                                                    "primary"
                                                    :
                                                    "secondary"
                                                }
                                                onClick={() => handleActiveItem(elIndex)}
                                            />
                                        }
                                    </ChipBox>
                                )
                            })
                        }
                    </Chips>
                    {
                        md &&
                        <ChipNavBtns>
                            <Fab
                                size="medium" 
                                color="secondary" 
                                aria-label="add"
                                onClick={() => switchCard('up', itemTitles.length)}
                            >
                                <FontAwesomeIcon icon={faChevronUp} size='lg' />
                            </Fab>
                            <Fab
                                size="medium" 
                                color="secondary" 
                                aria-label="add"
                                onClick={() => switchCard('down', itemTitles.length)}
                            >
                                <FontAwesomeIcon icon={faChevronDown} size='lg' />
                            </Fab>
                        </ChipNavBtns>
                    }
                </ButtonsCol>
            </ItemsSection>
        )
    }

    return(

        <Section>
            <Header>
                {   props['text-1'] &&
                    <TitleBox>
                        <Typography variant='h3'>
                            {props['text-1']}
                        </Typography>
                    </TitleBox>
                }
                {   props['text-2'] &&
                    <DescriptionBox>
                        <Typography variant='body1'>
                            {props['text-2']}
                        </Typography>
                    </DescriptionBox>
                }
            </Header>
            <ContentBox>
                {
                    props['text-3'] && props['text-4'] && props['text-5'] &&
                    renderItem()
                }
            </ContentBox>
        </Section>

    )

}