import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {Accordion, Box, AccordionSummary, AccordionDetails} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import StoreInfo from "./StoreInfo";
import MoreInfo from "./MoreInfo";

const ScAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    // "&:not(:last-child)": {
    //     borderBottom: 0,
    // },
    // "&:before": {
    //     display: "none",
    // },
}));

// const AccordionSummary = styled((props) => (
//     <MuiAccordionSummary
//         expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//         {...props}
//     />
// ))(({ theme }) => ({
    // backgroundColor:
    //     theme.palette.mode === "dark"
    //         ? "rgba(255, 255, 255, .05)"
    //         : "rgba(0, 0, 0, .03)",
    // flexDirection: "row-reverse",
    // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    //     transform: "rotate(90deg)",
    // },
    // "& .MuiAccordionSummary-content": {
    //     marginLeft: theme.spacing(1),
    // },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    // borderTop: "1px solid rgba(0, 0, 0, .125)",
// }));

export default function StoreInfoAccordion(props) {
    const [expanded, setExpanded] = React.useState();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box>
            <ScAccordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    id="panel1d-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        {props.rootContent["restaurant-reminder"] &&
                            props.rootContent["restaurant-reminder"][
                                `${props.language}`
                            ]}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MoreInfo
                        language={props.language}
                        rootContent={props.rootContent}
                        storeContent={props.storeContent}
                    />
                </AccordionDetails>
            </ScAccordion>
        </Box>
    );
}
