import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBarCart from './AppBarCart';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { device } from './device';
import Menu from '@mui/material/Menu';
import { Button } from '@mui/material';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const AppBarBox = styled(Box)`

`;

const LanguageButtons = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 200px;
    margin: 0 auto;
`;

const LanguageBox = styled(Box)(({theme}) => ({

}));

const ScChip = styled(Chip)(({theme}) => ({
    border: `1px solid ${theme.palette.secondary.main}`,
}));

const ScPlaceHolderBox = styled(Box)(({ theme }) => ({
    height: '80px',
}));


const ScAppBar = styled(AppBar)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // backgroundColor: theme.palette.primary.background,
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.primary,
}));

const ScPaper = styled(Paper)(({ theme }) => ({
    
}));

const ScToolbar = styled(Toolbar)(({ theme }) => ({
    padding: `0px ${theme.spacing(2)}`,
}));

const PlaceHolderBar = styled(AppBar)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: theme.palette.primary.background,
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.primary,
    padding: '16px',
    position: 'static',
    opacity: 0
}));

const SmallImageBox = styled(Box)`
    display: flex;
`

const SmallTypography = styled(Typography)`
    display: none;
    @media ${device.mobileM} {
        display: flex;
    }
`
function Navigation(props) {

    const history = useHistory();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const {store, language} = useParams();
    
    function passCleanUpCart(){
        props.onCleanUpCart();
    }

    function passHandleChangeQuantity(action, inputItem){
        props.onHandleChangeQuantity(action, inputItem);
    }

    function handlePassNote(noteInput) {
        props.passNote(noteInput);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLanguageSwitch = React.useCallback((storeInput, languageInput) => {
            
        {   props.cashier ?
            history.push(`/cashier/${storeInput}/${languageInput}`)
            :
            history.push(`/main/${storeInput}/${languageInput}`)
        }
        
        setAnchorElUser(null);

    },[history]); 

    return (
        <AppBarBox>
            <CssBaseline />
            <ScPlaceHolderBox />
            <HideOnScroll {...props}>
                <ScAppBar>
                    {   props.language && props.rootContent && props.storeContent && props.settings && props.notes &&
                        <ScPaper square={true} elevation={4}>
                            <ScToolbar>
                                {   props.logo ?
                                    <SmallImageBox sx={{  mr: 2 }}>
                                        <img
                                            sx={{ "objectFit": "contain" }}
                                            src={`${props.logo.thumbnails.small.url}`}
                                            srcSet={`${props.logo.thumbnails.large.url}`}
                                            alt='logo'
                                            loading='lazy'
                                            width={40}
                                            height={40}
                                        />
                                    </SmallImageBox>
                                    :
                                    <AdbIcon />
                                }

                                <SmallTypography
                                    variant="h5"
                                    // noWrap
                                    component="a"
                                    href=""
                                        sx={{
                                        mr: 2,
                                        flexGrow: 1,
                                        // fontFamily: 'monospace',
                                        fontWeight: 700,
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {   
                                        props.storeContent['restaurant-name'] && props.storeContent['restaurant-name'][`${props.language}`]
                                    }
                                </SmallTypography>

                                {/* blank */}
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>

                                </Box>

                                <Stack 
                                    spacing={2} 
                                    direction='row'
                                    sx={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <AppBarCart 
                                        cashier={props.cashier ? true : false} 
                                        purchases={props.purchases} 
                                        total={props.total} 
                                        settings={props.settings} 
                                        notes={props.notes}
                                        savedNote={props.savedNote && props.savedNote}
                                        passNote={(noteRecord) => handlePassNote(noteRecord)}
                                        onCleanUpCart={() => passCleanUpCart()} 
                                        onHandleChangeQuantity={(action, inputItem) => passHandleChangeQuantity(action, inputItem)}     
                                    />
                                    <LanguageBox sx={{ flexGrow: 0, ml: 3 }}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                                            <Stack direction="row" spacing={1}>
                                                {   props.rootContent && props.rootContent['language-button'] && props.rootContent['language-button'][`${language}`] &&
                                                    <ScChip 
                                                        variant='outlined'
                                                        color='primary'
                                                        label={`${props.rootContent && props.rootContent['language-button'][`${language}`]}`}
                                                    />
                                                }
                                            </Stack>
                                        </IconButton>
                                        <Menu
                                            sx={{ mt: '8px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                                <LanguageButtons>
                                                    <Button onClick={() => {handleLanguageSwitch(store, 'en')}}>{ props.rootContent && props.rootContent['language-button'] &&  props.rootContent['language-button'].en}</Button>
                                                    <Button onClick={() => {handleLanguageSwitch(store, 'ch')}}>{ props.rootContent && props.rootContent['language-button'] &&  props.rootContent['language-button'].ch}</Button>
                                                    <Button onClick={() => {handleLanguageSwitch(store, 'de')}}>{ props.rootContent && props.rootContent['language-button'] &&  props.rootContent['language-button'].de}</Button>
                                                </LanguageButtons>
                                        </Menu>
                                    </LanguageBox>
                                </Stack>
                            </ScToolbar>
                        </ScPaper>
                    }
                </ScAppBar>
            </HideOnScroll>
        </AppBarBox>
    );
}

export default Navigation;