import React from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NumberDisplay = styled(Chip)(({theme}) => ({
    boxSizing: 'border-box',
    borderRadius: '5px !important',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: '0 24px',
    width: '100%',
}));

function ProductDial(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    // const [value, setValue] = React.useState(props.currValue);

    function handleMinus(){
        // setValue( (prevValue)=> {
        //     return prevValue - 1;
        // } )
        props.onChange('minus', props.item);
    }
    
    function handlePlus(){
        // setValue( (prevValue)=> {
        //     return prevValue + 1;
        // } )
        props.onChange('plus', props.item);
    }



    return (
        <Stack spacing={1} 
            direction={{
                xs: 'column-reverse',
                sm: 'row'
            }}
            sx={{
                mr: 'auto',
                mt: {
                    xs: 1,
                    md: 'unset'
                }
            }}
        >
            <Button variant="contained" onClick={() => handleMinus()}>
                <FontAwesomeIcon icon={faMinus} />
            </Button>
            <NumberDisplay 
                label={props.item['quantity']} 
                variant='filled'
                
            />
            <Button variant="contained" onClick={() => handlePlus()}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </Stack>
    )
}

export default ProductDial;