import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import useFetch from '../useFetch';
import readAirtableData from '../../hooks/readAirtableData';
import Box from '@mui/material/Box';
import Section from './Section';
import { styled } from '@mui/material/styles';

const ScBox = styled(Box)(({theme}) => ({
    
}));

export default function Sections (props){

    const {page, store, language} = useParams();

    const [url_store_colors, setUrl_store_colors] = useState(`/store/colors/${store}`);
    const [url_store_sections, setUrl_store_sections] = useState(`/store/sections/${store}`);

    const { data: data_store_colors, isPending: isPending_store_colors, error: error_store_colors } = useFetch(url_store_colors);
    const { data: data_store_sections, isPending: isPending_store_sections, error: error_store_sections } = useFetch(url_store_sections);

    const [storeColors, setStoreColors] = useState();
    const [storeSections, setStoreSections] = useState();

    useEffect(() => {

        let storeColorsMemory ={};
        let storeSectionsMemory ={};

        if(data_store_colors){
            readAirtableData(data_store_colors, storeColorsMemory);
            setStoreColors(storeColorsMemory);
        }

        if(data_store_sections){
            readAirtableData(data_store_sections, storeSectionsMemory);
            setStoreSections(storeSectionsMemory);
        }

    }, [
        data_store_colors,
        data_store_sections
    ])

    return(

        <ScBox >
            {   data_store_colors && data_store_sections &&
                storeColors && storeSections &&
                props.storePageBuilder?.map( (record, index) => {
                    return (
                        <Section 
                            key={index}
                            pages={props.pages}
                            storePageBuilderRecord={record}
                            storeColors={storeColors}
                            storeSections={storeSections}
                        />
                    )
                })
            }
        </ScBox>

    )

}