export default {
    mapStyleDefault: [
      {
        "featureType": "administrative",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "stylers": [
          {
            "color": "#dedede"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "color": "#2185d5"
          },
          {
            "saturation": -20
          },
          {
            "lightness": 45
          },
          {
            "visibility": "simplified"
          },
          {
            "weight": 1.5
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": 60
          },
          {
            "lightness": 5
          },
          {
            "visibility": "simplified"
          },
          {
            "weight": 1.5
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#6e6e6e"
          },
          {
            "saturation": -35
          },
          {
            "lightness": 10
          },
          {
            "weight": 3
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "color": "#2185d5"
          }
        ]
      }
    ],
    mapStyleDark: [
      {
        "stylers": [
          {
            "color": "#2185d5"
          },
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "color": "#2185d5"
          }
        ]
      }
    ]
  }