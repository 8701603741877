import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import useFetch from './useFetch';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { device } from './device';
import readAirtableData from '../hooks/readAirtableData';
import Fab from '@mui/material/Fab';
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

let rootContent = {};
let storeContent = {};

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const FABBox = styled(Box)`
    ${'' /* right: 16px; */}
    margin-left: auto;
    ${'' /* position: fixed; */}
    ${'' /* top: 20px; */}
    z-index: 99;
`;

const AppBarBox = styled(Box)`
    ${'' /* height: 128px; */}
    @media ${device.mobileM} {
        ${'' /* height: 96px; */}
    }
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -2,
        top: -2,
        border: `2px solid #2185D5`,
        padding: '0 4px',
    },
}));


const ScAppBar = styled(AppBar)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: theme.palette.white.main,
    // boxShadow: 'unset',
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.primary,
    padding: '16px',
    position: 'fixed',
}));

const NavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const drawerWidth = 240;

function SiteNavigation(props) {

    const {store, language} = useParams();

    const history = useHistory();

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    
    const [url_rootContent, setUrl_rootContent] = useState('/root/site-navigation');
    const [url_storeContent, setUrl_storeContent] = useState(`/store/site-navigation/${store}`);
    const [url_storePages, setUrl_storePages] = useState(`/store/pages/${store}`);
    
    const { data: data_rootContent, isPending: isPending_rootContent, error: error__rootContent } = useFetch(url_rootContent);
    const { data: data_storeContent, isPending: isPending_storeContent, error: error__storeContent } = useFetch(url_storeContent);
    const { data: data_storePages, isPending: isPending_storePages, error: error__storePages } = useFetch(url_storePages);
    
    const [filteredStorePages, setFilteredStorePages] = useState(data_storePages);
    
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    
    const container = window !== undefined ? () => window().document.body : undefined;
    
    function handleDirectHome() {
        props.port === 'cashier' ? history.push(`/cashier/${store}/${language}`) : history.push(`/main/${store}/${language}`)
    }
    
    function handleNav(page){
        history.push(`/page/${page}/${store}/${language}`);
        history.go(0);
    }
    
    useEffect(() => {
        if(data_rootContent){
            readAirtableData(data_rootContent, rootContent);
        }
        if(data_storeContent){
            readAirtableData(data_storeContent, storeContent);
        }

        data_storePages && setFilteredStorePages(
            data_storePages.filter( record => {
                    return record.fields.use;
                }
            )
        )

    },[data_rootContent, data_storeContent, data_storePages]);
    
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                {   
                    rootContent !== {} && rootContent['pages'] && rootContent['pages'][`${language}`]
                }
            </Typography>
            <Divider />
            <List>
            {filteredStorePages?.map((pageRecord, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleNav(pageRecord.fields.url)}>
                        <ListItemText primary={pageRecord.fields[`${language}`]} />
                    </ListItemButton>
                </ListItem>
            ))}
            </List>
        </Box>
    );

    return (
        <AppBarBox>
            <CssBaseline />
            <HideOnScroll {...props}>
                <ScAppBar component='nav'>
                    {   store && language && rootContent !== {} && storeContent !== {} &&
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>

                                {/* hamburger */}
                                {   filteredStorePages && filteredStorePages.length > 0 &&
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2, display: { md: 'none' } }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                }

                                {/* logo */}
                                {   rootContent['store-logo'] ?
                                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }}>
                                        <img
                                            sx={{ "objectFit": "contain" }}
                                            src={storeContent['store-logo'] ? `${storeContent['store-logo'].image[0].thumbnails.small.url}` : `${rootContent['store-logo'].image[0].thumbnails.small.url}`}
                                            srcSet={storeContent['store-logo'] ? `${storeContent['store-logo'].image[0].thumbnails.small.url}` : `${rootContent['store-logo'].image[0].thumbnails.small.url}`}
                                            alt='logo'
                                            loading='lazy'
                                            width={40}
                                            height={40}
                                        />
                                    </Box>
                                    :
                                    <AdbIcon />

                                }
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="a"
                                    href={props.port === 'cashier' ? `/cashier/${store}/${language}` : `/main/${store}/${language}`}
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        // fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        textAlign: 'left'
                                    }}
                                >
                                    {   
                                        storeContent['store-name'] && storeContent['store-name'][`${language}`]
                                    }
                                </Typography>

                                {   rootContent['store-logo'] ?
                                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 2 }}>
                                        <img
                                            sx={{ "objectFit": "contain" }}
                                            src={storeContent['store-logo'] ? `${storeContent['store-logo'].image[0].thumbnails.small.url}` : `${rootContent['store-logo'].image[0].thumbnails.small.url}`}
                                            srcSet={storeContent['store-logo'] ? `${storeContent['store-logo'].image[0].thumbnails.small.url}` : `${rootContent['store-logo'].image[0].thumbnails.small.url}`}
                                            alt='logo'
                                            loading='lazy'
                                            width={40}
                                            height={40}
                                        />
                                    </Box>
                                    :
                                    <></>

                                }
                                <Typography
                                    variant="h6"
                                    // noWrap
                                    component="a"
                                    href={props.port === 'cashier' ? `/cashier/${store}/${language}` : `/main/${store}/${language}`}
                                        sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'none' },
                                        flexGrow: 1,
                                        // fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        textAlign: 'left'
                                    }}
                                >
                                    {   
                                        storeContent['store-name'] && storeContent['store-name'][`${language}`]
                                    }
                                </Typography>

                                {/* pages */}
                                {   filteredStorePages && filteredStorePages.length > 0 &&
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                        {filteredStorePages?.map((pageRecord, index) => (
                                            <NavButton key={index} onClick={() => handleNav(pageRecord.fields.url)}>
                                                {pageRecord.fields[`${language}`]}
                                            </NavButton>
                                        ))}
                                    </Box>
                                }
                                
                                {/* start blank */}
                                <Box sx={{ flexGrow: 1, display: 'flex'  }}>

                                </Box>
                                {/* end blank */}
                                
                                {/* cta */}
                                <FABBox sx={{ display: { xs: 'flex', md: 'none' } }}>
                                    <Fab 
                                        size="medium" 
                                        color={'primary'} 
                                        aria-label="add" 
                                        onClick={() => handleDirectHome()}
                                    >
                                        <StyledBadge>
                                            <FontAwesomeIcon icon={faHouse} size='lg' />
                                        </StyledBadge>
                                    </Fab>
                                </FABBox>
                                <FABBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <Button
                                        variant="contained" 
                                        className="btn btn-success" 
                                        // onClick={() => props.openPayment()}
                                        onClick={() => handleDirectHome()}
                                        size="large"
                                    >
                                        {storeContent['cta'] && storeContent['cta'][`${language}`]}
                                    </Button>
                                </FABBox>
                            </Toolbar>
                        </Container>
                    }
                    {/* drawer */}
                    {   filteredStorePages && filteredStorePages.length > 0 &&
                        <Box component="nav">
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    }
                </ScAppBar>
            </HideOnScroll>
        </AppBarBox>
    );
}

SiteNavigation.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SiteNavigation;