async function readAirtableData(inputFetch, outputObj) {

    inputFetch && await inputFetch?.map((el) => {

        // let recordContent = {
        //     en: String,
        //     ch: String,
        //     de: String
        // }
        let recordContent = {
            ...el.fields,
            id: el.id
        };

        // recordContent.en = el.fields.en;
        // recordContent.ch = el.fields.ch;
        // recordContent.de = el.fields.de;

        outputObj[`${el.fields.name}`] = recordContent;

        return;
    });
    return outputObj;
}

export default readAirtableData;