import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { device } from '../device';

const Section = styled(Box)(({theme}) => `
    border-radius: 20% 64% 10% 16% / 7% 15% 0% 0%;
    background-color: ${theme.palette.black.o_eightyseven};
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
`)
const TitleBox = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
    display: flex;
    padding: 0px 16px 0px 36px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    @media ${device.tablet} {
        width: 560px;
        padding: 0px 16px 0px 68px;
        align-items: flex-start;
    }

    @media ${device.laptop} {
        display: flex;
        width: 800px;
        padding: 0px 16px 0px 52px;
    }
`)
const ButtonBox = styled(Box)(({theme}) => `
    display: flex;
    padding: 0px 36px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;

    @media ${device.tablet} {
        padding: 0px 68px;
    }

    @media ${device.laptop} {
        padding: 0px 52px;
    }

`)

export default function SimpleTitleAndButton (props){

    function handleClick(inputUrl){
        if(inputUrl.substring(0,7) === 'http://'){
            inputUrl = 'https://' + inputUrl.substring(7);
        } else if(inputUrl.substring(0,8) !== 'https://'){
            inputUrl = 'https://' + inputUrl;
        }

        window.open(inputUrl, '_blank', 'noopener,noreferrer');
    }

    return(

        <Section>
            <TitleBox>
                {   props['text-1'] &&
                    <Typography variant='h3'>
                        {props['text-1']}
                    </Typography>
                }
            </TitleBox>
            <ButtonBox>
                {   props['url-1'] &&
                    <Button 
                        variant='contained'
                        onClick={() => handleClick(props['url-1'])}
                    >
                        {   props['text-2'] &&
                            <Typography variant='button'>
                                {props['text-2']}
                            </Typography>
                        }
                    </Button>
                }
            </ButtonBox>
        </Section>

    )

}