import React from 'react';
import PDFToPrint from './PDFToPrint';
import LabelToPrint from './LabelToPrint';

export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            {/* PDFToPrint deprecated */}
            {   props.invoice &&
                <PDFToPrint order={props.order} rootContent={props.rootContent} storeContent={props.storeContent} />
            }
            {   props.label &&
                <LabelToPrint title={props.title} order={props.order}/>
            }
        </div>
    );
});