import React, {useState} from 'react';
import { Button, Typography, Box, Stack } from '@mui/material';
import styled from 'styled-components';
import useFetch from './useFetch';
import Size from './Size';
import Loading from './Loading';
import Alert from '@mui/material/Alert';
import WindowScrollButtons from './WindowScrollButtons';
import SimpleButtonsGroup from './SimpleButtonsGroup';
import IconButtonsGroup from './IconButtonsGroup';
import CustomizationSection from './CustomizationSection';
import ExtraSection from './ExtraSection';
import DiscountSection from './DiscountSection';

const Container = styled(Stack)`
    background-color: #f2f2f2;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
    height: 100%;
    left: 0;
    margin: 0 auto !important;
    max-width: 1440px;
    overflow-x: hidden;
    padding: 16px 16px;
    ${'' /* position: absolute; */}
    top: 0;
    width: calc(80vw - 32px);
`;

const Div = styled.div`
    display: inline;
    text-align: left;
`;

const LoadingBox = styled.div`
    align-items: flex-start;
    background-color: #f2f2f2;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 80vw;
`;

const ContainerStack = styled(Stack)`
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
    margin: 0 auto !important;
    max-width: 1440px;
    ${'' /* padding: 16px 16px; */}
    position: relative;
`;

const MainStack = styled(Stack)`
    background-color: white;
    border-radius: 50px 50px 0px 0px;
    padding: 16px 16px;
    position: relative;
    top: 340px;
`;


const ButtonsSectionBox = styled(Stack)`

`;

const ButtonsGroupBox = styled(Box)`
    background-color: #E0E0E0;
    border-radius: 10px;
    display: flex;
    margin: 4px 0;
`;

const BackButtonDiv = styled(Box)`
    display: inline;
    left: 20px;
    position: fixed;
    top: 20px;
    text-align: left;
`;

const ImageHolder = styled(Box)`
    ${'' /* display: inline; */}
    position: fixed;
`;

const DragLineHolder = styled(Box)`
    ${'' /* max-width: 64px; */}
    display: flex;
    width: 100%;
    justify-content: center;
`;

let rendered = false;

let images = [];

const allSugar = [
    {
        label: '0-sugar',
        value: 0
    },
    {
        label: '1-sugar',
        value: 1
    },
    {
        label: '3-sugar',
        value: 3
    },
    {
        label: '5-sugar',
        value: 5
    },
    {
        label: '7-sugar',
        value: 7
    },
    {
        label: '10-sugar',
        value: 10
    },
];

const allTemperatures = [
    {
        label: 'no-ice',
        value: 'no-ice'
    },
    {
        label: 'less-ice',
        value: 'less-ice'
    },
    {
        label: 'normal',
        value: 'normal'
    },
    {
        label: 'more',
        value: 'more'
    },
    {
        label: 'warm',
        value: 'warm'
    },
];

function CashierProductPage(props) {

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState();

    const [productItem, setProductItem] = useState(props.productItem);
    const [sugar, setSugar] = useState();
    const [temperature, setTemperature] = useState();

    let toppingIndexes = [];

    const [item, setItem] = useState({
        'id': Number,
        'product': {},
        'size': null,
        'sugar': 'NaN',
        'sugar-name': 'NaN',
        'temperature': 'NaN',
        'temperature-name': 'NaN',
        'toppingIds': [],
        'toppings': [],
        'customization': {},
        'extra-price': 0,
        'customization-price': 0,
        'cup-price': 0,
        'total-price': 0,
        'payment': '',
        'discount-type': '',
        'discount-value': 0,
        'discounted-price': 0,
        'discounted-total-price': 0,
        'discount': null,
        'post-from': 'cashier',
        'quantity': 1
    });

    const itemMemory = {
        'id': Number,
        'product': {},
        'size': null,
        'sugar': 'NaN',
        'sugar-name': 'NaN',
        'temperature': 'NaN',
        'temperature-name': 'NaN',
        'toppingIds': [],
        'toppings': [],
        'customization': {},
        'extra-price': 0,
        'customization-price': 0,
        'cup-price': 0,
        'total-price': 0,
        'payment': '',
        'discount-type': '',
        'discount-value': 0,
        'discounted-price': 0,
        'discounted-total-price': 0,
        'discount': null
    }


    const handleClose = React.useCallback(() => { 
        props.onClose();
    },[props]); 

    const store = props.store;
    const language = props.language;
    const product = props.product;

    function readImages() {
        images = [];
        productItem && productItem.fields['images'] && productItem.fields['images'].forEach((el) => {
            if( !images.includes(el.thumbnails.large.url)){
                images.push(el.thumbnails.large.url);
            }
        });
        return images;
    }

    function loadStoreProduct() {
        readImages();
        if(productItem){
            item.name = productItem.fields['en'];
            item.product = productItem;
        }

        //if only one size
        if(props.cups && productItem && productItem.fields.cups.length === 1){
            let sizeRecordsFiltered;
            sizeRecordsFiltered = props.cups.filter(record => {
                return record.id === productItem.fields.cups[0];
            })
            item.size = sizeRecordsFiltered[0];

            let sizePrice = productItem.fields[`price-${sizeRecordsFiltered[0].fields['cup-syntax-tag']}`];
            item['cup-price'] = sizePrice;
            // item['total-price'] = sizePrice;
            item['total-price'] = sizePrice + item['extra-price'] + item['customization-price'];
            item['total-price'] = Number.parseFloat(item['total-price']).toFixed(2);
            item['total-price'] = Number(item['total-price']);
        }
    };
    props.productItem&& props.products && loadStoreProduct();

    React.useLayoutEffect(() =>{
        // setSugar([]);
        // setTemperature([]);
    })

    React.useEffect(() => {

        let sugarMemory = [];
        let temperatureMemory = [];

        // let productItemMemory;

        //read sugar
        if(props.rootContent && productItem && productItem.fields['sugar-must-select']){
            allSugar?.map((el) => {
                if(productItem.fields[`sugar-${el.value}`]){
                    sugarMemory.push(
                        {
                            label:  props.rootContent[el.label] && props.rootContent[el.label][`${language}`],
                            value: el.value
                        }
                    )
                }

            })
        }

        //read temperature
        if(props.rootContent && productItem && productItem.fields['temperature-must-select']){
            allTemperatures?.map((el) => {
                if(
                    productItem.fields[`cold-noIce`] ||
                    productItem.fields[`cold-lessIce`] ||
                    productItem.fields[`cold-normalIce`] ||
                    productItem.fields[`cold-moreIce`] ||
                    productItem.fields[`hot`]
                ){
                    temperatureMemory.push(
                        {
                            label: props.rootContent[el.label] && props.rootContent[el.label][`${language}`],
                            value: el.value,
                            icon: props.rootContent[el.label] && props.rootContent[el.label]['attachments'][0].url,
                            icon_reverse: props.rootContent[el.label] && props.rootContent[`${el.label}-reverse`]['attachments'][0].url
                        }
                    )
                }
            })
        }

        setSugar(sugarMemory);
        setTemperature(temperatureMemory);
        
        
        let mounted = true;
        if(mounted && !rendered) {
            rendered = true;
            loadStoreProduct();
        }
        return () => {
            mounted = false;
        }
    },[]);

    function handleUpdateSugar(input){
        itemMemory.sugar = input;
        itemMemory['sugar-name'] = props.rootContent[`${itemMemory.sugar}-sugar`].en;

        setItem(prevState => ({
            ...prevState,
            'sugar': itemMemory.sugar,
            'sugar-name': itemMemory['sugar-name']
        }))
    }

    function handleUpdateTemperature(input){
        itemMemory.temperature = input;
        itemMemory['temperature-name'] = props.rootContent[`${itemMemory.temperature}`].en;

        setItem(prevState => ({
            ...prevState,
            'temperature': itemMemory.temperature,
            'temperature-name': itemMemory['temperature-name']
        }))
    }

    function selectCustomization(record, action) {
        let selectedRecord = record;
        let selectedAction = action;

        itemMemory['customization-price'] =  Number(selectedRecord.fields.price);
        if(selectedAction === 'plus') {
            itemMemory['customization'] = selectedRecord.fields;
            itemMemory['total-price'] = item['cup-price'] + item['extra-price'] + itemMemory['customization-price']
        } else if (selectedAction === 'minus') {
            itemMemory['customization'] = '';
            itemMemory['total-price'] = item['cup-price'] + item['extra-price'] - itemMemory['customization-price']
        }
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        setItem(prevState => ({
            ...prevState,
            'customization-price': itemMemory['customization-price'],
            'customization': itemMemory['customization'],
            'total-price': Number(itemMemory['total-price']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
    }

    function selectDiscount(record, action){

        if(action === 'plus') {
            if(record.fields['direct-discount']){
                itemMemory['discount-type'] =  'direct';
                itemMemory['discount-value'] =  Number(record.fields['direct-discount']);
            } else if (record.fields['percentage-off-discount']) {
                itemMemory['discount-type'] =  'percentage';
                itemMemory['discount-value'] =  Number(record.fields['percentage-off-discount']);
            }
            itemMemory['discount'] =  record.fields;
        } else if (action === 'minus') {
            itemMemory['discount-type'] = '';
            itemMemory['discount-value'] = 0;
            itemMemory['discounted-price'] = 0;
            itemMemory['discounted-total-price'] = 0;
            itemMemory['discount'] = null;
        }
        
        //validate discounted price
        if(itemMemory['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(itemMemory['discount-value']);
            itemMemory['discounted-total-price'] = Number(item['total-price']) + itemMemory['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (itemMemory['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(item['total-price']) * Number(itemMemory['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(item['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        setItem(prevState => ({
            ...prevState,
            'discount-type': itemMemory['discount-type'],
            'discount-value': Number(itemMemory['discount-value']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price']),
            'discount': itemMemory['discount']
        }))

    }

    function handleSelectSize(sizeRecord){

        itemMemory.size = sizeRecord;
        itemMemory['cup-price'] = productItem.fields[`price-${sizeRecord.fields['cup-syntax-tag']}`];
        itemMemory['total-price'] = itemMemory['cup-price'] + item['extra-price'] + item['customization-price'];
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);

        setItem(prevState => ({
            ...prevState,
            'size': itemMemory.size,
            'cup-price': itemMemory['cup-price'],
            'total-price': Number(itemMemory['total-price']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
    }

    function handleUpdateExtra(action, record){

        let actionMemory = action;
        let recordMemory = record;

        let currToppings = [...item.toppings];
        let currToppingIds = [...item.toppingIds];

        if(actionMemory === 'minus') {
            const removeIndex = currToppingIds.indexOf(recordMemory.id);
            currToppings.splice(removeIndex, 1);
            currToppingIds.splice(removeIndex, 1);
            itemMemory['extra-price'] = item['extra-price'] - recordMemory.fields.price;
        } else if (actionMemory === 'plus') {
            currToppingIds.push(recordMemory.id);
            currToppings.push(recordMemory);
            itemMemory['extra-price'] = item['extra-price'] + recordMemory.fields.price;
        }
        itemMemory['total-price'] = item['cup-price'] + itemMemory['extra-price'] + item['customization-price'];
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else {
            itemMemory['discounted-price'] = Number(item['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(item['discounted-total-price']).toFixed(2);
        }
        let price = Number(itemMemory['total-price']);
        // item['total-price'] = price;
        setItem(prevState => ({
            ...prevState,
            'toppings': currToppings,
            'toppingIds': currToppingIds,
            'extra-price': itemMemory['extra-price'],
            // 'total-price': Number(itemMemory['total-price']),
            'total-price': price,
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
        // item['total-price'] = price;
    }
    
    function handleAddToCart() {
        if(item.size === null){
            //enter sugar dialog
            setAlert(true);
            setAlertMessage(`${props.rootContent['cart-alert-size'][`${language}`]}`)

        } else if(productItem && productItem.fields && productItem.fields['sugar-must-select'] && item['sugar-name'] === 'NaN'){
            //enter temperature dialog
            setAlert(true);
            setAlertMessage(`${props.rootContent['cart-alert-sugar'][`${language}`]}`)

        } else if(productItem && productItem.fields && productItem.fields['temperature-must-select'] && item['temperature-name'] === 'NaN'){
            //enter size dialog
            setAlert(true);
            setAlertMessage(`${props.rootContent['cart-alert-temperature'][`${language}`]}`)

        } else {
            props.onUpdateCart(item);
            props.onClose();
        }
    }

    return (
        <Box>

            {   props.rootContent && props.products ?
                <Container id='container'
                    spacing={2}
                >
                    <Div>
                        <Button onClick={() => handleClose()}>
                            Close
                        </Button>
                    </Div>
                    <Typography variant="h3" component="h2">
                        {   productItem &&
                            productItem.fields[`${language}`]
                        }
                    </Typography>
                    <Div>
                        <Typography>
                            {   productItem &&
                                productItem.fields[`${language}-note`]
                            }
                        </Typography>
                    </Div>
                    {   productItem && props.rootContent &&
                        <Size content={props.rootContent} preSelect={ productItem.fields.cups.length === 1 ? true : false} sizeRecords={props.cups && props.cups} store={store} language={language} product={productItem} onSelectSize={(input) => handleSelectSize(input)}/>
                    }
                    {/* sugar */}
                    {   productItem && props.rootContent && productItem.fields && productItem.fields['sugar-must-select'] && language && props.rootContent['helper-default-sugar'] && sugar &&
                        <ButtonsSectionBox
                            spacing={1}
                        >
                            <Typography variant="body2_bold">
                                    {props.rootContent.sugar[`${language}`]}
                            </Typography>
                            <ButtonsGroupBox>
                                <SimpleButtonsGroup 
                                    content={props.rootContent} 
                                    language={language} 
                                    product={productItem.fields} 
                                    helperText={props.rootContent['helper-default-sugar'][`${language}`]} 
                                    buttons={sugar}
                                    onClick={(input) => handleUpdateSugar(input)} 
                                />
                            </ButtonsGroupBox>
                        </ButtonsSectionBox>
                    }
                    {/* ice */}
                    {   productItem && props.rootContent && productItem.fields && props.rootContent.temperature && productItem.fields && productItem.fields['temperature-must-select'] && language && props.rootContent['helper-default-temperature'] && temperature &&
                        <ButtonsSectionBox
                            spacing={1}
                        >
                            <Typography variant="body2_bold">
                                    {props.rootContent.temperature[`${language}`]}
                            </Typography>
                            <ButtonsGroupBox>
                                <IconButtonsGroup
                                    content={props.rootContent} 
                                    language={language} 
                                    product={productItem.fields} 
                                    helperText={props.rootContent['helper-default-temperature'][`${language}`]} 
                                    buttons={temperature}
                                    onClick={(input) => handleUpdateTemperature(input)} 
                                />
                            </ButtonsGroupBox>
                        </ButtonsSectionBox>
                    }
                    {/* customization */}
                    {   productItem && props.rootContent && props.customization && productItem.fields['extra-customization'] &&
                        <CustomizationSection
                            content={props.rootContent}
                            store={store} 
                            language={language} 
                            product={productItem}
                            customizationRecords={props.customization}
                            onClick={(record, action) => selectCustomization(record, action)} 
                        /> 
                    }
                    {/* toppings */}
                    {   productItem && props.rootContent !== {} && productItem.fields['activate-toppings'] && props.toppings && language && store && props.dialogs &&
                        <ExtraSection 
                            dialogsData={props.dialogs}
                            content={props.rootContent} 
                            store={store} 
                            language={language} 
                            product={productItem}
                            allRecords={props.toppings}
                            // currRecords={item.toppings}
                            onUpdateExtra={(action, record) => handleUpdateExtra(action, record)}
                        />
                    }
                    {/* discount */}
                    {   productItem && props.rootContent !== {} && productItem.fields && props.discounts &&
                        <DiscountSection
                            cashier
                            content={props.rootContent}
                            store={store} 
                            language={language} 
                            product={productItem}
                            records={props.discounts}
                            onClick={(record, action) => selectDiscount(record, action)} 
                        /> 
                    }
                    {   props.rootContent && language && productItem && props.settings &&
                        <Button fullWidth={true} variant="contained" size='large' onClick={() => handleAddToCart()}>
                            <Typography>
                                {   props.rootContent['CTA-add'] &&
                                    props.rootContent['CTA-add'][`${language}`]
                                }
                            </Typography>
                            <Typography> </Typography>
                            <Typography>
                                {   props.rootContent['CTA-add'] &&
                                    props.rootContent['CTA-to-cart'][`${language}`]
                                }
                            </Typography>
                        </Button>
                    }
                    {   alert && alertMessage &&
                        <Alert severity="info">{`${alertMessage}`}</Alert>
                    }
                    {/* <WindowScrollButtons showBelow={0} /> */}
                </Container>
                :
                <LoadingBox>
                    <Loading />
                </LoadingBox>
            }
        </Box>
    )
}

export default CashierProductPage;