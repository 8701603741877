import * as React from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import {useHistory} from 'react-router-dom';

const Sc_Box = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 8px;
    justify-content: flex-start;
    min-height: 100%;
    @media ${device.laptop}{
        flex-direction: row;
    }
`;

const Sc_CardMedia = styled(CardMedia)`
    border-radius: 10px;
    height: 120px;
    width: 100%;
    @media ${device.laptop}{
        max-width: 120px;
    }
`;

const Sc_CardContent =styled(CardContent)`
    margin: 0px 8px;
`;

const SC_Card = styled(Card)`

    border-radius: 10px;
    margin: 4px 0;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const LabelBox = styled(Box)`

    text-align: left;

`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    color: white;
`;

function ProductItem(props) {

    const item = props.item.fields;
    const history = useHistory();

    const handleSelectItem = React.useCallback((input, languageInput) => { 

        {   props.cashier ?
            props.onCashierCallItem(input)
            :
            history.push({
                pathname: `/product/${props.store}/${languageInput}/${input}`,
                data: {
                    item: props.item
                }
            })
        }
    
    },[history]); 

    function renderCard(){
        return (
            <Sc_Box>
                {   item['images'] && !props.simpleLayout &&
                    <Sc_CardMedia
                    component="img"
                    image={item['images'][0].thumbnails.large.url}
                    alt="item image"
                    />
                }
                <Sc_CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {
                            item[`${props.language}`]
                        }
                    </Typography>
                    {   item['cup-syntax-tag'] &&
                        item['cup-syntax-tag']?.map((el, index) => {
                            return(
                                <LabelBox key={index}>
                                    {   item[`size-${el}`] && item[`price-${el}`] &&
                                        <Typography variant="body2" color="text.secondary">
                                            {item[`cups-${props.language}`][index]} {item[`price-${el}`]} €
                                        </Typography>
                                    }
                                </LabelBox>
                            )
                        })
                    }
                </Sc_CardContent>
            </Sc_Box>
        )
    }

    return (
        <SC_Card 
            raised={true}
            sx={{ 
                maxWidth: 1440, 
                display: 'flex', 
                flexDirection: 'row', 
                flexGrow: 1,
                borderRadius: '10px' 
            }}
        >
            {
                item && !item['sold-out'] ?
                <CardActionArea onClick={() => {handleSelectItem(props.item.id, props.language)}}>
                    {renderCard()}
                </CardActionArea>
                :
                <Box sx={{ alignItems: 'flex-start', display: 'flex', position: 'relative', width: '100%', height: '100%' }} >
                    {renderCard()}
                    <OverlayBox>
                        <TextBox>
                            <Typography variant="body1" color="text.secondary" sx={{ color: 'white' }}>
                                {   props.rootContent['sold-out'] &&
                                    props.rootContent['sold-out'][`${props.language}`]
                                }
                            </Typography>
                        </TextBox>
                    </OverlayBox>
                </Box>
            }
        </SC_Card>
    );
}

export default ProductItem;