import React, { useCallback, useEffect, useState }  from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router'
import { getThemeProps } from '@mui/system';
import textLogo from '../textLogo_v1.svg';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { theme } from './theme_light'
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const LanguageButtons = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 200px;
    margin: 0 auto;
`;

const TextContainer = styled.div`
    align-items: center;
    display:flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
`;

const Image = styled.img`
    ${'' /* width: 48px; */}
    height: 32px;
`;

const StyledDivider = styled(Divider)`
    margin: 0 0 16px 0;
`;

// const pages = ['Contact', 'About'];
// const settings = [ 'Deutsch', '中文', 'English'];

const content = {}

function Footer(props) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    };

    const [url_footer, setUrl_footer] = useState('/root/footer');

    const {mapTypeId, language} = useParams();

    const history = useHistory();

    const [isFetching, setIsFetching] = useState(false);
    const fetchData_footer = useCallback(() => {

        fetch(url_footer)
            .then(response => {
            if (!response.ok) {
                throw new Error(`status ${response.status}`);
            }
            return response.json();
            })
            .then(json => {
            json?.map(el => {

                const contentItem = {
                    'en': String,
                    'ch': String,
                    'de': String
                }
    
                contentItem.en = el.fields.en;
                contentItem.ch = el.fields.ch;
                contentItem.de = el.fields.de;
    
                content[el.fields.name] = contentItem;
            });
            setIsFetching(false);
            }).catch(e => {
            console.log('root content call failed')
            setIsFetching(false);
            })
        }, [url_footer]);

        const handleLanguageSwitch = React.useCallback((mapTypeIdInput, languageInput) => {

                history.push(`/map/${mapTypeIdInput}/${languageInput}`)
            
            setAnchorElUser(null);

        },[history]); 

    useEffect(() => {

        let mounted = true;
        if(mounted) {
            setIsFetching(true);
            fetchData_footer();
        }
        return () => {
            mounted = false;
        }
    }, [fetchData_footer]);

    return(
        <Container>
            <ThemeProvider theme={theme}>
                <Stack spacing={2} sx={{ flexGrow: 1 , mt: 1}} >
                    <Container maxWidth="xl" >
                        <Toolbar disableGutters sx={{ display:'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start' }}>
                            <Box sx={{ display: { xs: 'block', md: 'flex' }, mr: 4 }} >
                                <IconButton
                                    href='/map'
                                >
                                    <Image src={textLogo} />
                                </IconButton>
                            </Box>
                            {/* <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'flex' }, flexDirection: 'row-reverse' }} >
                                {pages.map((page) => (
                                    <Button
                                    key={page}
                                    // onClick={handleCloseNavMenu}
                                    sx={{ my: 1, display: 'block' }}
                                    color='secondary'
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box> */}
                        </Toolbar>
                    </Container>
                </Stack>

                <StyledDivider/>

                <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mb: 2 }} >
                    <Box sx={{ display:'flex', flexDirection: 'column' , alignItems: 'flex-start', flexGrow: 1, mb: { xs: 1, md: 0 } }} >
                        {/* <TextContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flexGrow: 1, ml: 0, mr: 'auto' }} > */}
                            <Typography sx={{ ml: 0, mr: 'auto'}} variant='subtitle2' color='secondary' >
                                { content['copywright'] &&  content['copywright'].en}
                            </Typography>
                            <Typography sx={{ ml: 0, mr: 'auto'}} variant='subtitle2' color='secondary' >
                                { content['credit'] &&  content['credit'].en}
                            </Typography>
                        {/* </TextContainer> */}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                                <Stack direction="row" spacing={1}>
                                    <Typography>
                                        Language: 
                                    </Typography>
                                    {   content['language-button'] &&
                                        <Chip 
                                            avatar= {<Avatar>{`${content['language-button'][`${language}`].charAt(0)}`}</Avatar>}
                                            label={`${content['language-button'][`${language}`]}`}
                                        />
                                    }
                                    {/* <Chip
                                        avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                                        label="Avatar"
                                        variant="outlined"
                                    /> */}
                                </Stack>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '8px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                                <LanguageButtons>
                                    <Button onClick={() => {handleLanguageSwitch(mapTypeId, 'en')}}>{ content['language-button'] &&  content['language-button'].en}</Button>
                                    <Button onClick={() => {handleLanguageSwitch(mapTypeId, 'ch')}}>{ content['language-button'] &&  content['language-button'].ch}</Button>
                                    <Button onClick={() => {handleLanguageSwitch(mapTypeId, 'de')}}>{ content['language-button'] &&  content['language-button'].de}</Button>
                                </LanguageButtons>
                        </Menu>
                    </Box>
                </Container>
            </ThemeProvider>
        </Container>
    );
}

export default Footer;