import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import Sections from './parts/Sections';
import Container from '@mui/material/Container';
import useFetch from './useFetch';
import SiteNavigation from './SiteNavigation';
import { styled } from '@mui/material/styles';

const ScContainer = styled(Container)(({theme}) => ({
    maxWidth: 'unset !important',
    paddingLeft: 'unset !important',
    paddingRight: 'unset !important'
}));

export default function Page (){

    const {page, store, language} = useParams();

    const [url_store_pageBuilder, setUrl_store_pageBuilder] = useState(`/store/page-builder/${store}`);

    const { data: data_store_pageBuilder, isPending: isPending_store_pageBuilder, error: error_store_pageBuilder } = useFetch(url_store_pageBuilder);

    const [storePageBuilder, setStorePageBuilder] = useState(data_store_pageBuilder);

    const [pages, setPages] = useState([]);

    useEffect(() => {

        let pagesMemory = [];

        setPages([])

        data_store_pageBuilder && setStorePageBuilder(
            data_store_pageBuilder.filter( record => {

                let pageObj = {
                    name: String,
                    url: String
                }

                if(!pagesMemory.includes(record.fields.page)){
                    
                    pageObj.name = record.fields.page;
                    pageObj.url = record.fields.url;

                    setPages(pages => [...pages, pageObj])
                    pagesMemory.push(pageObj.name)
                }

                return record.fields.url[0] === page && record.fields.sections;
            })
        )

    }, [
        data_store_pageBuilder
    ])

    return(

        <ScContainer>
            <SiteNavigation port='client' />
            {   data_store_pageBuilder &&
                storePageBuilder &&
                <Sections 
                    pages={pages}
                    storePageBuilder={storePageBuilder}
                />
            }
        </ScContainer>

    )

}