import React from 'react';

export default function PageNotFound (){

    return(

        <div/>

    )

}