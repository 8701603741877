import React from 'react';
import styled from 'styled-components';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ProductDial from './ProductDial';
import { Stack } from '@mui/system';

const ScContainer = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const ScBox = styled(Box)`
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
`;

const ScMiniBox = styled(Box)`
    display: flex;
`;

const ImageBox = styled(Box)`
    width: 100%;
`;


const ScDialBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-grow: 0;
    justify-content: flex-end;
`;


function CartItem(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    function renderSugar(){
        let sugarRecord = props.rootContent.filter((el) => {
            return el.fields['name'] === `${props.item.sugar}-sugar`;
        })
        return sugarRecord[0] && sugarRecord[0].fields[`${props.language}`];

    }

    function renderIce(){
        let iceRecord = props.rootContent.filter((el) => {
            return el.fields['name'] === `${props.item.temperature}`;
        })
        return iceRecord[0] && iceRecord[0].fields[`${props.language}`];
    }

    function handleChangeQuantity(action, inputItem) {
        props.passHandleChangeQuantity(action, inputItem);
    }
    
    return (
        <Stack
            direction={{
                md: 'row'
            }}
            sx={{
                alignItems: 'center',
                border: `2px solid ${theme.palette.neutral.main}`,
                padding: {
                    xs: '8px 8px',
                    md: '16px 16px',
                },
                borderRadius: '10px',
                mb: 2,
            }}
            spacing={1}
        >
            <Stack
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                sx={{
                    width: '100%',
                }}
                spacing={2}
            >
                <Box>
                    <ImageBox>
                        {   props.item && props.item.product && props.item.product.fields.images &&
                            <CardMedia
                                component="img"
                                sx={{ 
                                    borderRadius: '10px',
                                    height:140, 
                                    objectFit: 'cover',
                                    width: md ? 140 : '100%', 
                                }}
                                image={`${props.item.product.fields.images[0].thumbnails.large.url}`}
                                alt="item image"
                            />
                        }
                    </ImageBox>
                </Box>
                <Stack>
                    {/* title */}
                    <Typography variant="h5" component="div">
                        {   props.item && props.item.product && 
                            props.item.product.fields[`${props.language}`]
                        }
                    </Typography>
                    {/* size */}
                    <Typography variant="body2" color="text.secondary">
                        {   
                            props.item && props.rootContent && props.language && props.item.size &&
                            props.item.size.fields[`${props.language}`]
                        }
                    </Typography>
                    {/* ice and sugar */}
                    {   props.item && props.rootContent && props.language && props.item['sugar'] !== 'NaN' && props.item['sugar-name'] !== 'NaN' && props.item['temperature'] !== 'NaN' && props.item['temperature-name'] !== 'NaN' ?
                        <Typography variant="body2" color="text.secondary">
                            {renderSugar()} / {renderIce()}
                        </Typography>
                        :
                        props.item && props.rootContent && props.language && props.item['sugar'] !== 'NaN' && props.item['sugar-name'] !== 'NaN' ?
                        <Typography variant="body2" color="text.secondary">
                            {renderSugar()}
                        </Typography>
                        :
                        props.item && props.rootContent && props.language && props.item['temperature'] !== 'NaN' && props.item['temperature-name'] !== 'NaN' &&
                        <Typography variant="body2" color="text.secondary">
                            {renderIce()}
                        </Typography>

                    }
                    {/* toppings */}
                    <Typography variant="body2" color="text.secondary">
                        {   props.item && props.language && props.item.toppings && props.item.toppings!==[] &&
                            props.item.toppings?.map((el) => {
                                return el.fields[`${props.language}`] + ' / '
                            })
                        }
                    </Typography>
                    {/* customization */}
                    <Typography variant="body2" color="text.secondary">
                        {   props.item && props.language && props.item.customization && props.item.customization!=={} &&
                            props.item.customization[`${props.language}`]
                        }
                    </Typography>
                    {/* price */}
                    <Typography variant="body2" color="text.secondary">
                        {   props.item && props.language && props.item['total-price'] && props.item['discount-type'] === '' ?
                            props.item['total-price'] + ' €'
                            :
                            props.item && props.language && props.item['total-price'] &&
                            <>
                                <s>
                                    {props.item['total-price']} €
                                </s>
                                &nbsp;
                                {props.item['discounted-total-price']} €
                            </>
                        }

                    </Typography>
                </Stack>
            </Stack>
            {   props.item &&
                <Box
                    sx={{
                        width: {
                            xs: '100%',
                            md: 'unset',
                        }
                    }}
                >
                    <ProductDial item={props.item} onChange={(action, inputItem) => handleChangeQuantity(action, inputItem)} />
                </Box>
            }
        </Stack>
    )
}

export default CartItem;