import { styled } from '@mui/material/styles';
import { Stack, Button, Typography, ButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  padding: '8px 0px',
}));

const Sc_ButtonGroup = styled(ButtonGroup)(({theme}) => ({
  display: 'flex',
  margin: 8
}));

const NumberDisplay = styled(Box)(({theme}) => ({
  padding: '0 24px',
}));

export default function NumberDial(props) {
  const theme = useTheme();

  const [value, setValue] = React.useState(props.initialValue);

  function handleChange(action){

    let selectedAction = action;

    let valueMemory = value;
    if(selectedAction === 'minus'){
        if(valueMemory > 1) {
            valueMemory = valueMemory - 1;
            props.onChange('minus');
        } else if(valueMemory === 1){
            valueMemory = 0;
            props.onChange('minus');
        } else {

        }
        setValue(valueMemory);
    } else if (selectedAction === 'plus') {
        if( Number(props.limit) >= 1) {
            valueMemory = valueMemory + 1;
            setValue(valueMemory);
            props.onChange('plus');
        } else {
            let alertMessage = 'exceed limit';
            props.onAlert(alertMessage);
        } 
    }
  }

  React.useEffect(() => {
    let mounted = true;
    if(mounted) {
      // readDialogs();
    }
    return () => {
        mounted = false;
    }
}, [value]);

  return (
      <Container
        sx={{
          minWidth: {
            xs: '100%',
            sm: 'unset'
          }
        }}
      >
        <Sc_ButtonGroup
          variant="contained"
          fullWidth={true}
        >
          <Button disabled={value === 0 ? true : false} variant="contained" onClick={() => handleChange('minus')}
            sx={{
              height: '48px',
              width: '48px',
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <NumberDisplay 
            active={value > 0 ? 'true' : 'false'} 
            label={value && value} 
            variant="contained"
            sx={{
              alignItems: 'center',
              border: `2px solid ${theme.palette.primary.main} !important`,
              backgroundColor: theme.palette.background.paper,
              color: `${value > 0 ? theme.palette.primary.main : 'unset' }`,
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            {value && value}
          </NumberDisplay>
          <Button disabled={value === 3 ? true : false} variant="contained" onClick={() => handleChange('plus')}
            sx={{
              height: '48px',
              width: '48px',
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Sc_ButtonGroup>
      </Container>
  );
}