import React,{useState, useEffect} from 'react';
import { useParams } from 'react-router'
import {useHistory} from 'react-router-dom';
import Loading from './Loading';
import useFetch from './useFetch';
import styled from 'styled-components';
import Box from '@mui/material/Box';

// let rendered = false;

const PageBox = styled(Box)`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
`;

export default function StripeRedirect (){

    const history = useHistory();

    const {store, language, stripeKey} = useParams();

    const [url_stripeBacklog, setUrl_stripeBacklog] = useState(`/store/stripe-backlog/${store}`);
    const { data: data_stripeBacklog, isPending: data_stripeBacklog_isPending, error: data_stripeBacklog_error } = useFetch(url_stripeBacklog);

    useEffect(() => {
        // if(!rendered) {

        data_stripeBacklog && setTimeout(
            function(){
                const filterdBacklog = data_stripeBacklog.filter((item) => {
                    return item.fields['stripe-key'] === stripeKey;
                })
                let itemKey = filterdBacklog[0].fields['stripe-key'];
                let currId = filterdBacklog[0].fields.id;
                // check if webhook finished
                if(itemKey === stripeKey && currId !== stripeKey){
                    // setTimeout(
                        // function() {
                            history.push(`/${store}/${language}/invoice/${currId}`);
                        // }.bind(this), 2000)
                }
                window.location.reload(false)
            }.bind(this),
            3000
        )

            // rendered = true;
        // }
        // return () => {
        // }
    }, [data_stripeBacklog]);

    return(
        <PageBox>
            <Loading/>
        </PageBox>

    )

}