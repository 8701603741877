import React from 'react';
import styled from 'styled-components';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import SimpleCardButtonGroup from "./SimpleCardButtonGroup";

const Container = styled.div`

`;

const ScStack = styled(Stack)`
    min-width: 100%;
`;

const SC_Card = styled(Card)`

    margin: 4px 0;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const Item = styled(Paper)`

`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    text-color: white;
`;

function CustomizationSection({content, customizationRecords, store, language, product, onClick}) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [filteredCustomizationRecords, setFilteredCustomizationRecords] = React.useState()

    const [options, setOptions] = React.useState(product.fields['extra-customization-name']);
    const [labels, setLabels] = React.useState(product.fields[`extra-customization-name`]);
    const [images, setImages] = React.useState(product.fields[`extra-customization-images`]);
    const [prices, setPrices] = React.useState(product.fields[`extra-customization-price`]);
    const [soldoutBooleans, setSoldoutBooleans] = React.useState(product.fields[`extra-customization-soldout`]);

    const [itemSelected, setItemSelected] = React.useState();

    const handleClick = React.useCallback((record, action) => {

        let selectedRecord = record;
        let selectedAction = action

        onClick(selectedRecord, selectedAction);
    }); 

    React.useEffect(() => {
        customizationRecords && setFilteredCustomizationRecords(() =>{
            return customizationRecords.filter((el) => {
                return product.fields['extra-customization'].includes(el.id)
            })
        })
        let mounted = true;
        if(mounted) {

        }
        return () => {
            mounted = false;
        }
    }, []);


    return (
        <ScStack
            spacing={2}
        >
            {   content.customization &&
                <Typography variant="body2_bold">
                    {content['customization'] && content['customization'][`${language}`] }
                </Typography>
            }
            {   filteredCustomizationRecords && content && language &&
                <SimpleCardButtonGroup
                    content={content}
                    records={filteredCustomizationRecords}
                    language={language}
                    onClick={(record, action) => handleClick(record, action)} 
                />
            }
        </ScStack>
    );
}

export default CustomizationSection;