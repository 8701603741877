import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { LightBox } from 'react-lightbox-pack';
import 'react-lightbox-pack/dist/index.css';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Portal } from "react-portal";

const Container = styled(Box)`
  width: 100%;
  ${'' /* height: 50px; */}
  margin: 0;
`;

const StyledBox = styled(Box)`
  position: relative;
  z-index: 999;
`;


export default function MasonryImageList(props) {

  const LightboxContainer = () => (
    <StyledBox>
      <LightBox
          state={toggle}
          event={lightBoxHandler}
          data={images}
          imageWidth="90vw"
          imageHeight="90vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
        />
    </StyledBox>
  );

  const [toggle, setToggle] = React.useState(false);
	const [sIndex, setSIndex] = React.useState(0);
  let imageIndex = 0
  let images = [];

  const readImages = (el) => {

    const image = {
      id: imageIndex,
      image: el.thumbnails.large.url,
      title: 'image',
      description: 'description'
    }

    imageIndex+=1;

    images.push(image);
  }

  const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
    //for lightbox styling on safari due to drawer issue
    props.onLightboxHandler(state);
	};

  return (
    <Container>
      {toggle && <LightboxContainer />}
      <Box sx={{ width: '100%', height: '100%' }} >
        <ImageList variant="masonry" cols={3} gap={4} sx={{ marginTop: '4px', marginBottom: '4px' }}>
          {props.thumbnails?.map((el, index) => {
            
            {readImages(el)}

            return (
              <ImageListItem key={el.id} >
                <img
                // ?w=200&fit=crop&auto=format
                  src={`${el.thumbnails.large.url}`}
                  // ?w=200&fit=crop&auto=format&dpr=2 2x
                  srcSet={`${el.thumbnails.large.url}`}
                  alt='image'
                  loading="lazy"
                  onClick={() => {lightBoxHandler(true, index)}}
                />
              </ImageListItem>
            )
          })}
        </ImageList>
      </Box>
    </Container>
  );
}