import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/system';
import { device } from '../device';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStar, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const generateSlide = (diff_count, curr_count) => {
    const slide = keyframes`
        from {
            transform: translateX(${curr_count * 80 * -1}px);
        }
        to {
            transform: translateX(${diff_count * 80 * -1}px);
        }
    `;
    return slide;
}

const generateFade = () => {
    const fade = keyframes`
        0% {
            transform: translateY(0px);
            opacity: 1;
        }
        50% {
            transform: translateY(20px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    `;
    return fade;
}

const Section = styled(Box)(({theme}) => `
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding: 32px 0px;
    flex-direction: column;
    gap: 32px;
    margin: 32px 0px;
`);

const Header = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0px 16px;
    @media ${device.tablet} {
        align-self: stretch;
        align-items: flex-start;
        text-align: center;
    }
    @media ${device.laptop} {
        align-self: stretch;
        align-items: center;
        text-align: center;
    }
`);
const TitleBox = styled(Box)(({theme}) => `
    text-align: left;
    @media ${device.laptop} {
        text-align: center;
    }
`);

const DescriptionBox = styled(Box)(({theme}) => `
    text-align: left;
    @media ${device.laptop} {
        max-width: 560px;
        text-align: center;
    }
`);
const ContentBox = styled(Box)(({theme}) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;
`);
const AvatarBox = styled(Box)(({theme, diff_count, curr_count, avatar_count}) => ({
    alignItems: "flex-start",
    animation: `${generateSlide(diff_count, curr_count)} ${ Math.abs(diff_count) / avatar_count}s ease-in-out forwards`,
    display: "flex",
    gap: "16px",
    justifyContent: "center",
    overflow: "hidden",
}));
const AvatarButton = styled(Button)(({theme}) => `
    border-radius: 50%;
    min-width: unset;
    padding: unset;
`);
const ArrowBox = styled(Box)(({theme}) => `
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`);
const ScFontAwesomeIcon = styled(FontAwesomeIcon)(({theme, input_color}) => `
    color: ${input_color ? input_color.hex : theme.palette.black.main};
    transform: rotate(90deg);
`);
const TestimonialBox = styled(Box)(({theme}) => `
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    padding: 0px 16px;
    gap: 16px;
    justify-content: center;
`);
const CardBox = styled(Box)(({theme}) => `
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
`);
const Card = styled(Box)(({theme, active, diff_count, avatar_count}) => ({
    alignSelf: "stretch",
    alignItems: "flex-start",
    animation: `${generateFade()} ${ Math.abs(diff_count) / avatar_count}s ease-in-out forwards`,
    borderRadius: "32px 32px 32px 0px",
    background: `${theme.palette.black.main}`,
    display: `${active === "true" ? "flex" : "none"}`,
    flexDirection: "column",
    gap: "16px",
    width: "min(100vw - 32px, 432px)"
}));
const CardHead = styled(Box)(({theme}) => `
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`);
const DialogIconBox = styled(Box)(({theme, input_color}) => `
    background-color: ${input_color ? input_color.hex : theme.palette.black.main};
    border-radius: 50%;
    display: flex;
    width: 42px;
    height: 42px;
    padding: 0px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
`);
const CardBody = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`);
const CardMetrics = styled(Box)(({theme}) => `
    display: flex;
    padding: 0px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`);
const CardPerson = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`);
const CardPersonName = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-self: stretch;
`);
const CardPersonType = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-self: stretch;
`);
const CardStarsBox = styled(Box)(({theme}) => `
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`);
const FontAwesomeIconStar = styled(FontAwesomeIcon)(({theme, input_color}) => `
    color: ${input_color ? input_color.hex : theme.palette.black.main};
    padding: 6px 2px;
`);

export default function TestimonialWithAvatarButtons (props){

    let testimonialBodies = props['text-3'].split('/*/');
    let testimonialPeople = props['text-4'].split('/*/');
    let testimonialPersonTypes = props['text-5'].split('/*/');
    let testimonialImages = props['images-1'];

    const [initialCount, setInitialCount]= useState(() => {
        let initialCountMemory;
        let testimonialCount = testimonialBodies.length;
        if(testimonialCount % 2 === 0){
            initialCountMemory = testimonialCount / 2;
        } else {
            initialCountMemory = Math.ceil(testimonialCount / 2);
        }
        return initialCountMemory;
    });

    const [activeTestimonial, setActiveTestimonial] = useState(initialCount)

    const [prevDiffCount, setPrevDiffCount] = useState(0);
    const [diffCount, setDiffCount] = useState(0);


    function handleClick(inputTestimonialCount){
        setPrevDiffCount(diffCount);
        let diffCountMemory = inputTestimonialCount - initialCount;
        setDiffCount(diffCountMemory);
        setActiveTestimonial(inputTestimonialCount);
    }

    function renderTestimonials(){

        let testimonialObjs = [];
        let stars = ["","","","",""]

        for(let i = 0; i < testimonialBodies.length; i++){
            if(testimonialPeople[i] && testimonialImages[i] && testimonialPersonTypes[i]) {
                let testimonialObj = {
                    body: String,
                    person: String,
                    personType: String,
                    imageUrl: String
                }
                testimonialObj.body = testimonialBodies[i];
                testimonialObj.person = testimonialPeople[i];
                testimonialObj.personType = testimonialPersonTypes[i];
                testimonialObj.imageUrl = testimonialImages[i].thumbnails.large.url;
                testimonialObjs.push(testimonialObj);
            }
        }

        return (
            <ContentBox>
                <AvatarBox 
                    diff_count={diffCount} 
                    curr_count={prevDiffCount}
                    avatar_count={testimonialBodies.length}
                >
                {
                    testimonialObjs?.map((el, index) => {
                        let currCount = index + 1;
                        return (
                            <AvatarButton 
                                key={index} 
                                onClick={() => handleClick(currCount)}
                            >
                                <Avatar 
                                    alt={el.person} 
                                    src={el.imageUrl} 
                                    sx={{ width: 64, height: 64 }}    
                                />
                            </AvatarButton>
                        )
                    })
                }
                </AvatarBox>
                <ArrowBox>
                    <ScFontAwesomeIcon icon={faPlay} size='lg' input_color={props['color-1'] && props['color-1']} />
                </ArrowBox>
                <TestimonialBox>
                    <CardBox>
                        {   testimonialObjs?.map((el, index) => {
                                let testimonialCount = index + 1;
                                return (
                                    <Card 
                                        key={index} 
                                        active={activeTestimonial === testimonialCount ? "true" : "false"}
                                        diff_count={diffCount} 
                                        avatar_count={testimonialBodies.length}
                                    >
                                        <CardHead>
                                            <DialogIconBox input_color={props['color-1'] && props['color-1']}>
                                                <FontAwesomeIcon inverse icon={faQuoteLeft} size='lg' />
                                            </DialogIconBox>
                                        </CardHead>
                                        <CardBody>
                                            <Typography variant='body1'>
                                                {el.body}
                                            </Typography>
                                        </CardBody>
                                        <CardMetrics>
                                            <CardPerson>
                                                <CardPersonName>
                                                    <Typography variant='body1_bold'>
                                                        {el.person}
                                                    </Typography>
                                                </CardPersonName>
                                                <CardPersonType>
                                                    <Typography variant='body2'>
                                                        {el.personType}
                                                    </Typography>
                                                </CardPersonType>
                                            </CardPerson>
                                            <CardStarsBox>
                                                {   stars.map((el, index) => {
                                                        return (
                                                            <FontAwesomeIconStar key={index} icon={faStar} size='sm' input_color={props['color-1'] && props['color-1']} />
                                                        )
                                                    })
                                                }
                                            </CardStarsBox>
                                        </CardMetrics>
                                    </Card>
                                )
                            })
                        }
                    </CardBox>
                </TestimonialBox>
            </ContentBox>
        )
    }

    useEffect(() => {

    },[diffCount, activeTestimonial])

    return(

        <Section>
            <Header>
                {   props['text-1'] &&
                    <TitleBox>
                        <Typography variant='h3'>
                            {props['text-1']}
                        </Typography>
                    </TitleBox>
                }
                {   props['text-2'] &&
                    <DescriptionBox>
                        <Typography variant='body1'>
                            {props['text-2']}
                        </Typography>
                    </DescriptionBox>
                }
            </Header>
            {
                props['text-3'] && props['text-4'] && props['text-5'] &&
                renderTestimonials()
            }
        </Section>

    )

}