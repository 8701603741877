import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

ReactDOM.render(<App />, document.getElementById('root'));
// document.addEventListener('DOMContentLoaded', async () => {

//     const {stripePublishableKey} = await fetch('/config').then((r) => r.json());
//     const stripePromise = loadStripe(stripePublishableKey);

//     ReactDOM.render(
//         <React.StrictMode>
//             <Elements stripe={stripePromise}>
//                 <App />
//             </Elements>
//         </React.StrictMode>
//         , document.getElementById('root')
//     );

// })


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
