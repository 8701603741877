import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { device } from '../device';

const Section = styled(Paper)(({theme}) => `
    padding: 16px;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};

    @media ${theme.breakpoints.up('sm')} {
        padding: 32px;
        background-color: ${theme.palette.secondary.main};
    }

    @media ${theme.breakpoints.up('md')} {
        padding: 64px;
        background-color: ${theme.palette.primary.main};
    }

`);

const Image = styled('img')(({ theme }) => `
    width: 100%;
    max-width: 300px;
    margin-bottom: 16px;

    @media ${theme.breakpoints.up('sm')} {
        max-width: 500px;
        margin-bottom: 32px;
    }
`);

const Example = styled(Box)(({theme}) => `

`);

export default function Template (){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const {page, store, language} = useParams();

    const history = useHistory();

    useEffect(() => {

    })

    return(

        <Section>
            <Typography variant="h4">Welcome to Our Creative Section</Typography>
            <Image
                src="https://via.placeholder.com/500x300"
                alt="Placeholder image"
            />
            <Typography variant="body1">
                This section contains text, images, and buttons with a creative touch, and it's designed to be responsive according to the screen width and theme's palette.
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '16px',
                }}
            >
                <Button variant="contained" color="primary">
                    Get Started
                </Button>
                <Button variant="outlined" color="secondary">
                    Learn More
                </Button>
            </Box>
        </Section>

    )

}