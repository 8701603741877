import React, {useRef} from 'react';
import { Box , Container, Typography, Button, Link } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import axios from 'axios';

const pageStyleLabel = `
    html
    {
        background-color: #FFFFFF; 
        margin: 0mm;  /* this affects the margin on the html before sending to printer */
    }
    body
    {
        padding: 12px auto; /* margin you want for the content */
    }
    @page {
        size: 50mm 35mm;
        margin: 0mm 4mm;
        padding: 0mm 4mm;
    }

    @media all {
        .page-break {
        display: none;
        padding: 0mm auto;
        }
    }

    @media print {
        .page-break {
            page-break-before: always;
            padding: 0px auto;
        }
        html, body {
            height: auto;
            font-size: 8pt;
        }
    }
`;

class LabelToPrintHolder extends React.Component {

    // componentDidMount() {
    //     this.handleTrigger();
    //     console.log('hello')
    // }

    // componentRef = useRef();

    updatePaymentStatus= () => {

        const order = this.props.order;

        if(order[0].fields['payment-completion']){
            return;
        }

        axios
        .post(`/confirmOrder/${this.props.store}`, order)
        .then((res) => {
            if(res.data.status === 'success'){

            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    handleTrigger = () => {
        return (
            <Button>
                {this.props.invoiceContent['print-label'][`${this.props.language}`]}
            </Button>
        )
    }

    render(){
        return(
            <Box>
                {   this.props.order.length !== 0 && this.props.invoiceContent && this.props.invoiceContent['print-label'] &&
                    <Box className=".page-break">
                        <ReactToPrint
                            pageStyle={pageStyleLabel}
                            trigger={() =>{ 
                                if(this.props.port === 'cashier') {
                                    this.updatePaymentStatus();
                                }
                                return this.handleTrigger();
                            }}
                            content={() => this.componentRef}
                        />
                        <div style={{ display: "none" }} className=".page-break">
                            <ComponentToPrint label title={this.props.title} ref={el => (this.componentRef = el)} order={this.props.order} />
                        </div>
                    </Box>
                }
            </Box>
        )
    }

}

export default LabelToPrintHolder;