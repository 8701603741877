import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { device } from '../device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLocationPin, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router';

const Section = styled(Box)(({theme, input_color}) => `
    background-color: ${input_color ? input_color.hex : theme.palette.black.o_eightyseven};
    padding: 32px 16px;
`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    align-self: stretch;
`);

const DividerRec = styled(Box)(({theme}) => `
    background-color: ${theme.palette.grey.darken_3};
    display: flex;
    height: 2px;
    min-width: 100%;
`);

const MainBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 120px;
    align-self: stretch;
    @media ${device.tablet} {
        flex-direction: row;
        gap: 72px;
    }
`);

const CopyrightBox = styled(Box)(({theme}) => `
    display: flex;
    justify-content: start;
    align-items: start;
    align-self: stretch;
`);

const Col1Box = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 120px;
    align-self: stretch;
    @media ${device.laptop} {
        display: flex;
        flex-direction: row;
        gap: 72px;
        align-self: stretch;
        flex: 1 0 0;
    }
`);

const ContactBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`);

const CompanyAndDescriptionBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    flex: 1 0 0;
`);

const LinksBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    @media ${device.tablet} {

    }
`);

const LogoBox = styled(Box)(({theme}) => `

`);

const Image = styled('img')(({theme}) => `
    height: 32px;
`);

const DescriptionBox = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
`);

const SocialMediaBox = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
`);

const LinksTitleBox = styled(Box)(({theme}) => `
    color: ${theme.palette.grey.lighten_1};
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
`);

const ButtonsBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`);

const ScButton = styled(Button)(({theme}) => `

`);


const CopyrightTitleBox = styled(Box)(({theme}) => `
    color: ${theme.palette.grey.lighten_1};
`);

const ContactTitleBox = styled(Box)(({theme}) => `
    color: ${theme.palette.grey.lighten_1};
`);

const IconAndTextBox = styled(Box)(({theme}) => `
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`);

const IconBox = styled(Box)(({theme}) => `
    text-align: center;
`);

const IconDescriptionBox = styled(Box)(({theme}) => `
    color: ${theme.palette.white.main};
`);

export default function SimpleFooter (props){

    const {page, store, language} = useParams();

    const history = useHistory();

    const [year, setYear] = useState(() => {
        const date = new Date;
        return date.getFullYear();
    })
    
    function handleClick(inputUrl){
        if(inputUrl.substring(0,7) === 'http://'){
            inputUrl = 'https://' + inputUrl.substring(7);
        } else if(inputUrl.substring(0,8) !== 'https://'){
            inputUrl = 'https://' + inputUrl;
        }

        window.open(inputUrl, '_blank', 'noopener,noreferrer');
    }

    function handleNavigation(inputPage){
        history.push(`/page/${inputPage}/${store}/${language}`)
        history.go(0);
    }

    return(

        <Section
            input_color={
                props['color-1'] && props['color-1']
            }
        >
            <ContentBox>
                <DividerRec />
                <MainBox>
                    <Col1Box>
                        <CompanyAndDescriptionBox>
                            <LogoBox>
                                {   props['images-1'] &&
                                    <Image alt='image' src={props['images-1'][0].thumbnails.large.url} />
                                }
                            </LogoBox>
                            <DescriptionBox>
                                {   props['text-1'] &&
                                    <Typography variant='body1'>
                                        {props['text-1']}
                                    </Typography>
                                }
                            </DescriptionBox>
                            <SocialMediaBox>
                                {   props['url-1'] && props['url-1'] !== 'x' && props['url-1'] !== 'X' &&
                                    <IconButton
                                        color='secondary'
                                        onClick={() => handleClick(props['url-1'])}
                                    >
                                        <FontAwesomeIcon icon={faInstagram} size='lg' /> 
                                    </IconButton>
                                }
                                {   props['url-2'] && props['url-2'] !== 'x' && props['url-2'] !== 'X' &&
                                    <IconButton
                                        color='secondary'
                                        onClick={() => handleClick(props['url-2'])}
                                    >
                                        <FontAwesomeIcon icon={faFacebook} size='lg' /> 
                                    </IconButton>
                                }
                            </SocialMediaBox>
                        </CompanyAndDescriptionBox>
                        <LinksBox>
                            <LinksTitleBox>
                                {   props['text-2'] &&
                                    <Typography variant='body1'>
                                        {props['text-2']}
                                    </Typography>
                                }
                            </LinksTitleBox>
                            <ButtonsBox>
                                {   props.pages &&
                                    props.pages?.map( (el, index) => {
                                        return(
                                            <ScButton 
                                                key={index}
                                                color='neutral'
                                                onClick={() => handleNavigation(el.url)}
                                            >
                                                <Typography variant='button'>
                                                    {el.name}
                                                </Typography>
                                            </ScButton>
                                        )
                                    } )

                                }
                            </ButtonsBox>
                        </LinksBox>
                    </Col1Box>
                    <ContactBox>
                        {   props['text-3'] &&
                            <ContactTitleBox>
                                <Typography variant='body1'>
                                    {props['text-3']}
                                </Typography>
                            </ContactTitleBox>
                        }
                        {   props['text-4'] && props['text-4'] !== 'x' && props['text-4'] !== 'X' &&
                            <IconAndTextBox>
                                <IconBox>
                                    <FontAwesomeIcon inverse icon={faLocationPin} size='lg' /> 
                                </IconBox>
                                <IconDescriptionBox>
                                    <Typography variant='body1'>
                                        {props['text-4']}
                                    </Typography>
                                </IconDescriptionBox>
                            </IconAndTextBox>
                        }
                        {   props['text-5'] && props['text-5'] !== 'x' && props['text-5'] !== 'X' &&
                            <IconAndTextBox>
                                <IconBox>
                                    <FontAwesomeIcon inverse icon={faEnvelope} size='lg' /> 
                                </IconBox>
                                <IconDescriptionBox>
                                    <Typography variant='body1'>
                                        {props['text-5']}
                                    </Typography>
                                </IconDescriptionBox>
                            </IconAndTextBox>
                        }
                    </ContactBox>
                </MainBox>
                <CopyrightBox>
                    <CopyrightTitleBox>
                        <Typography variant='body1'>
                            © Fong Feng-En Chou copyright {year} all rights reserved
                        </Typography>
                    </CopyrightTitleBox>
                </CopyrightBox>
            </ContentBox>
        </Section>

    )

}