import React, {useState, useEffect} from 'react';
import StripeCard from './StripeCard';
// stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//styles
import '../css/GlobalCss.css';


let mount = false;

const publicKey = 'pk_test_51M4AlNJyExqx0FuvNTGATxkfM7OfxIMt6QAlTSaIUcEZ938vXCZajYQqbwKW1cG9su2xpLjOiXtyjOM8o1PPRvTp00kupVWkev';
const stripePromise = loadStripe(publicKey);

function Stripe(props){

    const [initialOptions, setInitialOptions] = useState(
        {
            "client-id": props.clientId,
            // currency: "EUR",
            // intent: "capture",
            // "data-client-token": "access_token$production$wdy3mnsq6sz8fv4x$053b629aa553b0f0f3fdd1e57787aadd"
        }
    )

    function passOnPushPaymentToBacklog(idInput) {

        props.handlePushPaymentToBacklog(idInput);
    }

    useEffect(()=>{
        mount = false;

        mount = true;
    },[mount])

    return(
        <Elements stripe={stripePromise} >
            <StripeCard language={props.language} store={props.store} purchases={props.purchases} cartContent={props.cartContent} onPushPaymentToBacklog={(idInput) => passOnPushPaymentToBacklog(idInput)}/>
        </Elements>
    )
}

export default Stripe;