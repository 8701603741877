import React from 'react';
import styled from 'styled-components';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import SimpleCardButtonGroup from "./SimpleCardButtonGroup";

const SC_Card = styled(Card)`

    margin: 4px 0;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const ScStack = styled(Stack)`
    min-width: 100%;
`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    text-color: white;
`;

function DiscountSection({cashier, content, records, store, language, product, onClick}) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [filteredRecords, setFilteredRecords] = React.useState()

    const handleClick = React.useCallback((record, action) => {

        let selectedRecord = record;
        let selectedAction = action
        onClick(selectedRecord, selectedAction);
    }); 

    React.useEffect(() => {
        records && setFilteredRecords(() =>{
            return records.filter((el) => {
                if(cashier && el.fields['activate-cashier']){
                    return product.fields['discounts'].includes(el.id)
                }
                if(!cashier && el.fields['activate-client']){
                    return product.fields['discounts'].includes(el.id)
                }
            })
        })
        let mounted = true;
        if(mounted) {

        }
        return () => {
            mounted = false;
        }
    }, []);


    return (
        <ScStack
            spacing={2}
        >
            {   content.discounts && 
                <Typography variant="body2_bold">
                    {content['discounts'] && content['discounts'][`${language}`] }
                </Typography>
            }
            {   filteredRecords && content && language &&
                <SimpleCardButtonGroup
                    content={content}
                    records={filteredRecords}
                    language={language}
                    onClick={(record, action) => handleClick(record, action)} 
                />
            }
        </ScStack>
    );
}

export default DiscountSection;