import { useState, useEffect } from 'react';

const useFetch = (url) => {

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const abortCont = new AbortController();

        if(url.loaded && url.data){
            setData(url.data);
            setIsPending(false);
            setError(null);
        } else if( url === 'bypass') {
            setData('bypassed');
            setIsPending(false);
            setError(null);
        } else if(url !== ""){
            setTimeout(async () => {
                await fetch(url, { signal: abortCont.signal })
                .then(res => {
                    if(!res.ok) {
                        throw Error('could not fetch the data for that resource');
                    }
                    return res.json();
                })
                .then(data => {
                    setData(data);
                    setIsPending(false);
                    setError(null);
                })
                .catch(err => {
                    if(err.name === 'AbortError'){
                        console.log('fetch aborted', url)
                    } else {
                        setIsPending(false);
                        setError(err.message);
                    }
                })
    
            }, 1);
            return () => abortCont.abort();
        } else {
            return;
        }

    }, [url]);

    return {data, isPending, error}
}

export default useFetch;