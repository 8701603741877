import React, { useEffect, useState } from 'react';
import useFetch from './useFetch';
import { Box, Stack, useThemeProps } from '@mui/material';
import styled from 'styled-components';
import Map from './Map';
import Navbar from './Navbar';
import useData from '../hooks/useData';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router';

export default function MapLanding (props){
    
    // const [url_mapTypes, setUrl_mapTypes] = useState('/map-types');
    // const { data: mapTypes, isPending: mapTypes_isPending, error: mapTypes_error } = useFetch(url_mapTypes);
    
    const {data, getData} = useData();

    const {mapTypeId, language} = useParams();

    //Hard code default id
    const defaultMapId = 'recrnq4r5tmTPXuhN';

    const [ activeMap, setActiveMap ] = useState();

    // function handleMapType (type) {
        // setActiveMap(type);
    // }

    async function onPageLoad () {
        await getData('/map-types');
    }
    
    useEffect(() => {
        onPageLoad();

    }, []);

    return(

        <Box>
            <Stack spacing={0}>
                {   data  && 
                    <Navbar allMaps={data} activeMapId={ props.default ? defaultMapId : mapTypeId } />
                }
                {   data  &&
                    <Map allMaps={data} activeMapId={ props.default ? defaultMapId : mapTypeId } />
                }
            </Stack>
        </Box>

    )

}