import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { device } from '../device';

const Section = styled(Box)(({theme}) => `
    background-color: ${theme.palette.grey.lighten_4};
    padding: 32px 16px;

    @media ${device.mobileL} {
        padding: 32px 16px;
    }
    @media ${device.tablet} {
        padding: 64px 16px;
    }
    @media ${device.laptop} {
        padding: 64px 16px;
    }
`);

const ContentBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
        justify-content: center;
    }
`);

const TextsBox = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${device.tablet} {

    }
    @media ${device.laptop} {
        text-align: center;
    }
`);

const TitleBox = styled(Box)(({theme}) => `
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    @media ${device.mobileL} {

    }
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
        justify-content: center;
        min-width: 100%;
    }
`);

const DescriptionBox = styled(Box)(({theme}) => `
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px 16px;
    gap: 8px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
    }
`);

const CardsSection = styled(Box)(({theme}) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
        flex-direction: row;
        gap: 32px;
    }
`);

const ScCard = styled(Card)(({theme, custom_margin}) => `
    margin-right: ${custom_margin === 'right' ? '32px' : 'unset'};
    margin-left: ${custom_margin === 'left' ? '32px' : 'unset'};

    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
        flex: 1;
        margin-right: unset;
        margin-left: unset;
    }
    @media ${device.laptop} {
    }
`);

const NumberBox = styled(Box)(({theme}) => `

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 16px 0px;
    gap: 8px;

    width: 279px;
    height: 40px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
    }
`);

const CircleBox = styled(Box)(({theme}) => `
    align-items: center;
    background: ${theme.palette.black.main};
    border-radius: 100px;
    color: ${theme.palette.white.main};
    display: flex;
    flex-direction: column;
    height: 24px;
    justify-content: center;
    padding: 0px 4px;
    width: 24px;

    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
    }
`);

const CardTitleBox = styled(Box)(({theme}) => `
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;


    /* Inside auto layout */
    
    align-self: stretch;
    @media ${device.mobileL} {

    }
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
        justify-content: flex-start;
        min-width: 100%;
    }
`);

const ImageSection = styled(Box)(({theme}) => `
    border-radius: 10px;
    min-width: 100%;
    position: relative;
    
    @media ${device.mobileL} {
        display: flex;
        justify-content: center;
    }
    @media ${device.tablet} {
        flex: 1;
        min-width: unset;
    }
    @media ${device.laptop} {

    }
`);

const Image = styled('img')(({ theme }) => `
    border-radius: 10px;
    height: 50vw;
    object-fit: cover;
    width: 100%;

    @media ${device.mobileL} {
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        max-width: 100%;
    }
`);

const Example = styled(Box)(({theme}) => `

`);

export default function SimpleFeatures (props){

    let cardImages = props['images-1'];
    
    function renderCards() {
        
        let cardTitles = props['text-2'].split('/*/');
        let cardDescriptions = props['text-3'].split('/*/');
        let cardObjs = [];

        for(let i = 0; i < cardTitles.length; i++){
            if(cardDescriptions[i]) {
                let cardObj = {
                    title: String,
                    description: String,
                }
                cardObj.title = cardTitles[i];
                cardObj.description = cardDescriptions[i];
                cardObjs.push(cardObj);
            }
        }

        return (
            <CardsSection>
                {   cardObjs !== [] &&
                    cardObjs?.map((el, index) => {
                        let elIndex = index;
                        let count = elIndex + 1;

                        let evenOrOddIdentifier;
                        if(count % 2 === 0){
                            evenOrOddIdentifier = 'even';
                        } else {
                            evenOrOddIdentifier = 'odd';
                        }

                        return (
                            <ScCard 
                                key={index} 
                                custom_margin={
                                    evenOrOddIdentifier === 'even' ?
                                    'left' :
                                    'right'
                                }
                            >
                                <NumberBox>
                                    <CircleBox>
                                        {count}
                                    </CircleBox>
                                </NumberBox>
                                <CardTitleBox>
                                    <Typography variant="h5" component="div">
                                        {el.title}
                                    </Typography>
                                </CardTitleBox>
                                <DescriptionBox>
                                    <Typography variant="body2">
                                        {el.description}
                                    </Typography>
                                </DescriptionBox>
                            </ScCard>
                        )
                    })
                }
            </CardsSection>
        )
    }

    return(

        <Section>
            <ContentBox>
                <TextsBox>
                    {   props['text-1'] &&
                        <TitleBox>
                            <Typography variant='h3'>
                                {props['text-1']}
                            </Typography>
                        </TitleBox>
                    }
                </TextsBox>
                {   props['text-2'] && props['text-3'] &&
                    renderCards()
                }
                <ImageSection>
                    {   cardImages &&
                        <Image 
                            src={`${cardImages[0].thumbnails.large.url}`}
                            srcSet={`${cardImages[0].thumbnails.large.url}`}
                            alt={props['text-1'] && props['text-1']}
                            loading="lazy"
                        />
                    }
                </ImageSection>
            </ContentBox>
        </Section>

    )

}