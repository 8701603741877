import React, { useRef, useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import qz from "qz-tray";
// import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
// import useFetch from './useFetch';


let rootContent = null;
const storeContent = {};
let data = [];
let tse;

function readContent(inputFetch, output) {

    inputFetch.forEach((el) => {

        let recordContent = {
            en: String,
            ch: String,
            de: String,
            image: Array
        }

        recordContent.en = el.fields.en;
        recordContent.ch = el.fields.ch;
        recordContent.de = el.fields.de;
        recordContent.image = el.fields.image;

        output[`${el.fields.name}`] = recordContent;

        return;
    });
    return;
}

function createData(
    name: string,
    quantity: number,
    price: number,
    tax: string,
) {
    return { name, quantity, price, tax };
}

function chunkString (str, len) {
    const size = Math.ceil(str.length/len)
    const r = Array(size)
    let offset = 0
    
    for (let i = 0; i < size; i++) {
        r[i] = str.substr(offset, len)
        offset += len
    }
    
    return r
}

function readData(orderInput, dataOutput){
    orderInput.forEach((el) => {
        let product = el.fields.name;
        if(product.length > 14){
            let productStringArray = chunkString(product, 14);
            
            //first line
            dataOutput.push(
                createData(
                    `${productStringArray.shift()}-`,
                    el.fields['quantity'],
                    el.fields['original-price'],
                    el.fields.tax
                )
            );
            //rest of the lines
            product = productStringArray.join('-\n');
            dataOutput.push(
                createData(
                    `${product}`,
                    "",
                    "",
                    ""
                )
            );
        } else {
            dataOutput.push(
                createData(
                    `${product}`,
                    el.fields['quantity'],
                    el.fields['original-price'],
                    el.fields.tax
                )
            );
        }
        // if(el.fields.size){
        //     product = `${product}\n${el.fields.size}`
        // }
        // if(el.fields.sugar){
        //     product = `${product}\n${el.fields.sugar}`
        // }
        // if(el.fields.temperature){
        //     product = `${product}\n${el.fields.temperature}`
        // }

        if(el.fields['toppings-1']){
            dataOutput.push(
                createData(
                    el.fields['toppings-1'],
                    el.fields['quantity'],
                    el.fields['toppings-1-price'],
                    el.fields['toppings-1-tax']
                )
            );
        }
        if(el.fields['toppings-2']){
            dataOutput.push(
                createData(
                    el.fields['toppings-2'],
                    el.fields['quantity'],
                    el.fields['toppings-2-price'],
                    el.fields['toppings-2-tax']
                )
            );
            
        }
        if(el.fields['toppings-3']){
            dataOutput.push(
                createData(
                    el.fields['toppings-3'],
                    el.fields['quantity'],
                    el.fields['toppings-3-price'],
                    el.fields['toppings-3-tax']
                )
            );
        }
        if(el.fields.customization){
            dataOutput.push(
                createData(
                    el.fields['customization'],
                    el.fields['quantity'],
                    el.fields['customization-price'],
                    el.fields['customization-tax']
                )
            );
        }
        if(el.fields['discount-name']){
            dataOutput.push(
                createData(
                    el.fields['discount-name'],
                    el.fields['quantity'],
                    `- ${el.fields['discounted-price']}`,
                    ''
                )
            );
        }
    })
}

function interpretDate(input){
    let date = new Date();
    let timeOffset = date.getTimezoneOffset();
    
    let interpretDateArray = input.split('-');
    let year = interpretDateArray[0];
    let month = interpretDateArray[1];
    let day = interpretDateArray[2].substring(0,2);
    let dayAsNumber;
    let interpretTimeArray = input.split('T');  //
    let interpretTimeString = interpretTimeArray[1];  //
    let interpretTimeStringAlong = interpretTimeString.split('.');  //
    let timeString = interpretTimeStringAlong[0];  //
    let timeStringArray = timeString.split(':');
    let hour = +timeStringArray[0];
    let minute = +timeStringArray[1];
    let second = timeStringArray[2];

    if(timeOffset !== 0){
        let timeAbs = Math.abs(timeOffset);
        let offsetHour = Math.round(timeAbs / 60);
        let offsetMinute = timeAbs % 60;
        if(timeOffset > 0){
            hour = hour - offsetHour;
            if(hour < 0){
                dayAsNumber = day * 1;
                dayAsNumber = dayAsNumber - 1;
                // day = dayAsNumber;
                day = dayAsNumber.toString();
                hour = hour + 24;
            }
            minute = minute - offsetMinute;
        } else if(timeOffset < 0){
            hour = hour + offsetHour;
            if(hour >= 24){
                dayAsNumber = day * 1;
                dayAsNumber = dayAsNumber + 1;
                // day = dayAsNumber;
                day = dayAsNumber.toString();
                hour = hour % 24;
            }
            minute = minute + offsetMinute;
        }
    }

    return `${day}.${month}.${year} ${hour}:${minute}:${second}`
}

function numberToTwoDecimalString(number){
    const convertedNumber = (Math.round(number * 100) / 100).toFixed(2);
    return convertedNumber;
}

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
}

function QztrayPrinter (props) {

    // const componentRef = useRef();

    const [printProcess, setPrintProcess] = useState('idle');

    rootContent = props.invoiceContent;
    readContent(props.storeContent, storeContent);

    data = [];
    if(props.type === 'receit'){
        readData(props.order, data);
    }

    async function postFiskaly(orderInput, storeInput){
        await axios
        .post(`/post_fiskaly/${storeInput}`, orderInput)
        .then((res) => {
            // if(res.data.status === 'success'){
            //     console.log('successful')
            // }
            // console.log(res.data);
            tse = res.data;
        })
        .catch(err => {
            console.error(err);
        });
    }

    async function getFiskaly(txIdInput, storeInput){
        await axios
        .post(`/get_fiskaly/${storeInput}`, {"data": txIdInput})
        .then((res) => {
            // if(res.data.status === 'success'){
            //     console.log('successful')
            // }
            // console.log(res.data);
            tse = res.data;
        })
        .catch(err => {
            console.error(err);
        });
    }

    async function getOrders(storeInput) {
        // const { data: data_storeContent_orders, isPending: isPending_storeContent_orders, error: error_storeContent_orders } = await useFetch(`/store/orders/${storeInput}`);
    }

    function printInvoice(printer) {

        tse = undefined;

        setPrintProcess('printing');

        qz.websocket.connect().then(() => {
            // return qz.printers.find('Kasse');
            // EPSON3A209D (Epson Stylus SX420W)
            return qz.printers.find(printer);
            // return qz.printers.find('PDFCreator');
        }).then(async (found) => {

            if(props.order[0].fields['payment'] !== 'stripe' && props.order[0].fields['payment'] !== 'paypal'){
                if(props.order[0].fields['printed-invoice']){
                    // getFiskaly
                    await getFiskaly(props.order[0].fields['tx-id'], props.store);
                } else {
                    //tse setup
                    await postFiskaly(props.order, props.store);
                }
            }

            // console.log("FOUND PRINTER", found)
            let dateString;
            // let isoDateString = new Date(tse['tse_end']).toISOString();
            // dateString = interpretDate(isoDateString);
            if(tse){
                dateString = timeConverter(tse['tse_end']);
            } else {
                dateString = interpretDate(props.order[0].fields.created);
            }

            let config = qz.configs.create(found);
            const lineFeed = '\x0A';
            let cmd;
            cmd = [
                '\x1B' + '\x40',          // init
                '\x1B' + '\x61' + '\x31', // center align
                lineFeed,
                '\x1B' + '\x4D' + '\x30', // normal text
                '\x1B' + '\x45' + '\x0D', // bold on
                `${storeContent.store.en}\n`,
                '\x1B' + '\x45' + '\x0A', // bold off
                `www.mappals.app/${props.store}/en` + lineFeed,     // text and line break
                `${storeContent.address.en}` + lineFeed,
                `${rootContent['tax-number'].en}: ${storeContent['tax-number'].en ? storeContent['tax-number'].en : ''}` + lineFeed,
                `${storeContent.instagram.en ? `${rootContent.instagram.en}: ${storeContent.instagram.en}\n` : '\n'}`,
                `${storeContent.facebook.en ? `${rootContent.facebook.en}: ${storeContent.facebook.en}\n` : '\n'}`,
                lineFeed,                   // line break
                '\x1B' + '\x61' + '\x30', // left align
                'Invoice\t',
                '\x1B' + '\x4D' + '\x31', // small text
                `#${props.order[0].fields['order-name']}-${props.order[0].fields["index"]}` + lineFeed,
                lineFeed,                   // line break
                '\x1B' + '\x4D' + '\x30', // normal text
                `${rootContent['invoice-id'].en}: ${props.order[0].fields.id}` + lineFeed,
                `${rootContent.date.en}: ${dateString}` + lineFeed,
                `${rootContent.payment.en}: ${props.order[0].fields.payment}` + lineFeed,
                `${rootContent.currency.en}: ${storeContent.currency.en}` + lineFeed,
                lineFeed,                   // line break                   // line break    
                '-----------------------------------------' + lineFeed,
                'Product Name\t',
                // \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t
                '\x1B' + '\x61' + '\x32', // right align
                'Qty\t',
                'Price\t',
                'Tax\t',
                lineFeed,
            ];

            //items
            data.length !== 0 &&
            await data.forEach((el, index) => {

                let item;

                item = [
                    `${el.name}\t`,
                    '\x1B' + '\x61' + '\x32',
                    `${el.quantity}\t`,
                    `${el.price}\t`,
                    `${el.tax}\t`,
                    lineFeed
                ]
                cmd = [...cmd, ...item]

            })

            //Sum
            cmd = [
                ...cmd,
                '-----------------------------------------' + lineFeed,
                '\x1B' + '\x61' + '\x32', // right align
                '\x1B' + '\x45' + '\x0D', // bold on
                '\x1B' + '\x21' + '\x30', // em mode on
                `${rootContent.sum.en}: ${numberToTwoDecimalString(props.order[0].fields['order-sum'])}` + lineFeed,
                '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45', // em mode off
                '\x1B' + '\x45', // bold off
                '\x1B' + '\x61' + '\x30', // left align
                lineFeed
            ]
            
            //Tax title
            cmd = [
                ...cmd,
                '\x1B' + '\x4D' + '\x30', // normal text
                '\x1B' + '\x61' + '\x30', // left align
                lineFeed,
                '-----------------------------------------' + lineFeed,
                'Type\t',
                // \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t
                'Value\t',
                'Gross\t',
                'Net\t',
                'Include\t',
                lineFeed,
            ]

            // Tax
            let aSum = 0;
            let aNet = 0;
            let aInclude = 0;

            let bSum = 0;
            let bNet = 0;
            let bInclude = 0;

            props.order && 
            props.order.forEach((el) =>{
                aSum = aSum + Math.round(Number(el.fields['A-sum']) * el.fields['quantity'] * 100) / 100;
                aNet = aNet + Math.round(Number(el.fields['A-net']) * el.fields['quantity'] * 100) / 100;

                bSum = bSum + Math.round(Number(el.fields['B-sum']) * el.fields['quantity'] * 100) / 100;
                bNet = bNet + Math.round(Number(el.fields['B-net']) * el.fields['quantity'] * 100) / 100;
            })
            aInclude = aSum - aNet;
            bInclude = bSum - bNet;

            cmd = [
                ...cmd,
                'A\t',
                '19%\t',
                `${Math.round(aSum * 100) / 100}\t`,
                `${Math.round(aNet * 100) / 100}\t`,
                `${Math.round(aInclude * 100) / 100}\t`,
                lineFeed,
                'B\t',
                '7%\t',
                `${Math.round(bSum * 100) / 100}\t`,
                `${Math.round(bNet * 100) / 100}\t`,
                `${Math.round(bInclude * 100) / 100}\t`,
                lineFeed,
                '-----------------------------------------' + lineFeed,
                lineFeed,
            ]

            //tse
            if(tse){
                cmd = [
                    ...cmd,
                    '\x1B' + '\x4D' + '\x31', // small text
                    'TSE Transaction Nr.:\t' + `${tse['tse_transaction_number']}` + lineFeed,
                    'TSE Serial Nr.:\t' + `${tse['tse_serial_number']}` + lineFeed,
                    'TSE Cashier:\t' + `${tse['client_id']}` + lineFeed,
                    'Signature Nr.:\t' + `${tse['tse_signature_number']}` + lineFeed,
                    'TSE Start:\t' + `${timeConverter(tse['tse_start'])}` + lineFeed,
                    'TSE End:\t' + `${timeConverter(tse['tse_end'])}` + lineFeed,
                    'Time Format:\t' + 'utcTime' + lineFeed,
                    lineFeed,
                    lineFeed,
                    lineFeed,
                    '\x1B' + '\x4D' + '\x30', // normal text
                ]
                // console.log(tse)
            } else {
                cmd = [
                    ...cmd,
                    '\x1B' + '\x4D' + '\x31', // small text
                    'Online Transaction, TSE not connected.' + lineFeed,
                    lineFeed,
                    lineFeed,
                    '\x1B' + '\x4D' + '\x30', // normal text
                ]
            }


            let end = [
                lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // 'Baklava (Qty 4)       9.00' + '\x1B' + '\x74' + '\x13' + '\xAA', //print special char symbol after numeric
                '*****************************************' + lineFeed,       
                // '\x1B' + '\x45' + '\x0D', // bold on
                // 'Here\'s some bold text!',
                // '\x1B' + '\x45' + lineFeed, // bold off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x32', // right align
                // '\x1B' + '\x21' + '\x30', // em mode on
                // 'DRINK ME',
                // '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + lineFeed, // em mode off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // '----------------------------------------------' + lineFeed,
                // '\x1B' + '\x4D' + '\x31', // small text
                // 'EAT ME' + lineFeed,
                // '\x1B' + '\x4D' + '\x30', // normal text
                // '----------------------------------------------' + lineFeed,
                // 'normal text',
                // '\x1B' + '\x61' + '\x30', // left align
                lineFeed + lineFeed + lineFeed + lineFeed,
                // '\x1B' + '\x69',          // cut paper (old syntax)
                // '\x1D' + '\x56'  + '\x00', // full cut (new syntax)
                // '\x1D' + '\x56'  + '\x30' // full cut (new syntax)
                '\x1D' + '\x56'  + '\x01', // partial cut (new syntax)
                // '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
                '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                // **for legacy drawer cable CD-005A.  Research before using.
                // Star TSP100-series kick-out ONLY
                '\x1B' + '\x70' + '\x00' /* drawer 1 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
                // '\x1B' + '\x70' + '\x01' /* drawer 2 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
            ]

            const final = [...cmd, ...end]
            // cmd = final;
            // console.log(final)
            setPrintProcess('printed');
            return qz.print(config, final);
        }).catch((e) => {
            alert(e);
        }).finally(() => {
            return qz.websocket.disconnect();
        });
    }

    function roundToDecimalTwo(input){
        return Math.round(input * 100) / 100;
    }

    function createTableRow(columns) {
        var row = '';
        columns.forEach(function(column) {
            // Pad or truncate each column value to a fixed width
            var value = column.toString();
            value = value.substring(0, 14);  // Truncate to 15 characters
            value = value.padEnd(16, ' ');  // Pad to 20 characters with spaces
            row += value;
        });
        row += '\n';  // Add a newline at the end of the row
        return row;
    }

    function createTableRow4Columns(columns) {
        var row = '';
        columns.forEach(function(column) {
            // Pad or truncate each column value to a fixed width
            var value = column.toString();
            value = value.substring(0, 10);  // Truncate to 15 characters
            value = value.padEnd(12, ' ');  // Pad to 20 characters with spaces
            row += value;
        });
        row += '\n';  // Add a newline at the end of the row
        return row;
    }

    async function printZReport(printer) {

        const abortCont = new AbortController();

        // tse = undefined; DEL

        setPrintProcess('printing');

        qz.websocket.connect().then(() => {
            // return qz.printers.find('Kasse');
            return qz.printers.find(printer);
            // return qz.printers.find('PDFCreator');
        }).then(async (found) => {

            const reportId = props.reportId;

            const url_storeContent_zReports = `/store/z-reports/${props.store}`;
            let data_storeContent_zReports;

            await fetch(url_storeContent_zReports, { signal: abortCont.signal })
            .then(res => {
                if(!res.ok) {
                    throw Error('could not fetch the data for that resource');
                }
                return res.json();
            })
            .then(data => {
                data_storeContent_zReports = data;
            })
            .catch(err => {
                if(err.name === 'AbortError'){
                    console.log('fetch aborted', url_storeContent_zReports)
                } else {
                    console.log(err.message)
                }
            })

            let filtered_data_storeContent_zReports = data_storeContent_zReports.filter(report => {
                return report.fields.id === reportId;
            })

            let reportToPrint;
            if(filtered_data_storeContent_zReports[0]){
                reportToPrint = filtered_data_storeContent_zReports[0];
            }

            // console.log(reportToPrint);

            // console.log("FOUND PRINTER", found)

            let config = qz.configs.create(found);
            const lineFeed = '\x0A';
            var divider = '-'.repeat(40) + '\x0A';

            //set z-report or monthly report
            let reportTitle = "";
            if(reportToPrint.fields['report-type'] === 'z-report'){
                reportTitle = 'Z-Report';
            } else if (reportToPrint.fields['report-type'] === 'monthly-report') {
                reportTitle = 'Monthly Report';
            }

            //z-report section
            const data_cmd_heaher = [
                '\x1B' + '\x40',          // init
                '\x1B' + '\x61' + '\x31', // center align
                lineFeed,
                '\x1B' + '\x4D' + '\x30', // normal text
                '\x1B' + '\x45' + '\x0D', // bold on
                `${storeContent.store.en}\n`,
                '\x1B' + '\x45' + '\x0A', // bold off
                `www.mappals.app/${props.store}/en` + lineFeed,     // text and line break
                `${storeContent.address.en}` + lineFeed,
                `${rootContent['tax-number'].en}: ${storeContent['tax-number'].en ? storeContent['tax-number'].en : ''}` + lineFeed,
                `${storeContent.instagram.en ? `${rootContent.instagram.en}: ${storeContent.instagram.en}\n` : '\n'}`,
                `${storeContent.facebook.en ? `${rootContent.facebook.en}: ${storeContent.facebook.en}\n` : '\n'}`,
                lineFeed,                   // line break
                '\x1B' + '\x45' + '\x0D', // bold on
                `${reportTitle}\t${reportToPrint.fields['count']}`,
                '\x1B' + '\x45' + '\x0A', // bold off
                // '\x1B' + '\x4D' + '\x31', // small text
                lineFeed,                   // line break
                lineFeed,                   // line break
                '\x1B' + '\x4D' + '\x30', // normal text
                '\x1B' + '\x61' + '\x30', // left align
                `${reportTitle}\t`,
                '\x1B' + '\x61' + '\x32', // right align
                `${reportToPrint.fields['count']}` + lineFeed,
                '-----------------------------------------' + lineFeed,
                '\x1B' + '\x61' + '\x30', // left align
                'From:\t',
                '\x1B' + '\x61' + '\x32', // right align
                `${interpretDate(reportToPrint.fields['start'])}` + lineFeed,
                '\x1B' + '\x61' + '\x30', // left align
                'To:\t',
                '\x1B' + '\x61' + '\x32', // right align
                `${interpretDate(reportToPrint.fields['end'])}` + lineFeed,
                '\x1B' + '\x61' + '\x30', // left align
                'Duration:\t',
                '\x1B' + '\x61' + '\x32', // right align
                `${reportToPrint.fields['duration']}` + lineFeed,
                '\x1B' + '\x61' + '\x30', // left align
                'Created at:\t',
                '\x1B' + '\x61' + '\x32', // right align
                `${interpretDate(reportToPrint.fields['created'])}` + lineFeed,
                '\x1B' + '\x61' + '\x30', // left align
                'Printed by:\t',
                '\x1B' + '\x61' + '\x32', // right align
                `${storeContent.store.en}` + lineFeed,
                lineFeed,                   // line break
                lineFeed,                   // line break
            ];

            let string_cmd_header = '';
            data_cmd_heaher.forEach(e => {
                string_cmd_header = string_cmd_header + e;
            })

            const print_cmd_header ={
                type: 'raw',
                format: 'plain',
                data: string_cmd_header
            }

            //turnover section
            const data_cmd_turnover = 'TURNOVER' + lineFeed + divider;

            // turnover data
            var turnoverData = [
                ['Total', `${roundToDecimalTwo(reportToPrint.fields['total'])} EUR`],
                ['Excl. expenses', `${roundToDecimalTwo(reportToPrint.fields['expenses'])} EUR`],
                ['Tip', `${roundToDecimalTwo(reportToPrint.fields['tip'])} EUR`]
                // ... more rows as needed
            ];
            
            // Build the table string
            var turnoverString = '';
            turnoverData.forEach(function(row) {
                turnoverString += createTableRow(row);
            });

            const print_cmd_turnover ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_turnover + turnoverString + lineFeed + lineFeed
            }
            // cmd = [
            //     ...cmd,
            //     'TURNOVER' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Total\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['total'])} EUR` + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Excl. expenses\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['expenses'])} EUR` + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Tip\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['tip'])} EUR` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]
            
            let cmd = [];
            //Users section
            const data_cmd_user = 'USERS' + lineFeed + divider;

            // user data
            var userData = [
                ['Name', 'Amount'],
                [`${storeContent.store.en}`, `${roundToDecimalTwo(reportToPrint.fields['total'])} EUR`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var userString = '';
            userData.forEach(function(row) {
                userString += createTableRow(row);
            });

            const print_cmd_user ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_user + userString + lineFeed + lineFeed
            }
            // cmd = [
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            //     'USERS' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Name',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     'Amount' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     `${storeContent.store.en}`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['total'])} €` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]

            //payment types section
            const data_cmd_payment = 'PAYMENT TYPES' + lineFeed + divider;

            // payment data
            var paymentData = [
                ['Name', 'Count', 'Amount'],
                ['Cash', `${reportToPrint.fields['cash-count']}`, `${roundToDecimalTwo(reportToPrint.fields['cash-amount'])} EUR`],
                ['Included tip:', `${roundToDecimalTwo(reportToPrint.fields['cash-included-tip'])} EUR`, ''],
                ['Card', `${reportToPrint.fields['card-count']}`, `${roundToDecimalTwo(reportToPrint.fields['card-amount'])} EUR`],
                ['Included tip:', `${roundToDecimalTwo(reportToPrint.fields['card-included-tip'])} EUR`, ''],
                ['Stripe', `${reportToPrint.fields['card-online-count']}`, `${roundToDecimalTwo(reportToPrint.fields['card-online-amount'])} EUR`],
                ['Included tip:', `${roundToDecimalTwo(reportToPrint.fields['card-online-included-tip'])} EUR`, '']
                // ... more rows as needed
            ];
            
            // Build the table string
            var paymentString = '';
            paymentData.forEach(function(row) {
                paymentString += createTableRow(row);
            });

            const print_cmd_payment ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_payment + paymentString + lineFeed + lineFeed
            }
            // cmd = [
            //     ...cmd,
            //     'PAYMENT TYPES' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Name\t',
            //     'Count\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     'Amount' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Cash\t',
            //     `${reportToPrint.fields['cash-count']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['cash-amount'])} €` + lineFeed,
            //     `Included tip:\t${roundToDecimalTwo(reportToPrint.fields['cash-included-tip'])} €` + lineFeed,
            //     lineFeed,                   // line break
            //     'Card\t',
            //     `${reportToPrint.fields['card-count']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['card-amount'])} €` + lineFeed,
            //     `Included tip:\t${roundToDecimalTwo(reportToPrint.fields['card-included-tip'])} €` + lineFeed,
            //     lineFeed,                   // line break
            //     'Stripe\t',
            //     `${reportToPrint.fields['card-online-count']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['card-online-amount'])} €` + lineFeed,
            //     `Included tip:\t${roundToDecimalTwo(reportToPrint.fields['card-online-included-tip'])} €` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]

            //Tax title
            const data_cmd_taxTitle = 'TAXES' + lineFeed + divider;

            // tax title data
            var taxTitleData = [
                ['Rate', 'Gross EUR', 'Net EUR', 'Tax EUR'],
                // ... more rows as needed
            ];
            
            // Build the table string
            var taxTitleString = '';
            taxTitleData.forEach(function(row) {
                taxTitleString += createTableRow4Columns(row);
            });

            const print_cmd_taxTitle ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_taxTitle + taxTitleString + divider
            }
            // cmd = [
            //     ...cmd,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'TAXES' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     'Rate\t',
            //     // \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t
            //     'Gross €\t',
            //     'Net €\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     'Tax €' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            // ]

            // Tax section
            // tax data
            var taxData = [
                ['19%', `${roundToDecimalTwo(reportToPrint.fields['A-sum'])}`, `${roundToDecimalTwo(reportToPrint.fields['A-net'])}`, `${roundToDecimalTwo(reportToPrint.fields['A-paid'])}`],
                ['7%', `${roundToDecimalTwo(reportToPrint.fields['B-sum'])}`, `${roundToDecimalTwo(reportToPrint.fields['B-net'])}`, `${roundToDecimalTwo(reportToPrint.fields['B-paid'])}`]
                // ... more rows as needed
            ];
            
            // Build the table string
            var taxString = '';
            taxData.forEach(function(row) {
                taxString += createTableRow4Columns(row);
            });

            //taxtotal
            var taxTotalData = [
                ['Total', `${roundToDecimalTwo(reportToPrint.fields['A-sum'] + reportToPrint.fields['B-sum'])}`, `${roundToDecimalTwo(reportToPrint.fields['A-net'] + reportToPrint.fields['B-net'])}`, `${roundToDecimalTwo(reportToPrint.fields['A-paid'] + reportToPrint.fields['B-paid'])}`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var taxTotalString = '';
            taxTotalData.forEach(function(row) {
                taxTotalString += createTableRow4Columns(row);
            });

            const print_cmd_tax ={
                type: 'raw',
                format: 'plain',
                data: taxString + divider + taxTotalString + lineFeed + lineFeed
            }
            // cmd = [
            //     ...cmd,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '19%\t',
            //     `${roundToDecimalTwo(reportToPrint.fields['A-sum'])}\t`,
            //     `${roundToDecimalTwo(reportToPrint.fields['A-net'])}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['A-paid'])}` + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '7%\t',
            //     `${roundToDecimalTwo(reportToPrint.fields['B-sum'])}\t`,
            //     `${roundToDecimalTwo(reportToPrint.fields['B-net'])}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['B-paid'])}` + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Total\t',
            //     `${roundToDecimalTwo(reportToPrint.fields['A-sum'] + reportToPrint.fields['B-sum'])}\t`,
            //     `${roundToDecimalTwo(reportToPrint.fields['A-net'] + reportToPrint.fields['B-net'])}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['A-paid'] + reportToPrint.fields['B-paid'])}` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]

            //cancellation section
            //cancellation title
            const data_cmd_cancellationTitle = 'CANCELLATIONS' + lineFeed + divider;

            // tax title data
            var cancellationTitleData = [
                ['Reason', 'Count', 'Amount EUR'],
                // ... more rows as needed
            ];
            
            // Build the table string
            var cancellationTitleString = '';
            cancellationTitleData.forEach(function(row) {
                cancellationTitleString += createTableRow(row);
            });

            const print_cmd_cancellationTitle ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_cancellationTitle + cancellationTitleString + divider
            }

            // cancellation data
            var cancellationData = [
                [`${reportToPrint.fields['cancellation-type-1']}`, `${reportToPrint.fields['cancellation-count-1']}`, `${roundToDecimalTwo(reportToPrint.fields['cancellation-amount-1'])}`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var cancellationString = '';
            cancellationData.forEach(function(row) {
                cancellationString += createTableRow(row);
            });

            //cancellation total
            var cancellationTotalData = [
                ['Total', `${reportToPrint.fields['cancellation-count-total']}`, `${roundToDecimalTwo(reportToPrint.fields['cancellation-amount-total'])}`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var cancellationTotalString = '';
            cancellationTotalData.forEach(function(row) {
                cancellationTotalString += createTableRow(row);
            });

            const print_cmd_cancellation ={
                type: 'raw',
                format: 'plain',
                data: cancellationString + divider + cancellationTotalString + lineFeed + lineFeed
            }
            // cmd = [
            //     ...cmd,
            //     'CANCELLATIONS' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Reason\t',
            //     'Count\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     'Amount' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '-----------------------------------------' + lineFeed,
            //     `${reportToPrint.fields['cancellation-type-1']}\t`,
            //     `${reportToPrint.fields['cancellation-count-1']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['cancellation-amount-1'])} €` + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '-----------------------------------------' + lineFeed,
            //     'Total\t',
            //     `${reportToPrint.fields['cancellation-count-total']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['cancellation-amount-total'])} €` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]

            //discounts section
            //discounts title
            const data_cmd_discountsTitle = 'DISCOUNTS' + lineFeed + divider;

            // discounts title data
            var discountsTitleData = [
                ['Name', 'Count', 'Amount EUR'],
                // ... more rows as needed
            ];
            
            // Build the table string
            var discountsTitleString = '';
            discountsTitleData.forEach(function(row) {
                discountsTitleString += createTableRow(row);
            });

            const print_cmd_discountsTitle ={
                type: 'raw',
                format: 'plain',
                data: data_cmd_discountsTitle + discountsTitleString + divider
            }

            // discounts data
            var discountsData = [
                [`${reportToPrint.fields['discount-type-1']}`, `${reportToPrint.fields['discount-count-1']}`, `${roundToDecimalTwo(reportToPrint.fields['discount-amount-1'])}`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var discountsString = '';
            discountsData.forEach(function(row) {
                discountsString += createTableRow(row);
            });

            //discounts total
            var discountsTotalData = [
                ['Total', `${reportToPrint.fields['discount-count-total']}`, `${roundToDecimalTwo(reportToPrint.fields['discount-amount-total'])}`],
                // ... more rows as needed
            ];
            
            // Build the table string
            var discountsTotalString = '';
            discountsTotalData.forEach(function(row) {
                discountsTotalString += createTableRow(row);
            });

            const print_cmd_discounts ={
                type: 'raw',
                format: 'plain',
                data: discountsString + divider + discountsTotalString + lineFeed + lineFeed
            }
            // cmd = [
            //     ...cmd,
            //     'DISCOUNTS' + lineFeed,
            //     '-----------------------------------------' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     'Name\t',
            //     'Count\t',
            //     '\x1B' + '\x61' + '\x32', // right align
            //     'Amount' + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '-----------------------------------------' + lineFeed,
            //     `${reportToPrint.fields['discount-type-1']}\t`,
            //     `${reportToPrint.fields['discount-count-1']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['discount-amount-1'])} €` + lineFeed,
            //     '\x1B' + '\x61' + '\x30', // left align
            //     '-----------------------------------------' + lineFeed,
            //     'Total\t',
            //     `${reportToPrint.fields['discount-count-total']}\t`,
            //     '\x1B' + '\x61' + '\x32', // right align
            //     `${roundToDecimalTwo(reportToPrint.fields['discount-amount-total'])}` + lineFeed,
            //     lineFeed,                   // line break
            //     lineFeed,                   // line break
            // ]

            let end = [
                lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // 'Baklava (Qty 4)       9.00' + '\x1B' + '\x74' + '\x13' + '\xAA', //print special char symbol after numeric
                '*****************************************' + lineFeed,       
                // '\x1B' + '\x45' + '\x0D', // bold on
                // 'Here\'s some bold text!',
                // '\x1B' + '\x45' + lineFeed, // bold off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x32', // right align
                // '\x1B' + '\x21' + '\x30', // em mode on
                // 'DRINK ME',
                // '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + lineFeed, // em mode off
                // lineFeed + lineFeed,
                // '\x1B' + '\x61' + '\x30', // left align
                // '----------------------------------------------' + lineFeed,
                // '\x1B' + '\x4D' + '\x31', // small text
                // 'EAT ME' + lineFeed,
                // '\x1B' + '\x4D' + '\x30', // normal text
                // '----------------------------------------------' + lineFeed,
                // 'normal text',
                // '\x1B' + '\x61' + '\x30', // left align
                lineFeed + lineFeed + lineFeed + lineFeed,
                // '\x1B' + '\x69',          // cut paper (old syntax)
                // '\x1D' + '\x56'  + '\x00', // full cut (new syntax)
                // '\x1D' + '\x56'  + '\x30' // full cut (new syntax)
                '\x1D' + '\x56'  + '\x01', // partial cut (new syntax)
                // '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
                '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                // **for legacy drawer cable CD-005A.  Research before using.
                // Star TSP100-series kick-out ONLY
                '\x1B' + '\x70' + '\x00' /* drawer 1 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
                // '\x1B' + '\x70' + '\x01' /* drawer 2 */ + '\xC8' + '\xC8' + '\x1B' + '\x1F' + '\x70' + '\x03' + '\x00',
            ]

            const final = [...cmd, ...end]
            let finalString = '';
            final.forEach(item => {
                finalString = finalString + item;
            })
        

            var data = [
                print_cmd_header,
                print_cmd_turnover,
                print_cmd_user,
                print_cmd_payment,
                print_cmd_taxTitle,
                print_cmd_tax,
                print_cmd_cancellationTitle,
                print_cmd_cancellation,
                print_cmd_discountsTitle,
                print_cmd_discounts,
                { type: 'raw', format: 'plain', data: finalString },  // Left align
            ];
        

            // cmd = final;
            // console.log(final)
            setPrintProcess('printed');
            return qz.print(config, data);
        }).catch((e) => {
            alert(e);
        }).finally(() => {
            return qz.websocket.disconnect();
        });
    }

    useEffect(() => {

        if(
            props.port === 'cashier' &&
            props.order[0].fields['payment'] !== 'stripe' &&
            props.order[0].fields['payment'] !== 'paypal' &&
            props.order[0].fields['payment'] === 'Card' &&
            props.order[0].fields['note'] !== 'UberEats' &&
            props.order[0].fields['note'] !== 'Wolt' &&
            props.order[0].fields['note'] !== 'Lieferando'
        ){
            if(props.order[0].fields['printed-invoice']){
                // getFiskaly
                getFiskaly(props.order[0].fields['tx-id'], props.store);
            } else {
                //tse setup
                postFiskaly(props.order, props.store);
            }
        }

    }, [])

    return(
        <Box>
            {   props.order && props.order.length !== 0 && props.invoiceContent && storeContent && rootContent && printProcess === 'idle' && props.type === 'receit' ?
                <Button variant='outlined' onClick={() => printInvoice('Kasse')}>
                    {props.invoiceContent['print-invoice-cashier'][`${props.language}`]}
                </Button>
                :   props.type === 'receit' && printProcess === 'printing' ?
                <Button variant='outlined' >
                    <CircularProgress />  {props.invoiceContent['printing'][`${props.language}`]}
                </Button>
                :   props.type === 'receit' && printProcess === 'printed' ?
                <Button disabled variant='outlined' >
                    {props.invoiceContent['printed'][`${props.language}`]}
                </Button>
                :
                <></>
            }
            {   props.invoiceContent && storeContent && rootContent && printProcess === 'idle' && props.type === 'zReport' ?
                <Button variant='outlined' onClick={() => printZReport('Kasse')}>
                    {props.invoiceContent['print-z-report'][`${props.language}`]}
                </Button>
                :   props.type === 'zReport' && printProcess === 'printing' ?
                <Button variant='outlined' >
                    <CircularProgress />  {props.invoiceContent['printing'][`${props.language}`]}
                </Button>
                :   props.type === 'zReport' && printProcess === 'printed' ?
                <Button disabled variant='outlined' >
                    {props.invoiceContent['printed'][`${props.language}`]}
                </Button>
                :
                <></>
            }
        </Box>
    )
}

export default QztrayPrinter;

// {   props.order.length !== 0 && props.invoiceContent && storeContent && rootContent && printProcess === 'idle' ?
//                 <Button variant='outlined' onClick={() => print()}>{props.invoiceContent['print-invoice-cashier'][`${props.language}`]}</Button>
//                 :
//                 printProcess === 'printing' ?
//                 <Button variant='outlined' >{props.invoiceContent['print-invoice-cashier'][`${props.language}`]}</Button>
//                 :
//                 printProcess === 'printed' ?
//                 <Button variant='outlined' >{props.invoiceContent['print-invoice-cashier'][`${props.language}`]}</Button>
//                 :
//                 <></>
//             }

// {   props.order.length !== 0 && props.invoiceContent && storeContent && rootContent &&
//     <Button variant='outlined' onClick={() => print()}>{props.invoiceContent['print-invoice-cashier'][`${props.language}`]}</Button>
    
// }