import React, { Component, useState } from "react";
import {Map, Marker, GoogleApiWrapper, InfoWindow, Polyline} from 'google-maps-react';
import GoogleMapStyles from '../hooks/GoogleMapStyles.js';
import { Button, Box, Slide, Typography } from '@mui/material'
import MapMarkerDetailWindow from './MapMarkerDetailWindow'
import styled, {keyframes} from 'styled-components';
import logo from '../logo_v1.svg';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

const MapContainer = styled(Box)`
    ${'' /* display: flex; */}
    ${'' /* flex-direction: row; */}
`;
export class CreateMap extends Component {
    
    constructor(props) {
        super(props);
    }

    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: mapProps,
            // clickableIcons: false,
        })
    }

    state = {
        theme: this.props.theme,
        containerStyle: {
            height: 'calc(100%)',
            width: 'calc(100%)'
        },
        style: {
            width: 'calc(100%)'
        },
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        placeThumbnails: [],
        placeMenus: [],
        mapStyle: GoogleMapStyles.mapStyleDark,
        zoom: 12,
        center: {
            lat: 0,
            lng: 0
        },
        lightBoxImages: [],
        markerTypes: null,
        filteredMarkers: null,
        markers: this.props.data,
        activeMapType: this.props.mapType,
        relationshipList: null,
    };

    onMarkerClick = ((props, marker, e) =>
        {
            this.setState({
                // containerStyle: {
                //     height: 'calc(100%)',
                //     width: 'calc(100% - 200px)'
                // },
                selectedPlace: props,
                activeMarker: marker,
                placeThumbnails: props.images,
                placeMenus: props.menus,
                showingInfoWindow: true
            })
            this.props.onHandleDetailWindow(
                this.state.showingInfoWindow, 
                {
                    marker: this.state.activeMarker,
                    visible: this.state.showingInfoWindow,
                    name: this.state.selectedPlace.name,
                    facebook: this.state.selectedPlace.facebook,
                    instagram: this.state.selectedPlace.instagram,
                    address: this.state.selectedPlace.address,
                    website: this.state.selectedPlace.website,
                    video: this.state.selectedPlace.video,
                    owner: this.state.selectedPlace.owner,
                    images: this.state.placeThumbnails,
                    menus: this.state.placeMenus
                }
            );
        }
    );

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                // containerStyle: {
                //     height: 'calc(100vh)',
                //     position: 'relative',
                //     width: 'calc(100%)'
                // },
                showingInfoWindow: false,
                activeMarker: null
            })
            this.props.onHandleDetailWindow(this.state.showingInfoWindow);
        }
    };

    onMapZoomChanged = (mapProps, map) => {
        this.setState({
            zoom: map.getZoom()
        })
        if(this.state.zoom >= 13) {
            this.setState({
            mapStyle: GoogleMapStyles.mapStyleDefault
            })
        } else {
            this.setState({
            mapStyle: GoogleMapStyles.mapStyleDark,
            zoom: 12
            })
        }
        this._mapLoaded(this.state.mapStyle, map)
    }

    getInitialCenter = (mapProps, map) => {
        this.setState({
            center: {
            lat: this.props.data.slice(-1)[0].fields.lat,
            lng: this.props.data.slice(-1)[0].fields.lng
            }
        })
    }

    onMapDragged = (mapProps, map) => {
        this.setState({
            center: {
            lat: map.center.lat(),
            lng: map.center.lng()
            }
        })
        map.panTo(
            this.state.center
        )
    }

    handleContainerClick = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                // containerStyle: {
                //     height: 'calc(100vh)',
                //     position: 'relative',
                //     width: 'calc(100%)'
                // },
                showingInfoWindow: false,
                activeMarker: null
            })
            this.props.onHandleDetailWindow(this.state.showingInfoWindow);
        }
    }

    createRelationshipList = (props) => {

        let relationshipList = [];

        const markerList = props?.map(el => el.fields);
    
        const keyLists = markerList?.map(el => el.keyword);
    
      // compare relationship
        let markersCount = markerList.length;
    
      for(let i = 0; i < markersCount; i++) { //each point A
        let mostRelatedIndex = 0;
        let HighestRelationLevel = 0;
        for (let k = 0; k < markersCount; k++) { //look for most related for the point A
            let currRelatedIndex = 0;
            let currRelationLevel = 0;
            if (k !== i) {
                for (let j = 0; j < keyLists[k].length; j++) { //each word of the marker to compare
                    for (let p = 0; p < keyLists[i].length; p++) { //each word of the point A
                        if ( keyLists[k][j].localeCompare(keyLists[i][p]) === 0 ){
                            currRelationLevel +=1;
                            break;
                        }
                        else if ( p === keyLists[i].length - 1 ) {
                            currRelationLevel -=0.5;
                        }
                    }
                }
            }
            if (currRelationLevel >= HighestRelationLevel) {
                mostRelatedIndex = k;
                HighestRelationLevel = currRelationLevel;
            }
        }
    
        let lineCoordinates = [];
        lineCoordinates.push(markerList[i]);
        lineCoordinates.push(markerList[mostRelatedIndex]);
    
        relationshipList.push(lineCoordinates);
    
        }

        this.setState({
            relationshipList: relationshipList
        })
    }

    async componentDidMount() {

        // this.filterMarkers(this.state.markers)

        const filteredMarkersMemory = this.state.markers.filter((marker) => {

            return marker.fields.keyword.includes(this.props.mapType);
        })

        this.setState({
            filteredMarkers: filteredMarkersMemory
        })

        await this.createRelationshipList(filteredMarkersMemory);

    }

    async componentDidUpdate(prevProps) {
        if (prevProps.mapType !== this.props.mapType) {

            // this.filterMarkers(this.state.markers)

            const filteredMarkersMemory = this.state.markers.filter((marker) => {

                return marker.fields.keyword.includes(this.props.mapType);
            })
    
            this.setState({
                filteredMarkers: filteredMarkersMemory
            })

            await this.createRelationshipList(filteredMarkersMemory);

        }
    }

    render(){

        return(
            <MapContainer>
                <ThemeProvider theme={this.state.theme}>
                    {
                        this.state.filteredMarkers && 
                        <Map
                            mapTypeControl = {false}
                            streetViewControl =  {false}
                            google={this.props.google}
                            containerStyle={this.state.containerStyle}
                            style={this.state.style}
                            initialCenter={{
                                lat: this.props.data.slice(-1)[0].fields.lat,
                                lng: this.props.data.slice(-1)[0].fields.lng
                            }}
                            center={this.state.center}
                            mapCenter={this.state.center}
                            zoom={this.state.zoom}
                            onClick={this.onMapClicked}
                            onZoomChanged={(mapProps, map) => this.onMapZoomChanged(mapProps, map)}
                            onReady={
                                    (mapProps, map) => {
                                        // this.createRelationshipList(this.state.filteredMarkers)
                                        this._mapLoaded(this.state.mapStyle, map)
                                        this.getInitialCenter(mapProps, map)
                                    }
                                }
                            // onCenterChanged={(mapProps, map) => this.onMapCenter_changed(mapProps, map)}
                            onDragend={(mapProps, map) => this.onMapDragged(mapProps, map)}
                            >
                            
                            {this.state.filteredMarkers && this.state.filteredMarkers?.map((marker, index) => {
                            return <Marker 
                                key={index}
                                title={marker.name}
                                position={{lat: marker.fields.lat, lng: marker.fields.lng}}
                                onClick={this.onMarkerClick}
                                name={marker.fields.name}
                                owner={marker.fields.owner}
                                video={marker.fields.video}
                                images={marker.fields.images}
                                menus={marker.fields.menus}
                                address={marker.fields.address}
                                website={marker.fields.website}
                                facebook={marker.fields.facebook}
                                instagram={marker.fields.instagram}
                                icon={marker.fields.logo ? marker.fields.logo[0].thumbnails.small.url : 'https://airtable.com/appysU7oHKtIwY7vg/tblDdNhdxdljZzqPy/viw6bVoTHmlA5hCoJ/rec3Me5UCDa2CA9MD?blocks=hide'}
                                >
                                </Marker>
                            })
                            }
                            {this.state.relationshipList && this.state.relationshipList?.map((el, index) => {
                            return <Polyline
                                key={index}
                                // path={[
                                // {
                                //     lat: ( el[0].lat + (el[1].lat - el[0].lat) * 0.05 ),
                                //     lng: ( el[0].lng + (el[1].lng - el[0].lng) * 0.05 )
                                // },
                                // {
                                //     lat: ( el[1].lat + (el[0].lat - el[1].lat) * 0.05 ),
                                //     lng: ( el[1].lng + (el[0].lng - el[1].lng) * 0.05 )
                                // }
                                // ]}
                                path={[
                                {
                                    lat: ( el[0].lat ),
                                    lng: ( el[0].lng )
                                },
                                {
                                    lat: ( el[1].lat ),
                                    lng: ( el[1].lng )
                                }
                                ]}
                                strokeColor="#000"
                                strokeOpacity={0.8}
                                strokeWeight={2} />
                            })}
                            <InfoWindow 
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}>
                                <Box>
                                    <Typography variant="h6" component="div" >
                                        {this.state.selectedPlace.name}
                                    </Typography>
                                    <Typography variant="subtitle1" component="div" >
                                        {this.state.selectedPlace.address}
                                    </Typography>
                                </Box>
                            </InfoWindow>
                        </Map>
                    }
                </ThemeProvider>
            </MapContainer>
        )
    }
}


export default GoogleApiWrapper({
    apiKey: ('AIzaSyCC27f2Rvtry4DfHPfHyczWgLuo0_iG_8E')
  })(CreateMap)