import React from "react";
// import { Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { Box , Container, Typography, Button } from '@mui/material';
import styled from 'styled-components';

const Header = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

const HeaderInvoice = styled(Box)`
    display: flex;
`;

const Section = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 16px auto;
    width: 100%;
`;

const TableHeader = styled(Box)`
    border-top: 1px solid #EEEEEE;
    color: grey;
    display: flex;
    font-size: 7px;
    font-weight: bold;
    margin-bottom: 12px;
    padding: 8px auto;
`;

const TableItem = styled(Box)`
    display: flex;
    border-top: 1px solid #EEEEEE;
    padding: 8px auto;
`;

const Row1 = styled(Box)`
    width: 27%;
`;
const Row2 = styled(Box)`
    width: 15%;
`;
const Row3 = styled(Box)`
    width: 15%;
`;
const Row4 = styled(Box)`
    width: 20%;
`;
const Row5 = styled(Box)`
    width: 27%;
`;

const ItemTableRowName = styled(Box)`
    width: 70%;
`;
const ItemTableRowQty = styled(Box)`
    width: 10%;
`;
const ItemTableRowPrice = styled(Box)`
    width: 10%;
`;
const ItemTableRowTax = styled(Box)`
    width: 10%;
`;

const Title = styled(Typography)`
    font-size: 12px;
    text-align: center;
`;

const IdText = styled(Typography)`
    font-size: 9px;
    color: grey;
`;

const TwoColumnSection = styled(Box)`
    display: flex;
    width: 100%;
`;

const Column = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const Hr = styled(Box)`
    border-top: 1px solid #EEEEEE;
`;

const SectionRightAlign = styled(Box)`
    text-align: right;
`;

const rootContent = {}
const storeContent = {}

function readContent(inputFetch, output) {

    inputFetch.forEach((el) => {

        let recordContent = {
            en: String,
            ch: String,
            de: String,
            image: Array
        }

        recordContent.en = el.fields.en;
        recordContent.ch = el.fields.ch;
        recordContent.de = el.fields.de;
        recordContent.image = el.fields.image;

        output[`${el.fields.name}`] = recordContent;

        return;
    });
    return;
}

function createData(
    name: string,
    quantity: number,
    price: number,
    tax: string,
) {
    return { name, quantity, price, tax };
}

let data = [];

function Table(props) {
    return (
        <Section>
            <TableHeader>
                <ItemTableRowName>
                    <Typography>Product Name</Typography>
                </ItemTableRowName>
                <ItemTableRowQty>
                    <Typography>Qty</Typography>
                </ItemTableRowQty>
                <ItemTableRowPrice>
                    <Typography>Price</Typography>
                </ItemTableRowPrice>
                <ItemTableRowTax>
                    <Typography>Tax</Typography>
                </ItemTableRowTax>
            </TableHeader>
            {
                props.dataInput?.map((el, index) => (
                    <TableItem key={index}>
                        <ItemTableRowName>
                            <Typography>
                                {el.name}
                            </Typography>
                        </ItemTableRowName>
                        <ItemTableRowQty>
                            <Typography>
                                {el.quantity}
                            </Typography>
                        </ItemTableRowQty>
                        <ItemTableRowPrice>
                            <Typography>
                                {el.price}
                            </Typography>
                        </ItemTableRowPrice>
                        <ItemTableRowTax>
                            <Typography>
                                {el.tax}
                            </Typography>
                        </ItemTableRowTax>
                    </TableItem>
                ))
            }
        </Section>
    );
}

function TaxTable(props) {

    let aSum = 0;
    let aNet = 0;
    let aInclude = 0;

    let bSum = 0;
    let bNet = 0;
    let bInclude = 0;

    props.dataInput.forEach((el) =>{
        aSum = aSum + Math.round(Number(el.fields['A-sum']) * 100) / 100 ;
        aNet = aNet + Math.round(Number(el.fields['A-net']) * 100) / 100;

        bSum = bSum + Math.round(Number(el.fields['B-sum']) * 100) / 100;
        bNet = bNet + Math.round(Number(el.fields['B-net']) * 100) / 100;
    })

    return (
        <Box>
            <TableHeader>
                <Row1>
                    <Typography>Tax Type</Typography>
                </Row1>
                <Row2>
                    <Typography>Tax Value</Typography>
                </Row2>
                <Row3>
                    <Typography>Gross</Typography>
                </Row3>
                <Row4>
                    <Typography>Net</Typography>
                </Row4>
                <Row5>
                    <Typography>include</Typography>
                </Row5>
            </TableHeader>

            <TableItem>
                <Row1>
                    <Typography>
                        A
                    </Typography>
                </Row1>
                <Row2>
                    <Typography>
                        19%
                    </Typography>
                </Row2>
                <Row3>
                    <Typography>
                        {Math.round(aSum * 100) / 100}
                    </Typography>
                </Row3>
                <Row4>
                    <Typography>
                        {Math.round(aNet * 100) / 100}
                    </Typography>
                </Row4>
                <Row5>
                    <Typography>
                        {Math.round((aSum - aNet) * 100) / 100}
                    </Typography>
                </Row5>
            </TableItem>

            <TableItem>
                <Row1>
                    <Typography>
                        B
                    </Typography>
                </Row1>
                <Row2>
                    <Typography>
                        7%
                    </Typography>
                </Row2>
                <Row3>
                    <Typography>
                        {Math.round(bSum * 100) / 100}
                    </Typography>
                </Row3>
                <Row4>
                    <Typography>
                        {Math.round(bNet * 100) / 100}
                    </Typography>
                </Row4>
                <Row5>
                    <Typography>
                        {Math.round((bSum - bNet) * 100) / 100}
                    </Typography>
                </Row5>
            </TableItem>
        </Box>
    );
}

function readData(orderInput, dataOutput){
    orderInput.forEach((el) => {
        dataOutput.push(
            createData(
                `${el.fields.name}${el.fields.size ? `, ${el.fields.size}` : ''}${el.fields.sugar ? `, ${el.fields.sugar}` : ''}${el.fields.temperature ? `, ${el.fields.temperature}` : ''}`,
                1,
                el.fields['original-price'],
                el.fields.tax
            )
        );

        if(el.fields['toppings-1']){
            dataOutput.push(
                createData(
                    el.fields['toppings-1'],
                    1,
                    el.fields['toppings-1-price'],
                    el.fields['toppings-1-tax']
                )
            );
        }
        if(el.fields['toppings-2']){
            dataOutput.push(
                createData(
                    el.fields['toppings-2'],
                    1,
                    el.fields['toppings-2-price'],
                    el.fields['toppings-2-tax']
                )
            );
            
        }
        if(el.fields['toppings-3']){
            dataOutput.push(
                createData(
                    el.fields['toppings-3'],
                    1,
                    el.fields['toppings-3-price'],
                    el.fields['toppings-3-tax']
                )
            );
        }
        if(el.fields.customization){
            dataOutput.push(
                createData(
                    el.fields['customization'],
                    1,
                    el.fields['customization-price'],
                    el.fields['customization-tax']
                )
            );
        }
        if(el.fields['discount-name']){
            dataOutput.push(
                createData(
                    el.fields['discount-name'],
                    1,
                    `- ${el.fields['discounted-price']}`,
                    ''
                )
            );
        }
    })
}

const PDFToPrint = (props) => {
    
    readContent(props.rootContent, rootContent);
    readContent(props.storeContent, storeContent);

    data = [];
    readData(props.order, data);

    return (
        <Box>
            {/* <Page size='A4' style={styles.body}> */}
                <Header>
                    <HeaderInvoice>
                        <Title>
                            Invoice
                        </Title>
                        <IdText>
                            {`#${props.order[0].fields['order-name']}-${props.order[0].fields["index"]}`}
                        </IdText>
                    </HeaderInvoice>
                    {/* <Image style={styles.image} src={storeContent.logo.image[0].thumbnails.large.url}  /> */}
                </Header>

                <Section>
                    <Typography>
                        {storeContent.store.en}
                    </Typography>
                    <Typography>
                        {storeContent.address.en}
                    </Typography>
                    {   storeContent['tax-number'].en &&
                        <Typography>
                            {`${rootContent['tax-number'].en}: ${storeContent['tax-number'].en}`}
                        </Typography>
                    }
                    {   storeContent.instagram.en &&
                        <Typography>
                            {`${rootContent.instagram.en}: ${storeContent.instagram.en}`}
                        </Typography>
                    }
                    {   storeContent.facebook.en &&
                        <Typography>
                            {`${rootContent.facebook.en}: ${storeContent.facebook.en}`}
                        </Typography>
                    }
                </Section>

                <Section>
                    <Typography>
                        {rootContent.title.en}
                    </Typography>
                    <TwoColumnSection>
                        <Column>
                            <Typography>
                                {rootContent['invoice-id'].en}
                            </Typography>
                            <Typography>
                                {rootContent.date.en}
                            </Typography>
                            <Typography>
                                {rootContent.payment.en}
                            </Typography>
                            <Typography>
                                {rootContent.currency.en}
                            </Typography>
                        </Column>
                        <Column>
                            <Typography>
                                {props.order[0].fields.id}
                            </Typography>
                            <Typography>
                                {props.order[0].fields.created}
                            </Typography>
                            <Typography>
                                {props.order[0].fields.payment}
                            </Typography>
                            <Typography>
                                {storeContent.currency.en}
                            </Typography>
                        </Column>
                    </TwoColumnSection>
                </Section>

                <Hr></Hr>

                {   data !== [] &&
                    <Table dataInput={data} ></Table>
                }

                <Hr></Hr>

                <SectionRightAlign>
                    <Typography>
                        {`${rootContent.sum.en}: ${props.order[0].fields['order-sum']}`}
                    </Typography>
                </SectionRightAlign>
                <Section>
                    <TaxTable dataInput={props.order} ></TaxTable>
                </Section>

                {/* {
                    props.order.map((el, index) => {
                        return (
                            <div key={index}>
                                <Text styles={styles.text}>
                                    {el.fields.name}
                                </Text>
                                <Text styles={styles.text}>
                                    {el.fields.size}
                                </Text>
                                <Text styles={styles.text}>
                                    {el.fields.sugar}
                                </Text>
                                <Text styles={styles.text}>
                                    {el.fields.temperature}
                                </Text>
                                {   el.fields['toppings-1'] !== 'None' &&
                                    <Text styles={styles.text}>
                                        {el.fields['toppings-1']}
                                    </Text>
                                }
                                {   el.fields['toppings-2'] !=='None' &&
                                    <Text styles={styles.text}>
                                        {el.fields['toppings-2']}
                                    </Text>
                                }
                                {   el.fields['toppings-3'] !== 'None' &&
                                    <Text styles={styles.text}>
                                        {el.fields['toppings-3']}
                                    </Text>
                                }
                                <Text styles={styles.text}>
                                    {el.fields.customization}
                                </Text>
                                <Text styles={styles.text}>
                                    {el.fields.price} €
                                </Text>
                            </div>
                        )
                    })
                } */}
                {/* <Typography 
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`} /> */}
            {/* </Page> */}
        </Box>
    )
};

export default PDFToPrint;