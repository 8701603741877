import React, {useState} from 'react';
import { useParams } from 'react-router';
import useFetch from './useFetch';
import ConfettiAnimation from './ConfettiAnimation';
import { Box , Container, Typography, Button, Divider, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LottieAnimationWithUrl from './LottieAnimationWithUrl';
// import styled from 'styled-components';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SiteNavigation from './SiteNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
// import InvoicePrinter from './InvoicePrinter';
//cashier
import TextOnlyInvoice from './TextOnlyInvoice';
import QztrayPrinter from './QztrayPrinter';
import TicketPrinter from './TicketPrinter';
import QztrayCashierPrinter from './QztrayCashierPrinter';
import LabelToPrintHolder from './LabelToPrintHolder';

import readAirtableData from '../hooks/readAirtableData';
import { useTheme } from '@mui/material/styles';

const ScContainer = styled(Container)(
    ({ theme }) => `
        background-color: ${theme.palette.primary.background};
        min-height: 100vh;

        max-width: unset !important;
        padding: 0 20px 40px 20px;

        text-align: center;

        :hover {

        }
    `,
);

const ScStack = styled(Stack)`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
`;

const HomeBox = styled(Box)`
    margin: 32px 0;
`;

const PickUpNameAlert = styled(Dialog)`
    height: auto !important;
`;

const AlertContentBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center !important;
`;

const InvoiceBox = styled(Box)(
    ({ theme }) => `
        align-items: center;
        background-color: ${theme.palette.grey.lighten_5};
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 600px;
        text-align: center;
        padding: 40px 24px;
    `,
);

const StyledDialogTitle = styled(DialogTitle)`
    color: black;
`;

const ScDialogContent = styled(DialogContent)`

`;

const ScDialogContentText = styled(DialogContentText)`

`;

const DialogTitleTypography = styled(Typography)`
    color: black;
`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
    ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let thisOrder;

let thisOrderNameRecord;

let storeMedia = {};

let rendered = false;

function Invoice(props) {

    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const [openOrder, setOpenOrder] = React.useState(false);

    const [orderState, setOrderState] = React.useState();

    const [invoiceContent, setInvoiceContent] = React.useState();

    thisOrder = [];
    thisOrderNameRecord = null;

    const {store, language, orderID} = useParams();

    const url_storeContent_orders = `/store/orders/${store}`;
    const url_storeContent_invoice = `/store/invoice/${store}`;
    const [url_storeContent_media, setUrl_storeContent_media] = useState(`/store/media/${store}`);
    const url_idNames = `/store/orderIds/${store}`;
    const url_rootContent_invoice = `/root/invoice`;

    const { data: storeContent_orders, isPending: storeContent_orders_isPending, error: storeContent_orders_error } = useFetch(url_storeContent_orders);
    const { data: storeContent_invoice, isPending: storeContent_invoice_isPending, error: storeContent_invoice_error } = useFetch(url_storeContent_invoice);
    const { data: idNames, isPending: idNames_isPending, error: idNames_error } = useFetch(url_idNames);
    const { data: data_rootContent_invoice, isPending: data_rootContent_invoice_isPending, error: data_rootContent_invoice_error } = useFetch(url_rootContent_invoice);
    const { data: data_storeContent_media, isPending: isPending_storeContent_media, error: error_storeContent_media } = useFetch(url_storeContent_media);

    function readOrders(inputOrderID){

        let order = {
            orderRecords: [],
            nameRecord: null
        }

        let orderMemory = [];

        let orderNameMemory = '';
        
        let orderNamesFiltered;

        storeContent_orders?.map(el => {

            if(el.fields.id === inputOrderID) {

                order.orderRecords.push(el);

                if(!order.nameRecord){
                    orderNamesFiltered = idNames.filter(record => {
                        return record.fields['name'] === el.fields['order-name'];
                    })
                }
                orderNameMemory = orderNamesFiltered[0];

                order.nameRecord = orderNameMemory;
            }
        })

        setOrderState(order);

    }

    function readInvoiceContent(data) {

        let invoiceContentMemory = {};

        data.forEach((el) => {

            let recordContent = {
                en: String,
                ch: String,
                de: String
            }

            recordContent.en = el.fields.en;
            recordContent.ch = el.fields.ch;
            recordContent.de = el.fields.de;

            invoiceContentMemory[`${el.fields.name}`] = recordContent;

            return;

        })

        setInvoiceContent(invoiceContentMemory);

        return;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenOrder = () => {
        setOpenOrder(true);
    };
    
    const handleCloseOrder = () => {
        setOpenOrder(false);
    };

    function refreshPage() {
        window.location.reload(false);
    }

    // storeContent_orders && readOrders(orderID);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    async function emptyAwaitTimeOutFunction(delay){
        await timeout(delay);
    }
    
    React.useEffect(() => {

        let mounted = true;

        let rootContentMemory = {}

        if(data_storeContent_media){
            readAirtableData(data_storeContent_media, storeMedia);
        }

        if(data_rootContent_invoice && idNames){
            readAirtableData(data_rootContent_invoice, rootContentMemory);
            setInvoiceContent(rootContentMemory);
        }

        if(storeContent_orders && idNames) {
            readOrders(orderID);
            setOpen(true);
        }

        // if(mounted && !rendered && storeContent_orders && idNames && storeContent_invoice && data_rootContent_invoice ) {

            // readInvoiceContent(rootContent_invoice);

            // rendered = true;
            // emptyAwaitTimeOutFunction(2000);
            // timer1 = setTimeout(readOrders(orderID), 3000);
            // readOrders(orderID);
            // timer2 = setTimeout(setOpen(true), 2000);
            // setOpen(true)
            // setOrderState(order);
            // readOrders(orderID);
        // }
        
        return () => {
            mounted = false;
        }
    },[ idNames, orderID, data_rootContent_invoice, storeContent_orders, storeContent_invoice])

    return(
        <ScContainer>
            <ScStack spacing={10}>
                <SiteNavigation port={props.cashier ? 'cashier' : 'client' } />

                {   props.client && orderState && orderState.orderRecords[0] && orderState.nameRecord && data_rootContent_invoice && invoiceContent && language && orderState.orderRecords[0] &&
                    <PickUpNameAlert
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <AlertContentBox>
                            <ConfettiAnimation width='140px' height='140px' />
                            <StyledDialogTitle id="alert-dialog-title">
                                <DialogTitleTypography gutterBottom variant="h4" component="div">
                                    {invoiceContent["pick-up-reminder"] && invoiceContent["pick-up-reminder"][`${language}`]}
                                </DialogTitleTypography>
                            </StyledDialogTitle>
                            <ScDialogContent>
                                <ScDialogContentText id="alert-dialog-description">
                                    {invoiceContent["pick-up-reference"] && invoiceContent["pick-up-reference"][`${language}`]}  {orderState.nameRecord.fields.name && orderState.nameRecord.fields.name}
                                </ScDialogContentText>
                            </ScDialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>
                                    {invoiceContent["confirm"] && invoiceContent["confirm"][`${language}`]}
                                </Button>
                            </DialogActions>
                        </AlertContentBox>
                    </PickUpNameAlert>
                }

                {   invoiceContent &&
                    <InvoiceBox>
                        {   orderState && orderState.nameRecord && orderState.nameRecord.fields['animation-url'] ?
                            <LottieAnimationWithUrl url={`${orderState.nameRecord.fields['animation-url']}`} width='140px' height='140px' />
                            :
                            orderState && orderState.nameRecord && orderState.nameRecord.fields.image[0] ?
                            <Avatar
                                alt="Doggo"
                                src={orderState.nameRecord.fields.image[0].thumbnails.large.url}
                                sx={{ width: 80, height: 80 }}
                            />
                            :
                            <></>
                        }
                    
                        {   props.client && data_rootContent_invoice && invoiceContent && invoiceContent["pick-up-reference"] && language && orderState && orderState.nameRecord && orderState.orderRecords[0] &&
                            <Typography variant="h4_bold" component="div">
                                {invoiceContent["pick-up-reference"][`${language}`]}  {orderState.nameRecord.fields.name && orderState.nameRecord.fields.name}
                            </Typography>
                        }
                        {   props.client && orderState && orderState.orderRecords[0] && orderState.nameRecord && data_rootContent_invoice && invoiceContent["pick-up-reminder"] && language && orderState.orderRecords[0] &&
                            <DialogTitleTypography variant="body1" component="div">
                                {invoiceContent["pick-up-reminder"][`${language}`]}
                            </DialogTitleTypography>
                        }

                        {   props.cashier && data_rootContent_invoice && invoiceContent && invoiceContent["pick-up-reference"] && language && orderState && orderState.nameRecord && orderState.orderRecords[0] &&
                            <Typography variant="h4_bold" component="div">
                                {invoiceContent["pick-up-reference"][`${language}`]}  {orderState.nameRecord.fields.name && orderState.nameRecord.fields.name}
                            </Typography>
                        }
                        {   props.cashier && data_rootContent_invoice && invoiceContent && invoiceContent['refresh-command'] && invoiceContent['successful-order'] && language && orderState &&
                            <Typography variant="body1" component="div">
                                {   orderState.orderRecords.length === 0 ?
                                    invoiceContent['refresh-command'][`${language}`]
                                    : invoiceContent &&
                                    invoiceContent['successful-order'][`${language}`]
                                }
                            </Typography>
                        }

                        {   props.client && data_rootContent_invoice && invoiceContent && invoiceContent['my-order'] && language &&
                            <Button
                                variant="outlined" 
                                className="btn btn-success" 
                                onClick={() => handleOpenOrder()}
                                size="large"
                                startIcon={<FontAwesomeIcon icon={faTruckFast} size='lg' />}
                            >
                                {invoiceContent['my-order'][`${language}`]}
                            </Button>
                        }

                        {   props.cashier && invoiceContent && language && orderState && orderState.orderRecords[0] && orderState.orderRecords.length !== 0 && orderState &&
                            <LabelToPrintHolder store={store} title={`# ${orderState.nameRecord.fields.name}`} language={language} invoiceContent={invoiceContent} order={orderState.orderRecords} port={props.cashier ? 'cashier' : 'client'} />
                        }
                        {   props.cashier && data_rootContent_invoice && storeContent_invoice && invoiceContent && orderState && orderState.orderRecords.length !== 0 && orderState.nameRecord &&
                            <TicketPrinter store={store} language={language} invoiceContent={invoiceContent} order={orderState.orderRecords} storeContent={storeContent_invoice} />
                        }
                        {   props.cashier && data_rootContent_invoice && storeContent_invoice && invoiceContent && orderState && orderState.orderRecords.length !== 0 && orderState.nameRecord &&
                            <QztrayPrinter type='receit' store={store} language={language} invoiceContent={invoiceContent} order={orderState.orderRecords} storeContent={storeContent_invoice} port={props.cashier ? 'cashier' : 'client'} />
                        }

                    </InvoiceBox>
                }

                
                {   props.client && invoiceContent &&
                    <Dialog
                        open={openOrder}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseOrder}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>
                            {   data_rootContent_invoice && invoiceContent && invoiceContent['my-order'] && language &&
                                <Typography
                                    variant="h4_bold" component="div"
                                >
                                    {invoiceContent['my-order'][`${language}`]}
                                </Typography>
                            }
                        </DialogTitle>
                        <DialogContent>
                            {
                                props.client && orderState && orderState.orderRecords.length !== 0 && orderState.orderRecords?.map((el, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography gutterBottom variant="h5" component="div">
                                                {el.fields['quantity']} * {el.fields.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {el.fields.size}
                                            </Typography>
                                            {   el.fields.sugar &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {el.fields.sugar}
                                                </Typography>
                                            }
                                            {   el.fields.temperature &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {el.fields.temperature}
                                                </Typography>
                                            }
                                            {   el.fields['toppings-1'] !== 'None' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {el.fields['toppings-1']}
                                                </Typography>
                                            }
                                            {   el.fields['toppings-2'] !=='None' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {el.fields['toppings-2']}
                                                </Typography>
                                            }
                                            {   el.fields['toppings-3'] !== 'None' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {el.fields['toppings-3']}
                                                </Typography>
                                            }
                                            <Typography variant="body2" color="text.secondary">
                                                {el.fields.customization}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                            {   !el.fields['discount-name'] ?
                                                el.fields.price + ' €'
                                                :
                                                <>
                                                    <s>
                                                        {el.fields.price} €
                                                    </s>
                                                    &nbsp;
                                                    {el.fields['discounted-total-price']} €
                                                </>
                                            }

                                        </Typography>
                                        </Box>
                                    )
                                })
                            }
                            {
                                orderState && orderState.orderRecords.length !== 0 && invoiceContent && invoiceContent.sum && invoiceContent["currency-symbol-in-use"] &&
                                <Box>
                                    <Divider light />
                                    <Typography variant="h4" component="div">
                                        {`${invoiceContent.sum[`${language}`]}: ${orderState.orderRecords[0].fields["order-sum"] && orderState.orderRecords[0].fields["order-sum"]}`} {`${invoiceContent["currency-symbol-in-use"] && invoiceContent["currency-symbol-in-use"][`${language}`]}`}
                                    </Typography>
                                </Box>
                            }
                        </DialogContent>
                        {   invoiceContent &&
                            <DialogActions>
                                <Button onClick={handleCloseOrder}>
                                    { invoiceContent["confirm"] && invoiceContent["confirm"][`${language}`]}
                                </Button>
                            </DialogActions>
                        }
                    </Dialog>
                }

                {
                    props.client && orderState && orderState.orderRecords.length !== 0 && data_rootContent_invoice && storeContent_invoice && orderState.nameRecord && invoiceContent && invoiceContent["download-receit"] &&
                    <HomeBox>
                        {   data_rootContent_invoice && storeContent_invoice && invoiceContent && orderState && orderState.orderRecords.length !== 0 && orderState.nameRecord &&
                            <TextOnlyInvoice language={language} invoiceContent={invoiceContent} order={orderState.orderRecords} storeContent={storeContent_invoice} />
                        }
                    </HomeBox>
                }

                {   data_rootContent_invoice && invoiceContent && invoiceContent['refresh'] && language && orderState && orderState.orderRecords.length === 0 &&
                    <HomeBox>
                        <Button
                            variant="outlined" 
                            className="btn btn-success" 
                            onClick={() => refreshPage()}
                            size="large"
                        >
                            {invoiceContent && invoiceContent['refresh'][`${language}`]}
                        </Button>
                    </HomeBox>
                }

                {   props.client && data_rootContent_invoice && invoiceContent && invoiceContent['refresh-reminder'] && language &&
                    <Typography gutterBottom variant="body2" component="div">
                        {invoiceContent && invoiceContent['refresh-reminder'][`${language}`]}
                    </Typography>
                }

            </ScStack>
        </ScContainer>
    )
}

export default Invoice;