import React from 'react';
import { Paper, Button } from '@mui/material';
import styled from 'styled-components';
import { device } from './device';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Image = styled.img`
    object-fit: cover;

    ${'' /* @media ${device.tablet} {
        height: 600px;
    }

    @media ${device.laptopL} {
        height: 720px;
    } */}
`;

const SC_Paper= styled(Paper)`
    @media ${device.laptopL} {
        ${'' /* max-width: 1440px; */}
    }
`;

function Item(props)
{
    return (
        <SC_Paper>
            <Image src={props.src} width='100%' height={props.height} ></Image>
        </SC_Paper>
    )
}

function ImageSlides(props) {

    return (
        <Carousel
            showThumbs={props.hideThumbs ? false : true}
            showStatus={false}
            infiniteLoop
            // emulateTouch
            // autoPlay
            useKeyboardArrows
            transitionTime={500}
            // axis="vertical"
            // selectedItem={1}
            width="100%"

            // autoPlay={false}
            // onClick={handleChangePage}
            // next={loopNext}
            // prev={loopPrev}
            // NextIcon={<ArrowForwardIosRoundedIcon fontSize="large" />}
            // PrevIcon={<ArrowBackIosRoundedIcon fontSize="large" />}
            // navButtonsProps={{
            //     // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            //     style: {
            //         backgroundColor: "transparent",
            //         color: "#447CF0"
            //     }
            // }}
            // indicators={true}
            // swipe={true}
            // animation="slide"
        >
            {
                props.images &&
                props.images?.map( (item, i) => <Item key={i} src={`${item.thumbnails.large.url}`} height={props.height} /> )
            }
        </Carousel>
    )
}

export default ImageSlides;