import React, {useState} from 'react';
import { useParams } from 'react-router';
import useFetch from './useFetch';
import ConfettiAnimation from './ConfettiAnimation';
import { Box , Container, Typography, Button, Divider, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LottieAnimationWithUrl from './LottieAnimationWithUrl';
// import styled from 'styled-components';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SiteNavigation from './SiteNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
// import InvoicePrinter from './InvoicePrinter';
//cashier
import TextOnlyInvoice from './TextOnlyInvoice';
import QztrayPrinter from './QztrayPrinter';
import TicketPrinter from './TicketPrinter';
import QztrayCashierPrinter from './QztrayCashierPrinter';
import LabelToPrintHolder from './LabelToPrintHolder';

import readAirtableData from '../hooks/readAirtableData';
import { useTheme } from '@mui/material/styles';

const ScContainer = styled(Container)(
    ({ theme }) => `
        background-color: ${theme.palette.primary.background};
        min-height: 100vh;

        max-width: unset !important;
        padding: 0 20px 40px 20px;

        text-align: center;

        :hover {

        }
    `,
);

const ScStack = styled(Stack)`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
`;

const HomeBox = styled(Box)`
    margin: 32px 0;
`;

const PickUpNameAlert = styled(Dialog)`
    height: auto !important;
`;

const AlertContentBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center !important;
`;

const InvoiceBox = styled(Box)(
    ({ theme }) => `
        align-items: center;
        background-color: ${theme.palette.grey.lighten_5};
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 600px;
        text-align: center;
        padding: 40px 24px;
    `,
);

const StyledDialogTitle = styled(DialogTitle)`
    color: black;
`;

const ScDialogContent = styled(DialogContent)`

`;

const ScDialogContentText = styled(DialogContentText)`

`;

const DialogTitleTypography = styled(Typography)`
    color: black;
`;


let storeMedia = {};

let rendered = false;

function ZReport(props) {

    const theme = useTheme();

    const [invoiceContent, setInvoiceContent] = React.useState();

    const {store, language, reportId} = useParams();

    const url_storeContent_invoice = `/store/invoice/${store}`;
    const [url_storeContent_media, setUrl_storeContent_media] = useState(`/store/media/${store}`);
    const url_rootContent_invoice = `/root/invoice`;

    const { data: storeContent_invoice, isPending: storeContent_invoice_isPending, error: storeContent_invoice_error } = useFetch(url_storeContent_invoice);
    const { data: data_rootContent_invoice, isPending: data_rootContent_invoice_isPending, error: data_rootContent_invoice_error } = useFetch(url_rootContent_invoice);
    const { data: data_storeContent_media, isPending: isPending_storeContent_media, error: error_storeContent_media } = useFetch(url_storeContent_media);

    function refreshPage() {
        window.location.reload(false);
    }

    // storeContent_orders && readOrders(orderID);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    async function emptyAwaitTimeOutFunction(delay){
        await timeout(delay);
    }
    
    React.useEffect(() => {

        let mounted = true;

        let rootContentMemory = {}

        if(data_storeContent_media){
            readAirtableData(data_storeContent_media, storeMedia);
        }

        if(data_rootContent_invoice){
            readAirtableData(data_rootContent_invoice, rootContentMemory);
            setInvoiceContent(rootContentMemory);
        }
        
        return () => {
            mounted = false;
        }
    },[ data_rootContent_invoice, storeContent_invoice, data_storeContent_media, storeMedia])

    return(
        <ScContainer>
            <ScStack spacing={10}>
                <SiteNavigation port={props.cashier ? 'cashier' : 'client' } />

                {   data_rootContent_invoice && storeContent_invoice && invoiceContent &&
                    <QztrayPrinter type='zReport' reportId={reportId && reportId} store={store} language={language} invoiceContent={invoiceContent} storeContent={storeContent_invoice} />
                }

                {   !data_rootContent_invoice || !invoiceContent || !invoiceContent['refresh'] || !language &&
                    <HomeBox>
                        <Button
                            variant="outlined" 
                            className="btn btn-success" 
                            onClick={() => refreshPage()}
                            size="large"
                        >
                            {invoiceContent && invoiceContent['refresh'][`${language}`]}
                        </Button>
                    </HomeBox>
                }

                {   props.client && data_rootContent_invoice && invoiceContent && invoiceContent['refresh-reminder'] && language &&
                    <Typography gutterBottom variant="body2" component="div">
                        {invoiceContent && invoiceContent['refresh-reminder'][`${language}`]}
                    </Typography>
                }

            </ScStack>
        </ScContainer>
    )
}

export default ZReport;