import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';

const styles = {
    toTop: {
        zIndex: 9999,
        position: 'fixed',
        bottom: '96px',
        backgroundColor: '#DCDCDC',
        color: 'black !important',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: 'white !important',
            backgroundColor: '#2185D5'
        },
        right: '5%'
    }
}

const ScrollTop = ({
    showBelow
}) => {
    // const classes = useStyles();

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if(window.pageYOffset > showBelow) {
            if(!show) setShow(true)
        } else {
            if(show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if(showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })
    return (
        <Box>
            {   show &&
                <Fab size="medium" color="secondary" aria-label="add" sx={styles.toTop} onClick={handleClick}>
                    <VerticalAlignTopIcon style={{color: 'unset'}} />
                </Fab>
            }
        </Box>
    )
}

export default ScrollTop;