import React, {useState} from 'react';
// eslint-disable-next-line
import MasonryImageList from './MasonryImageList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Button, Box, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

const DetailWindow = styled(Box)`
    ${'' /* border-radius: 10px 0px 0px 10px; */}
    ${'' /* background-color: #dfdfdf; */}
    ${'' /* border: solid 2px #4B6587; */}
    ${'' /* display: flex; */}
    /* height: 100vh; */
    ${'' /* overflow-y: scroll; */}
    padding: 16px;
    width: 480px;
    ${'' /* -moz-border-radius: 10px 0px 0px 10px; */}
`;

const Header = styled(Box)`
    align-items: flex-start;
    display: flex;
    ${'' /* font-weight: bold; */}
    justify-content: space-between;
`;

const Texts = styled(Box)`
    margin: 0;
    padding: 0;
`;

const SocialMediaSection = styled(Box)`

`;

const NormalSection = styled(Box)`

`;

const VideoSection = styled(Box)`

`;

const ImageSection = styled(Box)`

`;



function MapMarkerDetailWindow(props) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const renderSocialMedia = (facebook, instagram) => {

        return (
            <SocialMediaSection>
                {
                    facebook !== undefined ? 
                    <IconButton aria-label="facebook" href={facebook} target="_blank">
                        <FontAwesomeIcon icon={['fab', 'facebook']} size="lg" />
                    </IconButton>
                    :
                    <></>
                    }
                {
                    instagram !== undefined ? 
                    <IconButton aria-label="facebook" href={instagram} target="_blank">
                        <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />
                    </IconButton>
                    :
                    <></>
                }
            </SocialMediaSection>
        )
    }

    const renderWebsite = (website) => {
        return (
            <>
                {
                    website !== undefined &&
                    <Button variant="contained" href={website} target="_blank">
                        Order Online
                    </Button>
                }
            </>
        )
    }

    return (
        <DetailWindow style={{ width: md ? '480px' : sm ? '70vw' : '80vw', height: "100%" }} >
            <Stack spacing={1}>
                <Header>
                    <Texts>
                        <Typography variant="h4" component="div">
                            {props.name}
                        </Typography>
                        <Link href={`http://maps.google.com/?q=${props.address}, ${props.name}`} target="_blank" color="inherit">
                            <Typography variant="body2" component="div" gutterBottom>
                                {props.address}
                            </Typography>
                        </Link>
                    </Texts>
                    {renderSocialMedia(props.facebook, props.instagram)}
                </Header>
                <NormalSection>
                    {renderWebsite(props.website)}
                </NormalSection>
                {   props.video &&
                    <VideoSection>
                        <Typography variant="h5" component="div">
                            Interview with the owner: {props.owner}
                        </Typography>
                        <iframe width="480" height="270" src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </VideoSection>
                }
                {   props.images &&
                    <ImageSection>
                        <Typography variant="h6" component="div" >
                            Store Gallery
                        </Typography>
                            <MasonryImageList 
                                thumbnails={props.images} 
                                onLightboxHandler={(state) => props.handleLightbox(state)}
                            />
                    </ImageSection>
                }
                {   props.menus &&
                    <ImageSection>
                        <Typography variant="h6" component="div" >
                            Menus
                        </Typography>
                            <MasonryImageList 
                                thumbnails={props.menus} 
                                onLightboxHandler={(state) => props.handleLightbox(state)}
                            />
                    </ImageSection>
                }
            </Stack>
        </DetailWindow>
    )
}

export default MapMarkerDetailWindow;